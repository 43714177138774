import styled from 'styled-components'

export const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  position: absolute;
`

export const Text = styled.h1`
  position: relative;
  z-index: 2;
  border-top: 4px solid ${({ theme }) => theme.colors.bananaMania};
  border-bottom: 4px solid ${({ theme }) => theme.colors.bananaMania};
  padding: 1rem 3rem;
  text-align: center;
  clip-path: polygon(
    0 0,
    100% 0%,
    calc(100% - 20px) 50%,
    100% 100%,
    0 100%,
    calc(0% + 20px) 50%
  );
  transform: rotate(-2deg);
  ${({ theme }) => theme.textStyles.titleH4};
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.pureWhite};
  margin: 0;
  background-color: ${({ theme }) => theme.colors.arapawa};
  filter: blur(0.01px);
`
