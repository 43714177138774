export { default as SoloBannerBlock } from './SoloBannerBlock'
export { default as SoloBrandBlock } from './SoloBrandBlock'
export { default as SoloRecipesBlock } from './SoloRecipesBlock'
export { default as SoloSectionTitleBlock } from './SoloSectionTitleBlock'
export { default as SoloTitleImageBodyBlock } from './SoloTitleImageBodyBlock'
export { default as SoloVideoBlock } from './SoloVideoBlock'
export { default as SoloVideosSliderBlock } from './SoloVideosSliderBlock'
export { default as SoloBodyBlock } from './SoloBodyBlock'
export { default as SoloHistoryContainerBlock } from './SoloHistoryContainerBlock'
export { default as SoloArticleUnionFourItemsBlock } from './SoloArticleUnionFourItemsBlock'
export { default as SoloFullWidthImageCardBlock } from './SoloFullWidthImageCardBlock'
export { default as SoloTitleBodyCtaBlock } from './SoloTitleBodyCtaBlock'
export { default as SoloContactFormBlock } from './SoloContactFormBlock'
export { default as SoloCapriceValentineGameBlock } from './SoloCapriceValentineGameBlock'
export { default as SoloCouponsBlock } from './SoloCouponsBlock'
