import styled, { css } from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'

import { mq, sizes } from '../../../theme'
import Link from '../../atoms/Link'
import Icon from '../../atoms/Icon'
import Picture from '../../atoms/Picture'

export const Stickytrigger = styled.div``

export const Container = styled.div<{ $isSticky?: boolean; $offset?: number }>`
  --padding-left--logo: 1.5rem;
  --width--logo: 7.8rem;
  position: relative;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  transition: box-shadow 300ms ease-in-out;
  ${({ $isSticky, $offset }) =>
    $isSticky === true &&
    css`
      position: fixed;
      top: ${$offset === 0 ? '6rem' : $offset};
      left: 0;
      right: 0;
      z-index: ${({ theme }) => theme.zIndexes.forefront};
      box-shadow: 0 1rem 6rem 0 rgba(0, 0, 0, 0.1);

      @media ${mq(sizes.tablet)} {
        top: ${$offset};
      }
    `}
`

export const Scroller = styled(ScrollContainer)<{ $scrollable?: boolean }>`
  --cursor--wrapper: ${({ $scrollable }) =>
    $scrollable === true ? 'grab' : 'initial'};
  --cursor--link: ${({ $scrollable }) =>
    $scrollable === true ? 'inherit' : 'pointer'};
  cursor: var(--cursor--wrapper);
`

export const Logo = styled(Picture)<{ $isVisible?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  aspect-ratio: 1 / 1;
  height: var(--width--logo);
  width: calc(var(--padding-left--logo) + var(--width--logo));
  padding-left: var(--padding-left--logo);
  background: ${({ theme }) => theme.colors.pureWhite};
  pointer-events: none;
  transition: opacity 300ms ease-in-out;
  & img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

export const Links = styled.ul`
  list-style: none;
  display: flex;
  gap: 2.2rem;
  width: max-content;
  padding: 0;
  margin: 0 auto;
  padding-inline: 1.5rem;
  padding-block: 1.8rem;
`

export const Spacer = styled.div<{ $isVisible?: boolean }>`
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  width: var(--width--logo);
`

export const StyledLink = styled(Link)`
  ${({ theme }) => theme.textStyles.titleH7}
  height: 4.2rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.8rem;
  padding: 0.6rem 1.2rem;
  border-radius: 3.5rem;
  border: 0.1rem solid
    ${({ theme }) => theme.scheme.navButtonBorder ?? theme.colors.arapawa};
  white-space: nowrap;
  color: ${({ theme }) => theme.scheme.navButtonColor ?? theme.colors.arapawa};
  background-color: ${({ theme }) =>
    theme.scheme.navButtonBackground ?? theme.colors.pureWhite};
  transition: all 200ms ease-in-out;
  cursor: var(--cursor--link);
  &[aria-current] {
    cursor: default;
  }
  &:hover,
  &:active,
  &[aria-current] {
    border-color: ${({ theme }) =>
      theme.scheme.navButtonBorderActive ?? theme.colors.arapawa};
    color: ${({ theme }) =>
      theme.scheme.navButtonColorActive ?? theme.colors.pureWhite};
    background-color: ${({ theme }) =>
      theme.scheme.navButtonBackgroundActive ?? theme.colors.arapawa};
  }
  & svg {
    color: ${({ theme }) =>
      theme.scheme.navButtonBorder ?? theme.colors.arapawa};
    transition: all 200ms ease-in-out;
  }
  &:hover svg,
  &:active svg,
  &[aria-current] svg {
    color: ${({ theme }) =>
      theme.scheme.navButtonColorActive ?? theme.colors.pureWhite};
  }
  @media ${mq(sizes.tablet)} {
  }
`
export const StyledIcon = styled(Icon)``
