import { ContactUsBlockProps } from '../../components/molecules/ContactUsBlock'
import { BrandContact } from '../../graphql/generated/api-graphql'
import { Variant } from '../../components/molecules/ContactUsBlock/types'

export const brandContactTransformer = (
  t: any,
  contact?: BrandContact
): ContactUsBlockProps | undefined =>
  contact
    ? {
        title: t('brand_contact_title'),
        data: [
          ...(contact?.email
            ? [
                {
                  variant: Variant.Mail,
                  title: t('brand_contact_mail_title'),
                  entries: [
                    {
                      htmlProps: {
                        text: `<a href="mailto:${contact.email}">${contact.email}</a>`,
                      },
                    },
                  ],
                },
              ]
            : []),
          ...(contact?.phoneFree ||
          contact?.phoneIncreasedPricing ||
          contact?.phoneUnmarked
            ? [
                {
                  variant: Variant.Phone,
                  title: t('brand_contact_phone_title'),
                  entries: [
                    ...(contact?.phoneFree
                      ? [
                          {
                            htmlProps: {
                              text: `<a href="tel:${contact?.phoneFree}">${contact?.phoneFree}</a>`,
                            },
                            note: t('contact_phone_free'),
                          },
                        ]
                      : []),
                    ...(contact?.phoneUnmarked
                      ? [
                          {
                            htmlProps: {
                              text: `<a href="tel:${contact?.phoneUnmarked}">${contact?.phoneUnmarked}</a>`,
                            },
                            note: t('contact_phone_local'),
                          },
                        ]
                      : []),
                    ...(contact?.phoneIncreasedPricing
                      ? [
                          {
                            htmlProps: {
                              text: `<a href="tel:${contact?.phoneIncreasedPricing}">${contact?.phoneIncreasedPricing}</a>`,
                            },
                            note: t('contact_phone_paying'),
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
          ...(contact?.address
            ? [
                {
                  variant: Variant.Address,
                  title: t('brand_contact_address_title'),
                  entries: [
                    {
                      htmlProps: {
                        text: contact.address,
                      },
                    },
                  ],
                },
              ]
            : []),
        ],
      }
    : undefined
