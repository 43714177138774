// sorry this one is unreadable but it has so many cases

import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Html from '../../atoms/Html'
import Picture from '../../atoms/Picture'
import Video from '../../atoms/Video'
import ActionButton from '../../atoms/ActionButton'

import {
  SoloContentBlockMediaMobilePosition,
  SoloContentBlockMediaPosition,
  SoloContentBlockMediaSize,
} from './types'

export const Container = styled.section<{
  $withSide?: boolean
  $withTitle?: boolean
}>`
  --gap-container__horizontal: ${({ $withSide }) =>
    $withSide === true ? '2rem' : '0rem'};
  --width-side: ${({ $withSide }) => ($withSide === true ? '2rem' : '0rem')};
  flex: 0 0 auto;
  display: grid;
  // 2 columns if a side is present (date)
  grid-template-columns: ${({ $withSide }) =>
    $withSide === true
      ? css`
  [side] auto [content] 1fr
  `
      : css`
  [content] 1fr
  `};
  // 2 rows if a title is present
  grid-template-rows: ${({ $withTitle }) =>
    $withTitle === true
      ? css`
  [title] auto [content] 1fr
  `
      : css`
  [content] 1fr
  `};
  gap: 2rem var(--gap-container__horizontal);
  align-items: start;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.scoping / 10}rem;
  margin-inline: auto;
  @media ${mq(sizes.desktop)} {
    --gap-container__horizontal: 5rem;
    --width-side: ${({ $withSide }) => ($withSide === true ? '3rem' : '0rem')};
    gap: 3rem var(--gap-container__horizontal);
  }
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3}
  margin: 0;
  grid-column: content / span 1;
  grid-row: title / span 1;
  text-align: 'start';
  color: ${({ theme }) =>
    theme.scheme.contentBlockTitle ?? theme.colors.arapawa};
  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.titleH1}
  }
`
export const Side = styled.p`
  ${({ theme }) => theme.fonts.PoppinsMedium}
  grid-column: side / span 1;
  grid-row: 1 / -1;
  display: flex;
  flex-flow: column nowrap;
  width: var(--width-side);
  font-size: 3.2rem;
  line-height: 1;
  color: ${({ theme }) => theme.scheme.contentSide};
  & span {
    flex: 0 0 auto;
    position: relative;
    left: 50%;
    width: fit-content;
    text-align: center;
    transform: translateX(-50%);
  }
  @media ${mq(sizes.desktop)} {
    font-size: 4.8rem;
  }
`
export const Content = styled.div<{
  $mediaPosition?: SoloContentBlockMediaPosition
  $withMediaCentered?: boolean
}>`
  grid-column: content / -1;
  display: flex;
  flex-flow: ${({ $mediaPosition, theme }) =>
    theme.scheme.contentMobileMediaPosition ===
    SoloContentBlockMediaMobilePosition.Top
      ? 'column'
      : $mediaPosition === SoloContentBlockMediaPosition.Right
      ? 'column-reverse'
      : 'column'};
  gap: 2rem;
  @media ${mq(sizes.desktop)} {
    gap: 3rem;
    ${({ $mediaPosition, $withMediaCentered }) =>
      $mediaPosition === SoloContentBlockMediaPosition.Left
        ? css`
            flex-flow: row;
            align-items: ${$withMediaCentered ? 'center' : 'flex-start'};
          `
        : $mediaPosition === SoloContentBlockMediaPosition.Right
        ? css`
            flex-flow: row-reverse;
            align-items: ${$withMediaCentered ? 'center' : 'flex-start'};
          `
        : css`
            flex-flow: column;
            align-items: stretch;
          `}
  }
`
const mediaStyles = css<{
  $mediaPosition?: SoloContentBlockMediaPosition
  $mediaSize?: SoloContentBlockMediaSize
}>`
  border-radius: 2rem;
  overflow: hidden;
  @media ${mq(sizes.desktop)} {
    flex: 0 0 auto;
    width: ${({ $mediaPosition, $mediaSize }) =>
      $mediaPosition === SoloContentBlockMediaPosition.Left ||
      $mediaPosition === SoloContentBlockMediaPosition.Right
        ? $mediaSize === SoloContentBlockMediaSize.Big
          ? 'calc(100% / 3 * 2 - 1.5rem)'
          : 'calc(100% / 2 - 1.5rem)'
        : '100%'};
    border-radius: 3rem;
  }
`
export const StyledPicture = styled(Picture)`
  ${mediaStyles}
  &,
  & img {
    display: block;
  }
  & img {
    width: 100%;
    height: auto;
  }
`
export const StyledVideo = styled(Video)`
  ${mediaStyles}
`
export const Text = styled.div`
  display: flex;
  flex-flow: column;
  gap: 3rem;
  padding: 2rem;
  border-radius: 2rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  @media ${mq(sizes.desktop)} {
    padding: 3rem 4rem;
  }
`
export const StyledHtml = styled(Html)`
  ${({ theme }) => theme.textStyles.textMedium}
  & a {
    color: ${({ theme }) => theme.scheme.HighLightToneColor};
  }
  & b,
  & strong {
    ${({ theme }) => theme.textStyles.textMediumStrong};
  }
  & h5,
  #as-h5 {
    color: ${({ theme }) =>
      theme.scheme.contentInsideSubTitle ??
      theme.scheme.contentInsideTitle ??
      theme.colors.arapawa};
    margin-bottom: 0;
    ${({ theme }) => theme.textStyles.titleH5};
  }
  & h1,
  & h2,
  & h3,
  & h4,
  & h6 {
    margin: 0;
    color: ${({ theme }) =>
      theme.scheme.contentInsideTitle ?? theme.colors.arapawa};
  }
  & h1:not(:first-child),
  & h2:not(:first-child),
  & h3:not(:first-child),
  & h4:not(:first-child),
  & h5:not(:first-child),
  & #as-h5:not(:first-child),
  & h6:not(:first-child) {
    margin-block-start: 3rem;
  }
  & h1,
  & h2,
  & h3,
  & h4 {
    ${({ theme }) => theme.textStyles.titleH4};
  }
  & h5,
  & h6 {
    ${({ theme }) => theme.textStyles.titleH5};
  }
  & p:not(:first-child) {
    margin-block-start: 1rem;
  }
  & p:not(:last-child) {
    padding-bottom: 0;
  }
  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.textXLarge}
    & b,
    & strong {
      ${({ theme }) => theme.textStyles.textXLargeStrong};
    }
    & h1,
    & h2,
    & h3,
    & h4 {
      ${({ theme }) => theme.textStyles.titleH3};
    }
    & h5,
    & h6 {
      ${({ theme }) => theme.textStyles.titleH4};
    }
  }
`
export const StyledActionButton = styled(ActionButton)`
  align-self: flex-start;
`
