import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'

import { SoloContentTitleSize, SoloContentTitleVariant } from './types'

export const Container = styled.h2<{ $variant: SoloContentTitleVariant }>`
  ${({ theme, $variant }) =>
    $variant === SoloContentTitleVariant.Alternative
      ? css`
          ${theme.scheme.contentSectionTitleVariant === SoloContentTitleSize.Big
            ? css`
                ${theme.fonts.PoppinsBold}
                margin-bottom: -5rem;
              `
            : css`
                ${theme.fonts.PoppinsLight}
              `}
          flex: 0 0 auto;
          position: relative;
          width: 100%;
          max-width: ${theme.sizes.scoping / 10}rem;
          margin-inline: auto;
          margin-block: 0;
          text-align: center;
          font-size: 3.2rem;
          line-height: 1.2;
          color: ${theme.scheme.contentSectionTitle ?? theme.colors.arapawa};
          & p {
            position: relative;
            z-index: 2;
            margin: 0;
          }
          @media ${mq(sizes.desktop)} {
            font-size: 4.8rem;
          }
        `
      : css`
          ${theme.textStyles.titleH2}
          margin: 0;
          color: ${theme.scheme.historyTitle ?? theme.colors.arapawa};
          text-align: center;
          @media ${mq(sizes.desktop)} {
            ${theme.textStyles.titleH1}
          }
        `}
`
