import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { SoloCapriceValentineStep } from '../../../components/molecules/SoloCapriceValentineGame/types'
import { useServiceRequest } from '../../../hooks/useServiceRequest'
import { actions, selectors } from '../../../redux'
import { SoloCapriceValentineGameLoadProps } from '../../../components/molecules/SoloCapriceValentineGame/partial/SoloCapriceValentineGameLoad'

export const useCapriceValentineGameGpt =
  (): SoloCapriceValentineGameLoadProps => {
    const step = useSelector(selectors.solo.capriceValentineStep)
    const session = useSelector(selectors.solo.capriceValentineSession)

    const [, fetchResponseHandler] = useServiceRequest(
      selectors.solo.capriceValentineFetchSessionResponse,
      actions.solo.capriceValentineFetchSessionResponseRequest,
      actions.solo.capriceValentineFetchSessionResponseReset
    )

    useEffect(() => {
      if (step === SoloCapriceValentineStep.GPT && session?.sessionUid) {
        fetchResponseHandler({
          sessionUid: session?.sessionUid,
        })
      }
    }, [step, session?.sessionUid, fetchResponseHandler])

    return {
      text: 'Votre Saint Valentin  idéale est concoctée avec amour<br/>par Caprice des Dieux et l’INTELLIGENCE ARTIFICIELLE',
    }
  }
