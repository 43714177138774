import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import dayjs from 'dayjs'

import {
  SoloCapriceValentineError,
  SoloCapriceValentineStep,
} from '../../../components/molecules/SoloCapriceValentineGame/types'
import { actions, selectors } from '../../../redux'
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import { Icons } from '../../../components/atoms/Icon'
import { SoloCapriceValentineGameErrorProps } from '../../../components/molecules/SoloCapriceValentineGame/partial/SoloCapriceValentineGameError'
import Router, { routes } from '../../../routes/Router'
import { ActionButtonProps } from '../../../components/atoms/ActionButton'
import { CapriceMaxSessionCount } from '../../../redux/solo/types'

import { useCapriceValentineGameSocials } from './useCapriceValentineGameSocials'

export const useCapriceValentineGameError = (
  startDate?: string,
  endDate?: string
): SoloCapriceValentineGameErrorProps => {
  const dispatch = useDispatch()
  const error = useSelector(selectors.solo.capriceValentineError)
  const { query } = useRouter()

  const count = useSelector(selectors.solo.capriceValentineCount)

  const socialProps = useCapriceValentineGameSocials()

  const backToBrandPage: ActionButtonProps = {
    text: "RETOUR À L'ACCUEIL",
    variant: ActionButtonVariant.red,
    iconPosition: 'left',
    iconProps: {
      icon: Icons.chevronLeft,
    },
    href: Router.getRouteUrl(routes.brand, { slug: query.slug }),
  }

  const backToStart: ActionButtonProps = {
    text: 'RETOUR',
    variant: ActionButtonVariant.red,
    iconPosition: 'left',
    iconProps: {
      icon: Icons.chevronLeft,
    },
    onClick: () =>
      dispatch(
        actions.solo.setCapriceValentineStep(SoloCapriceValentineStep.INTRO)
      ),
  }

  // Check if game is closed
  useEffect(() => {
    if (
      (endDate && dayjs(endDate, 'YYYY-MM-DD HH:mm:ss').isBefore(dayjs())) ||
      (startDate && dayjs(startDate, 'YYYY-MM-DD HH:mm:ss').isAfter(dayjs()))
    ) {
      dispatch(
        actions.solo.setCapriceValentineError(
          SoloCapriceValentineError.caprice_valentine_game_closed
        )
      )
      dispatch(
        actions.solo.setCapriceValentineStep(SoloCapriceValentineStep.ERROR)
      )
    }
  }, [endDate, startDate, dispatch])

  // Game is not closed anymore, go back to intro
  useEffect(() => {
    console.log('startDate', startDate)
    console.log('endDate', endDate)
    if (
      error &&
      error === SoloCapriceValentineError.caprice_valentine_game_closed &&
      !(
        (endDate && dayjs(endDate, 'YYYY-MM-DD HH:mm:ss').isBefore(dayjs())) ||
        (startDate && dayjs(startDate, 'YYYY-MM-DD HH:mm:ss').isAfter(dayjs()))
      )
    ) {
      dispatch(actions.solo.setCapriceValentineError(null))
      dispatch(
        actions.solo.setCapriceValentineStep(SoloCapriceValentineStep.INTRO)
      )
    }
  }, [endDate, startDate, error, dispatch])

  if (
    error ===
      SoloCapriceValentineError.caprice_valentine_max_sessions_reached ||
    count >= CapriceMaxSessionCount
  ) {
    return {
      text:
        '<p>Malheureusement, vous avez déjà participé 3 fois au jeu concours, ce qui est le nombre maximal de participations par personne.</p>' +
        '<p>En attendant, vous pouvez retrouver Caprice des Dieux sur les réseaux sociaux.</p>',
      actions: [backToBrandPage],
      socialProps,
    }
  }

  if (error === SoloCapriceValentineError.caprice_valentine_game_closed) {
    if (dayjs(endDate, 'YYYY-MM-DD HH:mm:ss').isBefore(dayjs())) {
      return {
        title: 'Jeu concours terminé !',
        text: 'Merci à tous pour votre participation ! Notre jury va élire la Saint-Valentin la plus Complices à Deux. Les gagnants recevront un email pour réaliser leur soirée romantique personnalisée.',
        actions: [backToBrandPage],
        socialProps,
      }
    } else {
      return {
        title: 'Malheureusement le jeu n’a pas encore commencé !',
        text: `Revenez à partir du <strong>${dayjs(
          startDate,
          'YYYY-MM-DD HH:mm:ss'
        ).format('DD/MM/YYYY à HH[h]mm')}</strong>`,
        actions: [backToBrandPage],
      }
    }
  }

  if (
    error ===
    SoloCapriceValentineError.caprice_valentine_max_input_tries_reached
  ) {
    return {
      text:
        '<p>Malheureusement, vos propositions ne respectent pas la charte bienveillante de la marque Caprice des Dieux...</p>' +
        '<p>Vous avez atteint le nombre de tentatives maximum sur ce jeu.</p>',
      actions: [backToStart],
    }
  }

  // no session / other error

  return {
    title:
      'Votre Saint Valentin  idéale est concoctée avec amour<br/>par Caprice des Dieux et l’INTELLIGENCE ARTIFICIELLE',
    text: 'Veuillez démarrer une nouvelle session',
    actions: [backToStart],
  }
}
