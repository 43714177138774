import { SoloBannerProps } from '../../../components/molecules/SoloBanner'
import { SoloBannerSize } from '../../../components/molecules/SoloBanner/types'
import { SoloBannerBlock } from '../../../graphql/generated/api-graphql'
import { wediaImagesTransform } from '../../Common/wedia'

const transformSoloBannerBlock = (
  data: SoloBannerBlock
): SoloBannerProps | undefined => {
  return data && data?.image
    ? {
        title: data?.title ?? undefined,
        subtitle: data?.subTitle ?? undefined,
        htmlTitleTag: 'h1',
        banner: {
          maxWidth: 3200,
          width: data.image.variations?.[0].width ?? undefined,
          height: data.image.variations?.[0].height ?? undefined,
          withHD: true,
          preload: true,
          //withLazyLoading: true,
          images: wediaImagesTransform(data.image),
          alt: data.image?.alt ?? ' ',
        },
        mobileBanner: data.imageMobile
          ? {
              maxWidth: 2000,
              width: data.imageMobile.variations?.[0].width ?? undefined,
              height: data.imageMobile.variations?.[0].height ?? undefined,
              withHD: true,
              images: wediaImagesTransform(data.imageMobile),
              alt: data.imageMobile?.alt ?? ' ',
            }
          : undefined,
        size:
          data?.size === SoloBannerSize.Big
            ? SoloBannerSize.Big
            : SoloBannerSize.Default,
        roundedBottom: data?.bottomRoundedCorners ?? true,
      }
    : undefined
}

export default transformSoloBannerBlock
