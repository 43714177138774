import { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'

import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import { Icons } from '../../../components/atoms/Icon'
import { SoloCapriceValentineStep } from '../../../components/molecules/SoloCapriceValentineGame/types'
import { SoloCapriceValentineGameInputProps } from '../../../components/molecules/SoloCapriceValentineGame/partial/SoloCapriceValentineGameInput'
import FormFieldTextAreaLimit from '../../../components/form/fields/FormFieldTextAreaLimit'
import { imageMocks } from '../../../components/atoms/Picture/mocks'
import { useServiceRequest } from '../../../hooks/useServiceRequest'
import { actions, selectors } from '../../../redux'
import { MutationSoloPageCapriceValentineSaveSessionInputArgs } from '../../../graphql/generated/api-graphql'
import tracking, {
  TrackingBrandName,
  TrackingCategoryType,
  TrackingContentGroup,
  TrackingUserType,
} from '../../../tracking'

export const useCapriceValentineGameInput = (
  suggestions: string[],
  recaptchaReady: boolean
): SoloCapriceValentineGameInputProps => {
  const step = useSelector(selectors.solo.capriceValentineStep)
  const [suggestionsOpen, setSuggestionsOpen] = useState(false)
  const [suggestion, setSuggestion] = useState('')
  const [prompt, setPrompt] = useState('')
  const session = useSelector(selectors.solo.capriceValentineSession)
  const user = useSelector(selectors.auth.user)

  const onComplete = useCallback(() => {
    setPrompt('')
    setSuggestion('')
    setSuggestionsOpen(false)
  }, [])

  // clear on change step
  useEffect(() => {
    setPrompt('')
    setSuggestion('')
    setSuggestionsOpen(false)
  }, [step])

  const [saveInput, saveInputHandler] = useServiceRequest(
    selectors.solo.capriceValentineSaveSessionInput,
    actions.solo.capriceValentineSaveSessionInputRequest,
    actions.solo.capriceValentineSaveSessionInputReset,
    onComplete
  )

  const openSuggestions = useCallback(() => {
    setSuggestionsOpen(true)
    tracking.page({
      group: TrackingContentGroup.GAME,
      page:
        step === SoloCapriceValentineStep.INPUT1
          ? "Jeu Caprice des dieux 2024 - Etape 3.2 Jai besoin d'inspiration"
          : "Jeu Caprice des dieux 2024 - Etape 4.2 Jai besoin d'inspiration",
      section: TrackingCategoryType.BENEFITS,
      brand: TrackingBrandName.CAPRICE,
      userType:
        user && user?.id ? TrackingUserType.MEMBER : TrackingUserType.VISITOR,
      userId: user?.id ?? undefined,
    })
  }, [step, user])

  return {
    title:
      step === SoloCapriceValentineStep.INPUT1
        ? 'Décrivez votre Saint Valentin idéale'
        : 'À votre tour !</br>Décrivez votre Saint Valentin idéale',
    submit: {
      disabled: !recaptchaReady,
      text: 'VALIDER',
      isPending: saveInput.pending,
      variant: ActionButtonVariant.red,
      iconPosition: 'right',
      iconProps: {
        icon: saveInput.pending ? Icons.refresh : Icons.arrowRight,
      },
    },
    error: saveInput.errors?.[0]?.label,
    formProps: {
      enableReinitialize: true,
      initialValues: {
        prompt,
      },
      validateOnChange: true,
      validationSchema: Yup.object().shape({
        prompt: Yup.string()
          .min(30, '30 caractères minimum !')
          .max(150, '150 caractères maximum !')
          .required('50 caractères minimum !'),
      }),
      onSubmit: (v) =>
        saveInputHandler({
          input: v.prompt,
          sessionUid: session?.sessionUid ?? '',
          order: step === SoloCapriceValentineStep.INPUT1 ? 1 : 2,
        } as MutationSoloPageCapriceValentineSaveSessionInputArgs),
    },
    textarea: {
      name: 'prompt',
      Component: FormFieldTextAreaLimit,
      limit: 150,
      rows: 5,
      placeholder:
        'Ma Saint Valentin vrAIment Complices à Deux : organiser une soirée romantique avec un repas à base de Mini Caprice, en écoutant la musique Just the two of us',
      hideErrors: true,
      onChange: (e) => setSuggestion(e.target.value),
    },
    help: {
      text: "j'ai besoin d’inspiration",
      variant: ActionButtonVariant.yellow,
      onClick: openSuggestions,
    },
    advice: {
      title: 'CONSEILS DE RÉDACTION',
      text:
        'Écrivez un texte bref et concis, sans vulgarité, cela reste un jeu, soyez créatif !  Vous pouvez saisir vos propres idées ou utiliser nos inspirations.<br/>' +
        'Ne divulguez pas d’informations personnelles.',
    },
    stepperProps: {
      text:
        step === SoloCapriceValentineStep.INPUT1
          ? `ÉTAPE 1 : VOTRE SAINT VALENTIN IDÉALE`
          : `ÉTAPE 2 : AU TOUR DE VOTRE PARTENAIRE`,
      value: step === SoloCapriceValentineStep.INPUT1 ? 0 : 1,
      total: 3,
    },
    suggestionsProps: {
      isOpen: suggestionsOpen,
      closeHandler: () => setSuggestionsOpen(false),
      onChange: (v) => setSuggestion(v),
      onSubmit: (v) => {
        setPrompt(v)
        setSuggestionsOpen(false)
      },
      logoProps: {
        ...imageMocks,
        images: [
          {
            src: '/static/assets/images/caprice/caprice-logo.png',
            size: 400,
          },
        ],
      },
      title: 'Nos idées de Saint Valentin<br/>vraiment Complices à Deux',
      options: suggestions,
      value: suggestion,
      cancelButton: {
        text: 'ANNULER',
        variant: ActionButtonVariant.secondary,
        onClick: () => setSuggestionsOpen(false),
      },
      submitButton: {
        isPending: saveInput.pending,
        text: 'CONFIRMER',
        iconPosition: 'right',
        iconProps: {
          icon: saveInput.pending ? Icons.refresh : Icons.check,
        },
      },
    },
  }
}
