import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  SoloTitleBodyCtaBlock as Data,
  LinkTarget,
} from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import SoloActionBlock, {
  SoloActionBlockProps,
} from '../../../components/molecules/SoloActionBlock'

export type SoloTitleBodyCtaBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

const SoloTitleBodyCtaBlock: FC<SoloTitleBodyCtaBlockProps> = ({ data }) => {
  const { t } = useTranslation()
  const props: SoloActionBlockProps = {
    title: data.title ?? undefined,
    htmlProps: {
      text: data.body ?? undefined,
    },
    actionButtonProps: data.ctaBlock?.href
      ? {
          href: data.ctaBlock.href,
          label: data.ctaBlock.label ?? t('learn_more'),
          target:
            data.ctaBlock?.target === LinkTarget.Blank ? '_blank' : '_self',
        }
      : undefined,
  }
  return <SoloActionBlock {...props} />
}

export default SoloTitleBodyCtaBlock
