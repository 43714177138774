import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ArticleUnion,
  SoloArticleUnionFourItemsBlock as Data,
} from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import { CardsBlockProps } from '../../../components/molecules/CardsBlock'
import { transformArticleBigCard } from '../../ArticleCard/transformArticleBigCard'
import { transformArticleCard } from '../../ArticleCard/transformArticleCard'
import tracking from '../../../tracking'
import Router, { routes } from '../../../routes/Router'
import SoloCardsBlock from '../../../components/molecules/SoloCardsBlock'
import { SearchType } from '../../SearchEngine/searchType'

export type SoloArticleUnionFourItemsBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

const SoloArticleUnionFourItemsBlock: FC<
  SoloArticleUnionFourItemsBlockProps
> = ({ data, brandId }) => {
  const { t } = useTranslation()

  const props: CardsBlockProps = {
    title: data?.title ?? '',
    highlighted:
      data?.articleUnions && data?.articleUnions?.length > 3
        ? transformArticleBigCard(
            t,
            data?.articleUnions?.[0],
            data.title ?? undefined,
            false
          )
        : undefined,
    cards: data?.articleUnions
      ?.slice(data?.articleUnions && data?.articleUnions?.length > 3 ? 1 : 0)
      ?.map((item: ArticleUnion) =>
        transformArticleCard(t, item, data?.title ?? '', false)
      ),
    actionButtonProps: {
      label: t('see_more_articles'),
      onClick: () => {
        tracking.cta(
          t('see_more_articles') ?? undefined,
          data?.title ?? undefined
        )
        // Use onclick instead of href because of SEO recommendation
        Router.push(
          Router.getRouteUrl(routes.searchType, {
            type: SearchType.Article,
            marques: brandId,
          })
        )
      },
    },
  }

  return <SoloCardsBlock {...props} />
}

export default SoloArticleUnionFourItemsBlock
