import React, { FC } from 'react'

import Footer, { FooterProps } from '../../components/molecules/Footer'
import { HeaderProps } from '../../components/molecules/Header'
import ProductPopin, {
  ProductPopinProps,
} from '../../components/molecules/ProductPopin'
import WipBanner from '../../components/molecules/WipBanner'
import SoloNav, { SoloNavProps } from '../../components/molecules/SoloNav'
import SoloHero, { SoloHeroProps } from '../../components/molecules/SoloHero'
import SoloAnchors, {
  SoloAnchorsProps,
} from '../../components/molecules/SoloAnchors'
import SoloBanner, {
  SoloBannerProps,
} from '../../components/molecules/SoloBanner'
import { ContactUsBlockProps } from '../../components/molecules/ContactUsBlock'
import SoloSocials, {
  SoloSocialsProps,
} from '../../components/molecules/SoloSocials'
import SoloContentAnchors, {
  SoloContentAnchorsProps,
} from '../../components/molecules/SoloContentAnchors'
import SoloHistoryAnchors, {
  SoloHistoryAnchorsProps,
} from '../../components/molecules/SoloHistoryAnchors'
import { AdProps } from '../../components/molecules/Ad'

import * as SC from './styled'

export enum WipState {
  INTEGRATION_PENDING = 'Intégration en cours',
  DYNAMIZATION_PENDING = 'Dynamisation en cours',
}

export type SoloLayoutProps = Pick<MinimalSeoBlock, 'htmlTitleTag'> & {
  className?: string
  idContent?: string
  headerProps?: HeaderProps
  footerProps?: FooterProps
  soloNavProps?: SoloNavProps
  soloContentAnchorsProps?: SoloContentAnchorsProps
  soloHistoryAnchorsProps?: SoloHistoryAnchorsProps
  soloHeroProps?: SoloHeroProps
  soloAnchorsProps?: SoloAnchorsProps
  soloBannerProps?: SoloBannerProps
  contactUsProps?: ContactUsBlockProps
  soloSocialsProps?: SoloSocialsProps
  title?: string
  children?: React.ReactNode
  productPopinProps?: ProductPopinProps
  withMobileModal?: boolean
  wipState?: WipState
  topBlocks?: React.ReactNode
  adProps?: AdProps
}

const SoloLayout: FC<SoloLayoutProps> = ({
  className,
  idContent = 'Content',
  headerProps,
  footerProps,
  soloNavProps,
  soloContentAnchorsProps,
  soloHistoryAnchorsProps,
  soloHeroProps,
  soloAnchorsProps,
  soloBannerProps,
  contactUsProps,
  soloSocialsProps,
  title,
  htmlTitleTag,
  children,
  productPopinProps,
  withMobileModal,
  wipState,
  topBlocks,
  adProps,
}) => {
  return (
    <>
      {wipState && <WipBanner text={wipState} />}
      <SC.Layout className={className} $adBackground={adProps?.background}>
        {headerProps && <SC.StyledHeader {...headerProps} />}
        {adProps && <SC.StyledAd {...adProps} />}
        <SC.Main id={idContent} $withMobileModal={withMobileModal}>
          <SC.Top>
            {soloHeroProps && <SoloHero {...soloHeroProps} />}
            {soloNavProps && <SoloNav {...soloNavProps} />}
            {/* 78 hardcoded for now but might need to use the actual size of the Nav, so might need the use of forwarded Props to get it... */}
            {soloAnchorsProps && (
              <SoloAnchors offset={78} {...soloAnchorsProps} />
            )}
            {soloBannerProps && <SoloBanner {...soloBannerProps} />}
          </SC.Top>
          {soloHistoryAnchorsProps && (
            <SoloHistoryAnchors {...soloHistoryAnchorsProps} />
          )}
          {topBlocks}
          {(children || title || soloContentAnchorsProps) && (
            <SC.Content>
              {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
              {soloContentAnchorsProps && (
                <SoloContentAnchors {...soloContentAnchorsProps} />
              )}
              {children}
            </SC.Content>
          )}
          {(contactUsProps || soloSocialsProps) && (
            <SC.Bottom>
              {contactUsProps && <SC.StyledContact {...contactUsProps} />}
              {soloSocialsProps && <SoloSocials {...soloSocialsProps} />}
            </SC.Bottom>
          )}
        </SC.Main>
        {footerProps && <Footer {...footerProps} />}
      </SC.Layout>
      {productPopinProps && <ProductPopin {...productPopinProps} />}
    </>
  )
}

export default SoloLayout
