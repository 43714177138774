import styled from 'styled-components'

import AllFiltersForm from '../../forms/AllCheesesFiltersForm'
import CardsBlock from '../CardsBlock'
import Pagination from '../Pagination'

export const Container = styled.section``
export const StyledAllFiltersForm = styled(AllFiltersForm)``
export const StyledCardsBlock = styled(CardsBlock)`
  padding-inline: 0;
  ${StyledAllFiltersForm} + & {
    margin-top: 3rem;
  }
`
export const StyledPagination = styled(Pagination)`
  &:not(:first-child) {
    margin-block-start: 2rem;
  }
`
