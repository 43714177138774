import styled, { css } from 'styled-components'

import FormLabel from '../../FormLabel'

type InputStyleProps = {
  $isError?: boolean
  $isActive?: boolean
  $hasLabel?: boolean
  $hasFloatingLabel?: boolean
  $isDisabled?: boolean
  $hasErrorMessage?: boolean
}

export const Container = styled.div<InputStyleProps>`
  display: flex;
  flex-flow: column;

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      opacity: 0.8;
      &,
      & * {
        cursor: not-allowed;
      }
    `}
`
export const Wrapper = styled.label``

export const InputContainer = styled.div<InputStyleProps>`
  border: 1px solid ${({ theme }) => theme.colors.pattensBlue};
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 0.6rem;
  position: relative;
  padding: 1.8rem 2rem;
  width: 100%;

  ${({ $isError, theme }) =>
    $isError === true &&
    css`
      border-color: ${theme.colors.error};
    `}
`

export const Label = styled(FormLabel)<{
  $active?: boolean
  $hasFloatingLabel?: boolean
  $required?: boolean
}>`
  ${({ theme }) => theme.textStyles.textSmallStrong}
  color: ${({ theme }) => theme.colors.bismark};
  &::after {
    color: ${({ theme }) => theme.colors.bismark}!important;
  }
  ${(props) =>
    props.$hasFloatingLabel &&
    css`
      position: absolute;
      transition: top 0.2s, font-size 0.2s, transform 0.2s, font-weight 0.2s;
      top: 1rem;
      margin: auto;
    `}
  ${(props) =>
    props.$active &&
    css`
      ${({ theme }) => theme.textStyles.textSmall}
      top: 0.75rem;
    `}
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  position: relative;
`

export const inputStyle = css<InputStyleProps>`
  ${({ theme }) => theme.textStyles.textSmall};
  line-height: 1;
  width: 100%;
  border: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.pureBlack};
  background: transparent;

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      pointer-events: none;
    `}

  ${({ $hasLabel, $hasFloatingLabel, $isActive }) =>
    $hasLabel &&
    $hasFloatingLabel &&
    css`
      position: relative;
      transition: top 0.2s;
      top: 0.5rem;
      ${$isActive &&
      css`
        top: 1rem;
      `}
    `}

  &::placeholder {
    opacity: 0.5;
  }

  // readonly
  &[readonly] {
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &[type='password'] {
    letter-spacing: 3px;
  }
`

export const Input = styled.textarea<InputStyleProps>`
  ${inputStyle}
`

export const HelpMessage = styled.p`
  ${({ theme }) => theme.textStyles.textSmall}
  color: ${({ theme }) => theme.colors.pureBlack};
  text-align: left;
`
export const ErrorMessage = styled.p`
  ${({ theme }) => theme.textStyles.textSmall}
  color: ${({ theme }) => theme.colors.error};
  text-align: left;
`
