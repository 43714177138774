import { SoloCapriceValentineGameSocialsProps } from '../../../components/molecules/SoloCapriceValentineGame/partial/SoloCapriceValentineGameSocials'
import { Icons } from '../../../components/atoms/Icon'

export const useCapriceValentineGameSocials =
  (): SoloCapriceValentineGameSocialsProps => {
    return {
      text: 'On reste en contact ?',
      actions: [
        {
          iconProps: {
            icon: Icons.socialInstagram,
          },
          linkProps: {
            href: 'https://www.instagram.com/capricedesdieuxfr/',
            target: '_blank',
          },
        },
        {
          iconProps: {
            icon: Icons.socialFacebook,
          },
          linkProps: {
            href: 'https://www.facebook.com/capricedesdieux/',
            target: '_blank',
          },
        },
      ],
    }
  }
