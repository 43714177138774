import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Html from '../../atoms/Html'
import ActionButton from '../../atoms/ActionButton'

import { Variant } from './types'

export const ContactUsBlock = styled.aside`
  padding: 3rem 2rem;
  color: ${({ theme }) => theme.colors.arapawa};
  background: ${({ theme }) => theme.colors.pureWhite};
  & a {
    color: inherit;
  }
  @media ${mq(sizes.tablet)} {
    padding: 4rem;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH2}
  margin: 0;
  color: ${({ theme }) =>
    theme.scheme.contactUsTitleColor ?? theme.colors.arapawa};
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH1}
  }
`
export const List = styled.dl`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-gap: 3rem;
  margin: 0;
  &:not(:first-child) {
    margin-top: 3rem;
  }
  @media ${mq(sizes.tablet)} {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }
`
export const Item = styled.div``
export const Label = styled.dt`
  ${({ theme }) => theme.textStyles.titleH5}
  color: ${({ theme }) =>
    theme.scheme.contactUsSubtitleColor ?? theme.colors.ruby};
`
export const Info = styled.dd`
  margin: 0;
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`
export const InfoMain = styled(Html)<{ $variant?: Variant }>`
  ${({ $variant, theme }) =>
    $variant === Variant.Phone
      ? theme.textStyles.textXLarge
      : theme.textStyles.textCaptions}
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
  & > * {
    margin: 0;
  }
  & > *:not(:first-child) {
    margin-top: 0.5rem;
  }
`
export const InfoNote = styled.p`
  ${({ theme }) => theme.textStyles.textSmallStrong}
  color: ${({ theme }) => theme.colors.bismark};
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`
export const StyledActionButton = styled(ActionButton)`
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`
