import styled from 'styled-components'

import Icon from '../Icon'

export const Container = styled.div`
  position: relative;
  aspect-ratio: 1080 / 608;
`

export const Video = styled.video`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: black;
`
export const PlayButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
`
export const Play = styled(Icon)`
  width: 10rem;
  height: 10rem;
  color: ${({ theme }) => theme.scheme.HighLightToneColor ?? theme.colors.ruby};
  opacity: 0.9;
`
