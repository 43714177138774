import { IconType, Icons } from '../../../components/atoms/Icon'
import { SoloSocialsProps } from '../../../components/molecules/SoloSocials'
import {
  SocialNetwork,
  SoloSocialNetworksBlock,
} from '../../../graphql/generated/api-graphql'

export const transformSoloSocialNetworksBlock = (
  t?: any,
  data?: SoloSocialNetworksBlock
): SoloSocialsProps | undefined => {
  return data?.socialNetworks && data.socialNetworks.length > 0
    ? {
        title: t('footer_brand_social'),
        socials: data.socialNetworks.map((social) => {
          const capitalize = (s: string) => {
            return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
          }
          const icon = (s: string): IconType => {
            switch (s) {
              case 'FACEBOOK':
                return Icons.socialFacebook
              case 'INSTAGRAM':
                return Icons.socialInstagram
              case 'TWITTER':
                return Icons.socialX
              case 'PINTEREST':
                return Icons.socialPinterest
              case 'YOUTUBE':
                return Icons.socialYoutube
              default:
                return Icons.cheese
            }
          }
          return {
            linkProps: {
              href: social.url,
              label: capitalize(social.type),
            },
            iconProps: {
              icon: icon(social.type as SocialNetwork),
            },
          }
        }),
      }
    : undefined
}
