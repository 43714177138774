// concerns both simple banner and a big banner (for caprice)

import { FC } from 'react'
import { PictureProps } from 'src/components/atoms/Picture'
import cx from 'classnames'

import Html from '../../atoms/Html'

import * as SC from './styled'
import { SoloBannerSize } from './types'

export type SoloBannerProps = MinimalSeoBlock & {
  className?: string
  title?: string
  subtitle?: string
  banner: PictureProps
  mobileBanner?: PictureProps
  roundedTop?: boolean
  roundedBottom?: boolean
  size?: SoloBannerSize
}

const SoloBanner: FC<SoloBannerProps> = ({
  className,
  htmlTag,
  htmlTitleTag,
  title,
  subtitle,
  banner,
  mobileBanner,
  roundedTop = true,
  roundedBottom = true,
  size,
}) => {
  return (
    <SC.Container
      className={cx('SoloBanner', className)}
      as={htmlTag}
      $size={size}
    >
      {(title || subtitle) && (
        <SC.Title as={htmlTitleTag} $size={size}>
          {title && <span>{title}</span>}
          {` `}
          {subtitle && <Html htmlTag={'span'}>{subtitle}</Html>}
        </SC.Title>
      )}
      <SC.Images $size={size}>
        <SC.Banner
          {...banner}
          $withMobileALternative={mobileBanner ? true : false}
        />
        {mobileBanner && <SC.MobileBanner {...mobileBanner} />}
      </SC.Images>
      {roundedTop && (
        <SC.TopBezel
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 1440 20"
          preserveAspectRatio="none"
          aria-hidden
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M0 20V.00002289h722.948C428.642.0178547 166.917 7.84947 0 20ZM724.963.00002289H1440V19.4324C1272.79 7.60612 1014.66.0175762 724.963.00002289Z"
            clipRule="evenodd"
          />
        </SC.TopBezel>
      )}
      {roundedBottom && (
        <SC.BottomBezel
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 1440 20"
          preserveAspectRatio="none"
          aria-hidden
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M0-.00012207V19.9997h721.267C427.645 19.9522 166.6 12.1273 0-.00012207ZM726.644 19.9997H1440V.567476C1273.11 12.3709 1015.66 19.9529 726.644 19.9997Z"
            clipRule="evenodd"
          />
        </SC.BottomBezel>
      )}
    </SC.Container>
  )
}

export default SoloBanner
