import React, { FC } from 'react'

import { FormFieldTextAreaProps } from '../FormFieldTextArea'

import * as SC from './styled'

export type FormFieldTextAreaLimitProps = FormFieldTextAreaProps & {
  limit: number
}

const FormFieldTextAreaLimit: FC<FormFieldTextAreaLimitProps> = (props) => {
  const { limit, ...extraProps } = props

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    extraProps.onChange?.({
      ...e,
      target: {
        ...e.target,
        name: e.target.name,
        value: e.target.value?.substring(0, limit)?.replace('\n', ''),
      },
    } as any)
  }

  return (
    <SC.Input
      {...extraProps}
      onChange={onChange}
      helpText={`${String(extraProps?.value)?.length ?? 0}/${limit}`}
    />
  )
}

export default FormFieldTextAreaLimit
