import styled from 'styled-components'

import Html from '../../atoms/Html'
import { mq, sizes } from '../../../theme'

export const Container = styled(Html)`
  ${({ theme }) => theme.textStyles.textMedium}
  color: ${({ theme }) => theme.colors.matterhorn};
  & a {
    color: ${({ theme }) => theme.scheme.HighLightToneColor};
    text-decoration: underline;
  }
  & a:hover {
    text-decoration: none;
  }
  & b,
  & strong {
    ${({ theme }) => theme.textStyles.textMediumStrong}
  }
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.textXLarge}
    & b,
    & strong {
      ${({ theme }) => theme.textStyles.textXLargeStrong}
    }
  }
`
