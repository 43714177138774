import {
  SoloHistoryContainerBlock,
  SoloPage,
} from '../../graphql/generated/api-graphql'
import { SoloHistoryAnchorsProps } from '../../components/molecules/SoloHistoryAnchors'

const transformSoloHistoryAnchors = (
  data?: SoloPage
): SoloHistoryAnchorsProps | undefined => {
  const blocks = data?.blocks?.filter(
    (item) => item.__typename === 'SoloHistoryContainerBlock'
  ) as SoloHistoryContainerBlock[] | undefined
  const years =
    blocks && blocks.length > 0
      ? blocks.map((block) => (block.years ? block.years : []))?.flat()
      : undefined

  // don't return the menu unless more than 1 item
  return years && years.length > 1
    ? {
        items: years.map((year) => {
          return {
            linkProps: {
              label: year,
              href: `#${year}`,
            },
          }
        }),
        offset: 78,
      }
    : undefined
}

export default transformSoloHistoryAnchors
