import React, { FC } from 'react'
import cx from 'classnames'

import BaseModal, { BaseModalProps } from '../../../LightAuthModal/BaseModal'

import * as SC from './styled'

export type SoloCapriceValentineGameDownloadModalProps = BaseModalProps & {
  className?: string
  title?: string
  text?: string
  imagePath?: string
}

const SoloCapriceValentineGameDownloadModal: FC<
  SoloCapriceValentineGameDownloadModalProps
> = (props) => {
  const { className, title, imagePath, text, ...modalProps } = props

  return (
    <BaseModal
      className={cx('SoloCapriceValentineGameDownloadModal', className)}
      hideCloseButton
      {...modalProps}
    >
      <SC.Content>
        <SC.Title>{title}</SC.Title>
        <SC.ImageContainer>
          {imagePath && <SC.Image src={imagePath} />}
        </SC.ImageContainer>
        <SC.Text>{text}</SC.Text>
      </SC.Content>
    </BaseModal>
  )
}

export default SoloCapriceValentineGameDownloadModal
