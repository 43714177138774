import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import Html from '../../atoms/Html'
import ActionButton from '../../atoms/ActionButton'

export const Container = styled.section<{
  $reverseOnMobile?: boolean
}>`
  flex: 0 0 auto;
  display: flex;
  flex-flow: ${({ $reverseOnMobile }) =>
    $reverseOnMobile === true ? 'column-reverse' : 'column'};
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.scoping / 10}rem;
  margin-inline: auto;
  border-radius: 2rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  @media ${mq(sizes.tablet)} {
    display: grid;
    grid-template-columns: 2rem 34rem 1fr 34rem 2rem;
    grid-template-rows: 2rem auto 2rem;
  }
`
export const Content = styled.div<{
  $reverseOnDesktop?: boolean
}>`
  position: relative;
  z-index: calc(${({ theme }) => theme.zIndexes.default} + 1);
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
  padding: 3rem 2rem;
  color: ${({ theme }) => theme.colors.arapawa};
  background-color: ${({ theme }) => theme.colors.pureWhite};
  @media ${mq(sizes.tablet)} {
    grid-row: 2 / 3;
    grid-column: ${({ $reverseOnDesktop }) =>
        $reverseOnDesktop === true ? 2 : 4} / span 1;
    justify-content: center;
    gap: 2rem;
    border-radius: 2rem;
  }
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3};
  margin: 0;
  color: ${({ theme }) => theme.scheme.newsPushImageTitle ?? 'inherit'};
  @media ${mq(sizes.tablet)} {
  }
`
export const StyledHtml = styled(Html)`
  ${({ theme }) => theme.textStyles.textSmall};
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  & > p:not(:last-child) {
    padding: 0;
  }
`
export const StyledActionButton = styled(ActionButton)`
  align-self: flex-start;
`
export const Pictures = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndexes.default};
  border-radius: 2rem;
  overflow: hidden;
  @media ${mq(sizes.tablet)} {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
  }
`
const pictureStyles = css`
  min-height: 100%;
  max-width: 100%;
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const StyledMobilePicture = styled(Picture)`
  ${pictureStyles}
  display: block;
  aspect-ratio: 1 / 1;
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`
export const StyledPicture = styled(Picture)<{
  $withMobileALternative?: boolean
}>`
  ${pictureStyles}
  display: ${({ $withMobileALternative }) =>
    $withMobileALternative ? 'none' : 'block'};
  aspect-ratio: 1 / 1;
  @media ${mq(sizes.tablet)} {
    display: block;
    aspect-ratio: 20 / 7;
  }
`
