import React, { FC } from 'react'
import { HtmlProps } from 'src/components/atoms/Html'

import * as SC from './styled'

export type SoloContactFormProps = Pick<MinimalSeoBlock, 'htmlTitleTag'> & {
  className?: string
  title?: string
  texts?: {
    introduction?: string
    mandatory?: string
  }
  children?: React.ReactNode
  htmlProps?: HtmlProps
}

const SoloContactForm: FC<SoloContactFormProps> = ({
  className,
  title,
  texts,
  children,
  htmlProps,
  htmlTitleTag,
}) => {
  return (
    <SC.Container className={className}>
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      <SC.Wrapper>
        {(texts?.introduction || texts?.mandatory) && (
          <SC.Texts>
            {texts?.introduction && (
              <SC.Introduction>{texts.introduction}</SC.Introduction>
            )}
            {texts?.mandatory && <SC.Mandatory>{texts.mandatory}</SC.Mandatory>}
          </SC.Texts>
        )}
        {children}
        {htmlProps && <SC.StyledHtml {...htmlProps} />}
      </SC.Wrapper>
    </SC.Container>
  )
}

export default SoloContactForm
