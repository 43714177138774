import styled from 'styled-components'

import ActionButton from '../../../../atoms/ActionButton'
import { fonts, mq, sizes } from '../../../../../theme'
import Html from '../../../../atoms/Html'
import SoloCapriceValentineGameSocials from '../SoloCapriceValentineGameSocials'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 0 1.5rem 8rem;
  @media ${mq(sizes.desktop)} {
    padding: 0 8rem 16.8rem;
  }
`

export const Title = styled(Html)`
  ${fonts.PoppinsBold}
  color: ${({ theme }) => theme.colors.pureWhite};
  text-align: center;
  font-size: 2.4rem;
  @media ${mq(sizes.desktop)} {
    font-size: 3rem;
  }
`

export const Text = styled(Html)`
  ${fonts.PoppinsMedium}
  color: ${({ theme }) => theme.colors.pureWhite};
  text-align: center;
  font-size: 2rem;
  padding: 0;
`
export const Error = styled.div`
  ${({ theme }) => theme.textStyles.textMedium}
  color: ${({ theme }) => theme.colors.arapawa};

  background: ${({ theme }) => theme.colors.pureWhite};
  border: 1px solid ${({ theme }) => theme.colors.standardRed};

  text-align: center;
  padding: 1rem 1.2rem;
  max-width: 80rem;
  border-radius: 6px;

  margin: 1rem 0;
  align-self: center;
  width: 100%;

  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.textLarge}
  }
`
export const Actions = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media ${mq(sizes.desktop)} {
    flex-direction: row;
  }
`
export const Socials = styled(SoloCapriceValentineGameSocials)`
  margin-top: 1rem;
`

export const Button = styled(ActionButton)``
