import React, { FC } from 'react'
import cx from 'classnames'

import { ActionButtonProps } from '../../../../atoms/ActionButton'
import BaseModal, { BaseModalProps } from '../../../LightAuthModal/BaseModal'
import { Icons } from '../../../../atoms/Icon'
import { FormSubmitButtonProps } from '../../../../form/FormSubmitButton'

import * as SC from './styled'

export type SoloCapriceValentineGameSuggestionsModalProps = BaseModalProps & {
  className?: string
  title: string
  options: string[]
  value?: string
  onChange?: (v: string) => void
  onSubmit?: (v: string) => void
  cancelButton?: ActionButtonProps
  submitButton?: FormSubmitButtonProps
}

const SoloCapriceValentineGameSuggestionsModal: FC<
  SoloCapriceValentineGameSuggestionsModalProps
> = (props) => {
  const {
    className,
    title,
    options,
    value,
    onChange,
    onSubmit,
    cancelButton,
    submitButton,
    ...modalProps
  } = props

  return (
    <BaseModal
      className={cx('SoloCapriceValentineGameSuggestionsModal', className)}
      hideCloseButton
      {...modalProps}
    >
      <SC.Content>
        <SC.Title>{title}</SC.Title>
        <SC.List>
          {options?.map((option, i) => (
            <SC.Option
              $active={value === option}
              key={i}
              onClick={() => onChange?.(option)}
            >
              <SC.OptionLabel>{option}</SC.OptionLabel>
              <SC.OptionIcon icon={Icons.check} />
            </SC.Option>
          ))}
        </SC.List>
        <SC.Actions>
          <SC.Button {...cancelButton} />
          <SC.SubmitButton
            onClick={() => onSubmit?.(value ?? '')}
            {...submitButton}
          />
        </SC.Actions>
      </SC.Content>
    </BaseModal>
  )
}

export default SoloCapriceValentineGameSuggestionsModal
