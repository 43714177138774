// title / text / button (link)

import { FC } from 'react'
import cx from 'classnames'

import { ActionButtonProps } from '../../atoms/ActionButton'
import { HtmlProps } from '../../atoms/Html'
import { Icons } from '../../atoms/Icon'

import * as SC from './styled'

export type SoloActionBlockProps = MinimalSeoBlock & {
  id?: string
  className?: string
  title?: string
  htmlProps?: HtmlProps
  actionButtonProps?: ActionButtonProps
}

const SoloActionBlock: FC<SoloActionBlockProps> = ({
  id,
  className,
  htmlTag,
  title,
  htmlTitleTag,
  htmlProps,
  actionButtonProps,
}) => {
  return title ? (
    <SC.Container
      id={id}
      className={cx('SoloActionBlock', className)}
      as={htmlTag}
    >
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      {htmlProps && <SC.StyledHtml {...htmlProps} />}
      {actionButtonProps && (
        <SC.StyledActionButton
          iconProps={{ icon: Icons.arrowRight }}
          iconPosition="right"
          {...actionButtonProps}
        />
      )}
    </SC.Container>
  ) : null
}

export default SoloActionBlock
