import { AdProps } from '../../components/molecules/Ad'
import { Brand, Page } from '../../graphql/generated/api-graphql'

import { wediaImagesTransform } from './wedia'
import { Configuration } from './PageProps'

export function adPropsTransformer(
  data?: Page | Brand,
  configuration?: Configuration,
  isBoxed?: boolean
): AdProps | undefined {
  return data?.pageWrapEnabled && configuration?.globalPageWrapEnabled
    ? {
        title: data.pageWrapTitle ?? '',
        isBoxed,
        ...(data?.pageWrapUrl && {
          link: {
            href: data.pageWrapUrl,
          },
        }),
        background: data.pageWrapBgColor ?? undefined,
        pictureTop: {
          withHD: true,
          maxWidth: 1480,
          width: 1480,
          height: 240,
          alt: data?.pageWrapImageTop?.alt ?? '',
          images: wediaImagesTransform(data?.pageWrapImageTop),
          withLazyLoading: false,
        },
        pictureTopTablet: data?.pageWrapImageTopTablet
          ? {
              withHD: true,
              maxWidth: 1000,
              width: 1000,
              height: 240,
              alt: data?.pageWrapImageTopTablet?.alt ?? '',
              images: wediaImagesTransform(data?.pageWrapImageTopTablet),
              withLazyLoading: false,
            }
          : undefined,
        pictureTopMobile: data?.pageWrapImageTopMobile
          ? {
              withHD: true,
              maxWidth: 750,
              width: 750,
              height: 320,
              alt: data?.pageWrapImageTopMobile?.alt ?? '',
              images: wediaImagesTransform(data?.pageWrapImageTopMobile),
              withLazyLoading: false,
            }
          : undefined,
        pictureLeft: {
          withHD: true,
          maxWidth: 240,
          width: 240,
          height: 640,
          alt: data?.pageWrapImageLeft?.alt ?? '',
          images: wediaImagesTransform(data?.pageWrapImageLeft, true),
          withLazyLoading: false,
        },
        pictureRight: {
          withHD: true,
          maxWidth: 240,
          width: 240,
          height: 640,
          alt: data?.pageWrapImageRight?.alt ?? '',
          images: wediaImagesTransform(data?.pageWrapImageRight, true),
          withLazyLoading: false,
        },
      }
    : undefined
}
