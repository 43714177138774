import styled from 'styled-components'

import { fonts, mq, sizes } from '../../../../../theme'
import Html from '../../../../atoms/Html'

export const Container = styled.div``
export const Wrapper = styled.div`
  width: 450px;
  height: 800px;
  background: radial-gradient(
      100% 100% at 50% 100%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 83.33%
    ),
    #018bc8;
  padding: 12px 23px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media ${mq(sizes.desktop)} {
    width: 1200px;
    height: 675px;
  }
`

export const Head = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  & div {
    width: 25%;
    &:last-of-type {
      text-align: right;
    }
  }
`
export const Logo = styled.img`
  width: 160px;
  @media ${mq(sizes.desktop)} {
    width: 200px;
  }
`
export const Heart = styled.img`
  opacity: 0.5;
  width: 70px;
  @media ${mq(sizes.desktop)} {
    width: 130px;
  }
`

export const Title = styled(Html)`
  display: none;
  @media ${mq(sizes.desktop)} {
    ${fonts.PoppinsBold}
    color: ${({ theme }) => theme.colors.pureWhite};
    text-align: center;
    font-size: 2.2rem;
    display: block;
    padding-bottom: 1rem;
  }
`
export const MobileTitle = styled(Html)`
  ${fonts.PoppinsBold}
  color: ${({ theme }) => theme.colors.pureWhite};
  text-align: center;
  font-size: 1.8rem;
  padding-bottom: 1rem;
  @media ${mq(sizes.desktop)} {
    display: none;
  }
`

export const Message = styled.div`
  background: ${({ theme }) => theme.colors.pureWhite};
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  flex-grow: 1;
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`
export const Text = styled.div`
  ${({ theme }) => theme.textStyles.titleH5};
  font-size: 1.5rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.capriceDark};
  text-align: center;
  @media ${mq(sizes.desktop)} {
    line-height: 170%;
    font-size: 1.6rem;
  }
`
export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`
export const Tag = styled.div`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.capriceDark};
`

export const Leaf = styled.img`
  align-self: center;
  width: 60px;
  @media ${mq(sizes.desktop)} {
    width: 96px;
  }
`
