import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SoloCapriceValentineGameBlock as Data } from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import SoloCapriceValentineGame, {
  SoloCapriceValentineGameProps,
} from '../../../components/molecules/SoloCapriceValentineGame'
import { SoloCapriceValentineStep } from '../../../components/molecules/SoloCapriceValentineGame/types'
import { actions, selectors } from '../../../redux'
import tracking, {
  TrackingBrandName,
  TrackingCategoryType,
  TrackingContentGroup,
  TrackingUserType,
} from '../../../tracking'
import { CapriceMaxSessionCount } from '../../../redux/solo/types'
import { google } from '../../../configuration'

import { useCapriceValentineGameIntro } from './useCapriceValentineGameIntro'
import { useCapriceValentineGameInput } from './useCapriceValentineGameInput'
import { useCapriceValentineGameGpt } from './useCapriceValentineGameGpt'
import { useCapriceValentineGameGptError } from './useCapriceValentineGameGptError'
import { useCapriceValentineGameResult } from './useCapriceValentineGameResult'
import { useCapriceValentineGameError } from './useCapriceValentineGameError'
import { useCapriceValentineGameInputLoad } from './useCapriceValentineGameInputLoad'

export type SoloCapriceValentineGameBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

const SoloCapriceValentineGameBlock: FC<SoloCapriceValentineGameBlockProps> = ({
  data,
}) => {
  const isInit = useSelector(selectors.app.isInit)
  const step = useSelector(selectors.solo.capriceValentineStep)
  const containerRef = useRef<HTMLDivElement>(null)
  const user = useSelector(selectors.auth.user)
  const count = useSelector(selectors.solo.capriceValentineCount)
  const dispatch = useDispatch()
  const [recaptchaReady, setRecaptchaReady] = useState(false)

  const introProps = useCapriceValentineGameIntro()
  const inputProps = useCapriceValentineGameInput(
    data.inputPropositions ?? [],
    recaptchaReady
  )
  const gptProps = useCapriceValentineGameGpt()
  const inputLoadProps = useCapriceValentineGameInputLoad()
  const gptErrorProps = useCapriceValentineGameGptError()
  const resultProps = useCapriceValentineGameResult()
  const errorProps = useCapriceValentineGameError(
    data.dateStarting,
    data.dateClosing
  )

  useEffect(() => {
    if (
      step === SoloCapriceValentineStep.INTRO &&
      count >= CapriceMaxSessionCount
    ) {
      dispatch(
        actions.solo.setCapriceValentineStep(SoloCapriceValentineStep.ERROR)
      )
    }
  }, [step, count, dispatch])

  useEffect(() => {
    let page = ''
    if (step === SoloCapriceValentineStep.INTRO) {
      if (user?.id) {
        page = 'Jeu Caprice des dieux 2024 - Etape 2 CGU'
      } else {
        page = 'Jeu Caprice des dieux 2024 - Etape 1 Presentation'
      }
    } else if (step === SoloCapriceValentineStep.INPUT1) {
      page = 'Jeu Caprice des dieux 2024 - Etape 3.1 Ma Saint Valentin ideal'
    } else if (step === SoloCapriceValentineStep.INPUT2) {
      page = 'Jeu Caprice des dieux 2024 - Etape 4.1 Ma partenaire'
    } else if (step === SoloCapriceValentineStep.RESULT) {
      page = 'Jeu Caprice des dieux 2024 - Etape 5 Resultat'
    }

    if (page) {
      tracking.page({
        group: TrackingContentGroup.GAME,
        page,
        section: TrackingCategoryType.SOLO,
        brand: TrackingBrandName.CAPRICE,
        userType:
          user && user?.id ? TrackingUserType.MEMBER : TrackingUserType.VISITOR,
        userId: user?.id ?? undefined,
        hideRubrique: true,
      })
    }
  }, [step, user])

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      const rect = container.getBoundingClientRect()
      const top = rect.top + window.scrollY
      if (!isInit || step === SoloCapriceValentineStep.INTRO) {
        window.scrollTo({
          top: top + rect.height / 2 - window.innerHeight / 2,
          ...(isInit && { behavior: 'smooth' }),
        })
      } else {
        window.scrollTo({
          top: top,
          behavior: 'smooth',
        })
      }
    }
  }, [step, isInit])

  const props: SoloCapriceValentineGameProps | undefined = isInit
    ? {
        ...(step === SoloCapriceValentineStep.INTRO && {
          introProps,
        }),
        ...((step === SoloCapriceValentineStep.INPUT1 ||
          step === SoloCapriceValentineStep.INPUT2) && {
          inputProps,
        }),
        ...(step === SoloCapriceValentineStep.INPUT_LOAD && {
          loadProps: inputLoadProps,
        }),
        ...(step === SoloCapriceValentineStep.GPT && {
          loadProps: gptProps,
        }),
        ...(step === SoloCapriceValentineStep.GPT_ERROR && {
          errorProps: gptErrorProps,
        }),
        ...(step === SoloCapriceValentineStep.RESULT && {
          resultProps,
        }),
        ...(step === SoloCapriceValentineStep.ERROR && {
          errorProps,
        }),
        recaptcha: {
          src: google.RECAPTCHA_SCRIPT_URL,
          onReady: () => {
            console.log('recaptcha ready')
            setRecaptchaReady(true)
          },
        },
      }
    : {
        introProps,
      }
  return <SoloCapriceValentineGame {...props} containerRef={containerRef} />
}

export default SoloCapriceValentineGameBlock
