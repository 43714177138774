import React, {
  FC,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useId,
  useState,
} from 'react'

import * as SC from './styled'

export type FormFieldTextAreaProps =
  InputHTMLAttributes<HTMLTextAreaElement> & {
    name?: string
    className?: string
    error?: string
    hasError?: boolean
    helpText?: string
    errors?: { message?: string }[]
    label?: string
    required?: boolean
    leftAdornment?: React.ReactElement
    rightAdornment?: React.ReactElement
    InputComponent?: FC<any>
    inputProps?: any
    hasFloatingLabel?: boolean
    hideErrors?: boolean
  }

const FormFieldTextAreaRenderer: ForwardRefRenderFunction<
  any,
  FormFieldTextAreaProps
> = (props, ref) => {
  const {
    className,
    name,
    id,
    error,
    hasError,
    placeholder,
    onChange,
    label,
    required,
    helpText,
    leftAdornment,
    rightAdornment,
    InputComponent,
    inputProps,
    value,
    hasFloatingLabel,
    hideErrors,
    ...extraProps
  } = props
  const localId = useId()
  const [isFocused, setFocused] = useState(false)
  const isActive = isFocused || !!value
  const hasLabel = !!label

  const CustomInput: FC<any> = InputComponent ? InputComponent : SC.Input

  return (
    <SC.Container className={className}>
      <SC.Wrapper>
        {!hasFloatingLabel && label && (
          <SC.Label
            required={required}
            $active={isActive}
            $hasFloatingLabel={hasFloatingLabel}
          >
            {label}
          </SC.Label>
        )}
        <SC.InputContainer
          $isError={!!error || hasError}
          $isActive={isActive}
          $isDisabled={extraProps.disabled}
          $hasLabel={hasLabel}
        >
          {hasFloatingLabel && label && (
            <SC.Label
              required={required}
              $active={isActive}
              $hasFloatingLabel={hasFloatingLabel}
            >
              {label}
            </SC.Label>
          )}
          <SC.InputWrapper>
            {leftAdornment}
            <CustomInput
              ref={ref}
              {...inputProps}
              {...extraProps}
              id={id ?? localId}
              name={name}
              type={extraProps.type ?? 'text'}
              placeholder={
                !hasLabel || !hasFloatingLabel || isFocused
                  ? placeholder
                  : undefined
              }
              value={value}
              $isError={!!error || hasError}
              $isActive={isActive}
              $hasLabel={hasLabel}
              $hasFloatingLabel={hasFloatingLabel}
              $isDisabled={extraProps.disabled}
              onChange={onChange}
              onFocus={(e: any) => {
                setFocused(true)
                extraProps?.onFocus?.(e)
              }}
              onBlur={(e: any) => {
                extraProps?.onBlur?.(e)
                setFocused(false)
              }}
            />
            {rightAdornment}
          </SC.InputWrapper>
        </SC.InputContainer>
      </SC.Wrapper>
      {(!error || hideErrors) && helpText && (
        <SC.HelpMessage>{helpText}</SC.HelpMessage>
      )}
      {!hideErrors && error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
    </SC.Container>
  )
}

const FormFieldTextArea: FC<
  FormFieldTextAreaProps & { ref?: React.LegacyRef<any> }
> = forwardRef(FormFieldTextAreaRenderer)

export default FormFieldTextArea
