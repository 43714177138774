import React, { FC } from 'react'
import cx from 'classnames'

import { ActionButtonProps } from '../../../../atoms/ActionButton'
import { SoloCapriceValentineGameSocialsProps } from '../SoloCapriceValentineGameSocials'

import * as SC from './styled'

export type SoloCapriceValentineGameErrorProps = Pick<
  MinimalSeoBlock,
  'htmlTag'
> & {
  id?: string
  className?: string
  title?: string
  text?: string
  error?: string
  actions?: ActionButtonProps[]
  socialProps?: SoloCapriceValentineGameSocialsProps
}

const SoloCapriceValentineGameError: FC<SoloCapriceValentineGameErrorProps> = ({
  id,
  className,
  title,
  text,
  error,
  actions,
  socialProps,
}) => {
  return (
    <SC.Container
      id={id}
      className={cx('SoloCapriceValentineGameError', className)}
    >
      {title && <SC.Title>{title}</SC.Title>}
      {text && <SC.Text>{text}</SC.Text>}
      {error && <SC.Error>{error}</SC.Error>}
      {(actions?.length ?? 0) > 0 && (
        <SC.Actions>
          {actions?.map((action, i) => (
            <SC.Button key={i} {...action} />
          ))}
        </SC.Actions>
      )}
      {socialProps && <SC.Socials {...socialProps} />}
    </SC.Container>
  )
}

export default SoloCapriceValentineGameError
