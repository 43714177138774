import styled from 'styled-components'

import { mq, sizes } from '../../../theme'

export const Container = styled.div`
  background: radial-gradient(
      100% 100% at 50% 100%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 83.33%
    ),
    #018bc8;
  padding: 2rem 0 0;
  @media ${mq(sizes.desktop)} {
    padding: 4rem 0 0;
  }
`

export const SpecialIntroAnchor = styled.a`
  display: inline-block;
  transform: translateY(-5rem);
`
