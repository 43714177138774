import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import ActionButton from '../../atoms/ActionButton'
import RelatedProduct from '../RelatedProduct'

export const Container = styled.section`
  display: flex;
  flex-flow: column nowrap;
  border-radius: 1.5rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  @media ${mq(sizes.tablet)} {
    border-radius: 2rem;
  }
`
export const Products = styled.div<{ $withPicture?: boolean }>`
  --swiper-pagination-color: ${({ theme }) => theme.colors.arapawa};
  --swiper-pagination-bullet-height: 1rem;
  --swiper-pagination-bullet-width: 1rem;
  padding-block-start: 2rem;
  padding-block-end: ${({ $withPicture }) => ($withPicture ? 0 : '2rem')};
  & .swiper {
    padding-inline: 2rem;
  }
  & .swiper-wrapper {
    align-items: stretch;
    margin-inline: -2rem;
    padding-inline: 2rem;
    padding-block-end: 0.2rem;
  }
  & .swiper-slide {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    height: auto;
  }
  & .swiper-pagination {
    position: relative;
    bottom: unset;
    margin-block-start: 2rem;
  }
  @media ${mq(sizes.desktop)} {
    padding-block-start: 3rem;
    padding-block-end: ${({ $withPicture }) => ($withPicture ? 0 : '3rem')};
    & .swiper {
      padding-inline: 3rem;
    }
    & .swiper-wrapper {
      margin-inline: -3rem;
      padding-inline: 3rem;
    }
  }
`
export const Product = styled(RelatedProduct)`
  width: 100%;
  border: 0.1rem solid ${({ theme }) => theme.colors.pattensBlue};
`
export const Action = styled.div<{ $withPicture?: boolean }>`
  position: relative;
  z-index: 2;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-block-start: 2rem;
  @media ${mq(sizes.tablet)} {
    margin-block-end: ${({ $withPicture }) =>
      $withPicture ? '-3.2rem' : '1rem'};
    border-radius: 2rem;
    background: ${({ theme }) => theme.colors.pureWhite};
  }
`
export const StyledActionButton = styled(ActionButton)`
  text-align: center;
`
export const StyledPicture = styled(Picture)`
  aspect-ratio: 16 / 10;
  overflow: hidden;
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media ${mq(sizes.tablet)} {
    aspect-ratio: 5 / 2;
  }
`
