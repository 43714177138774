import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  SoloTitleImageBodyBlock as Data,
  LinkTarget,
} from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import SoloContentBlock from '../../../components/molecules/SoloContentBlock'
import {
  getMediaPosition,
  getMediaSize,
} from '../../Common/getSoloMediaDetails'
import { wediaImagesTransform } from '../../Common/wedia'

export type SoloTitleImageBodyBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

// Replace <h5> with <p id="as-h5">
const transformHtmlText = (text: string) => {
  return text.replace(/<h5>/g, '<p id="as-h5">').replace(/<\/h5>/g, '</p>')
}

const SoloTitleImageBodyBlock: FC<SoloTitleImageBodyBlockProps> = ({
  data,
}) => {
  const { t } = useTranslation()

  return (
    <SoloContentBlock
      title={data.title ?? undefined}
      {...(data.image
        ? {
            mediaSize: getMediaSize(data.bodyImageRatio),
            mediaPosition: getMediaPosition(data.bodyPosition),
            pictureProps: {
              alt: data.image.alt ?? ' ',
              maxWidth: 1280,
              width: data.image?.variations?.[0].width ?? undefined,
              height: data.image?.variations?.[0].height ?? undefined,
              withHD: true,
              images: wediaImagesTransform(data.image, true),
            },
          }
        : {})}
      htmlProps={
        data.body
          ? {
              text: transformHtmlText(data.body),
            }
          : undefined
      }
      actionButtonProps={
        data.ctaBlock && data.ctaBlock.href
          ? {
              href: data.ctaBlock.href,
              label: data.ctaBlock.label ?? t('learn_more'),
              target:
                data.ctaBlock?.target === LinkTarget.Blank ? '_blank' : '_self',
            }
          : undefined
      }
    />
  )
}

export default SoloTitleImageBodyBlock
