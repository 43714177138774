// @TODO the whole filter thing needs to be reworked entirely

import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import tracking from 'src/tracking'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { FormikContextType } from 'formik'

import {
  SoloRecipesBlock as Data,
  Recipe,
  SearchEngineType,
} from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import { transformRecipeBigCard } from '../../RecipeCard/transformRecipeBigCard'
import { transformRecipeCard } from '../../RecipeCard/transformRecipeCard'
import Router, { routes } from '../../../routes/Router'
import { SearchType } from '../../SearchEngine/searchType'
import { Icons } from '../../../components/atoms/Icon'
import SoloRecipes, {
  SoloRecipesProps,
} from '../../../components/molecules/SoloRecipes'
import { AllFiltersFormProps } from '../../../components/forms/AllCheesesFiltersForm'
import FormFieldSelect from '../../../components/form/fields/FormFieldSelect'
import { getFilterOptions, parseFilters } from '../../Common/filters'
import { useServiceRequest } from '../../../hooks/useServiceRequest'
import { actions, selectors } from '../../../redux'
import { usePrevious } from '../../../hooks/usePrevious'

export type SoloRecipesBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

const SoloRecipesBlock: FC<SoloRecipesBlockProps> = ({ data, brandId }) => {
  const { t } = useTranslation()
  const { query } = useRouter()

  const [soloRecipes, getSoloRecipes] = useServiceRequest(
    selectors.search.soloSearchResults,
    actions.search.soloSearchResultsRequest,
    actions.search.soloSearchResultsReset
  )

  const handlegetSoloRecipes = useCallback(
    ({
      tags,
      cheeses,
    }: {
      tags?: (string | number)[]
      cheeses?: (string | number)[]
    }) => {
      getSoloRecipes({
        q: '',
        type: SearchEngineType.Recipe,
        brands: [brandId],
        tags,
        cheeses,
        first: 12,
      })
    },
    [brandId, getSoloRecipes]
  )

  // filters
  const parsedFilters = useMemo(
    () =>
      parseFilters(
        t,
        'listRecipes',
        query?.tag as string,
        Object.keys(data?.filters)
      ),
    [t, data?.filters, query?.tag]
  )

  const [currentFilters, setCurrentFilters] = useState(parsedFilters)
  const previousFilters = usePrevious(currentFilters)

  const handleFiltersSubmit = useCallback(
    (context: FormikContextType<AllFiltersFormProps>) => {
      setCurrentFilters(context.values)
    },
    []
  )

  useEffect(() => {
    if (JSON.stringify(currentFilters) !== JSON.stringify(previousFilters)) {
      // @TODO better job than this :
      // @ts-ignore
      const tagsPrepTime = currentFilters?.tags_preparation_time
      // @ts-ignore
      const tagsDish = currentFilters?.tags_dish_example
      // @ts-ignore
      const tagsDifficulty = currentFilters?.tags_difficulty
      // @ts-ignore
      const cheeses = currentFilters?.cheeses

      handlegetSoloRecipes({
        tags: [
          ...(tagsPrepTime ? [tagsPrepTime] : []),
          ...(tagsDish ? [tagsDish] : []),
          ...(tagsDifficulty ? [tagsDifficulty] : []),
        ],
        cheeses: [cheeses],
      })
    }
  }, [currentFilters, handlegetSoloRecipes, previousFilters])

  const filters: AllFiltersFormProps = useMemo(
    () => ({
      formikForm: {
        initialValues: parsedFilters,
        onSubmit: () => undefined,
        onContextUpdate: handleFiltersSubmit,
        validateOnChange: false,
        enableReinitialize: true,
      },
      autoSubmit: true,
      fields: Object.keys(data?.filters)
        ?.map((name) => ({
          select: {
            Component: FormFieldSelect,
            label: t(`listRecipes_filter_${name}_label`),
            name,
            placeholder: t(`listRecipes_filter_${name}_all`),
            staticLabel: true,
            options: getFilterOptions(t, 'listRecipes', name, data?.filters),
            required: false,
          },
        }))
        ?.filter((field) => field?.select?.options?.length > 1),
    }),
    [parsedFilters, data?.filters, t, handleFiltersSubmit]
  )

  const cards: Recipe[] | undefined = useMemo(() => {
    return JSON.stringify(currentFilters) !== JSON.stringify(parsedFilters)
      ? soloRecipes?.data?.data
      : [
          ...(data.firstRecipe ? [data.firstRecipe] : []),
          ...(data?.recipes && data?.recipes.length > 0
            ? data?.recipes.slice(0, 11)
            : []),
        ]
  }, [
    currentFilters,
    data.firstRecipe,
    data?.recipes,
    parsedFilters,
    soloRecipes?.data,
  ])

  // props assembled like the Avengers
  const props: SoloRecipesProps = {
    allFiltersFormProps: filters,
    cardsBlockProps: {
      htmlTitleTag: 'h2',
      highlighted: transformRecipeBigCard(
        t,
        data.topRecipe,
        data?.__typename ?? undefined
      ),
      texts: {
        noResult: t('solo_recipes_no_result'),
      },
      cards:
        cards && cards.length > 0
          ? cards?.map((recipe: Recipe) =>
              transformRecipeCard(
                t,
                recipe,
                data?.__typename ?? undefined,
                'h2'
              )
            )
          : [],
      actionButtonProps: {
        onClick: () => {
          tracking.cta(t('see_more_recipes'), data?.__typename ?? undefined)
          // Use onclick instead of href because of SEO recommendation
          Router.push(
            Router.getRouteUrl(routes.searchType, {
              type: SearchType.Recipe,
              marques: brandId,
            })
          )
        },
        label: t('see_more_recipes'),
        iconPosition: 'right',
        iconProps: {
          icon: Icons.arrowRight,
        },
      },
    },
  }

  return <SoloRecipes {...props} />
}

export default SoloRecipesBlock
