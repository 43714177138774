import React, { FC } from 'react'
import cx from 'classnames'

import { LinkProps } from '../../../../atoms/Link'
import { IconProps } from '../../../../atoms/Icon'

import * as SC from './styled'

export type SoloCapriceValentineGameSocialsProps = Pick<
  MinimalSeoBlock,
  'htmlTag'
> & {
  id?: string
  className?: string
  text?: string
  actions?: {
    linkProps: LinkProps
    iconProps?: IconProps
  }[]
}

const SoloCapriceValentineGameSocials: FC<
  SoloCapriceValentineGameSocialsProps
> = ({ id, className, text, actions }) => {
  return (
    <SC.Container
      id={id}
      className={cx('SoloCapriceValentineGameSocials', className)}
    >
      <SC.Text>{text}</SC.Text>
      {(actions?.length ?? 0) > 0 && (
        <SC.List>
          {actions?.map((action, i) => (
            <SC.Item key={i}>
              <SC.StyledLink {...action.linkProps}>
                {action.linkProps.label && (
                  <SC.Label>{action.linkProps.label}</SC.Label>
                )}
                {action.iconProps && <SC.StyledIcon {...action.iconProps} />}
              </SC.StyledLink>
            </SC.Item>
          ))}
        </SC.List>
      )}
    </SC.Container>
  )
}

export default SoloCapriceValentineGameSocials
