import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'src/redux'
import tracking from 'src/tracking'
import { enumBrandSocialPicto } from 'src/helpers/EnumHelpers'

import { Brand, FavoriteType } from '../../graphql/generated/api-graphql'
import { wediaImagesTransform } from '../Common/wedia'
import { BrandHeroProps } from '../../components/molecules/BrandHero'
import { Icons } from '../../components/atoms/Icon'
import { ActionButtonVariant } from '../../components/atoms/ActionButton/styled'
import { selectors } from '../../redux'

const useBrandHero = (data?: Brand): BrandHeroProps => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const getFavoriteBrands = useSelector(selectors.auth.favoriteBrands)
  const asFavoriteBrand = getFavoriteBrands.find((item) => item.id === data?.id)

  const isConnected = useSelector(selectors.auth.isConnected)

  const AddWishlist = () => {
    dispatch(
      actions.member.favoriteToggleRequest({
        type: FavoriteType.Brand,
        id: data?.id ?? '',
      })
    )
    tracking.wishlist(FavoriteType.Brand, data?.name, data?.name)
  }

  return {
    title: data?.name,
    imageProps: {
      maxWidth: 1280,
      width: 1280,
      height: 512,
      withHD: true,
      withLazyLoading: false,
      alt: data?.name ?? data?.pictureHeader?.alt ?? '',
      images: wediaImagesTransform(data?.pictureHeader),
      placeholder: !data?.pictureHeader,
    },
    mobileImageProps: {
      maxWidth: 370,
      width: 370,
      height: 200,
      withHD: true,
      withLazyLoading: false,
      images: data?.pictureHeader
        ? wediaImagesTransform(data.pictureHeader)
        : undefined,
      alt: data?.name ?? data?.pictureHeader?.alt ?? '',
    },
    brandLogoProps: data?.pictureLogo
      ? {
          maxWidth: 200,
          width: 200,
          height: 200,
          withHD: true,
          withLazyLoading: false,
          alt: data?.name ?? data?.pictureLogo.alt ?? '',
          images: wediaImagesTransform(data.pictureLogo),
        }
      : undefined,
    subtitle: data?.introductionTitle,
    htmlProps: {
      text: data?.introductionText ?? '',
    },
    actionButtonsProps: [
      {
        label: t('add_to_my_bookmarks'),
        iconPosition: 'left',
        iconProps: {
          icon: asFavoriteBrand ? Icons.heartFull : Icons.heartLine,
        },
        variant: ActionButtonVariant.secondary,
        onClick: () =>
          isConnected
            ? AddWishlist()
            : dispatch(actions.auth.setAuthPopinOpen(true)),
      },
      data?.socialUrl
        ? {
            label: t('learn_more'),
            iconPosition: 'left',
            iconProps: enumBrandSocialPicto(data?.socialPicto ?? '') && {
              icon:
                enumBrandSocialPicto(data?.socialPicto ?? '') ??
                Icons.socialFacebook,
            },
            variant: ActionButtonVariant.secondary,
            href: data?.socialUrl ? data?.socialUrl : undefined,
            onClick: () =>
              tracking.brand.details(
                FavoriteType.Brand,
                data?.introductionTitle ?? ''
              ),
          }
        : {},
    ],
  }
}

export default useBrandHero
