import React, { FC } from 'react'
import cx from 'classnames'
import Head from 'next/head'
import { jsonLdScriptProps } from 'react-schemaorg'
import { VideoObject } from 'schema-dts'

import { VideoProps } from '../../atoms/Video'
import { ActionButtonProps } from '../../atoms/ActionButton'
import { HtmlProps } from '../../atoms/Html'
import { ActionButtonVariant } from '../../atoms/ActionButton/styled'

import * as SC from './styled'

export type SoloVideoProps = MinimalSeoBlock & {
  className?: string
  videoProps: VideoProps
  title?: string
  videoTitle?: string
  htmlVideoTitleTag?: React.ElementType
  htmlProps?: HtmlProps
  actionButtonProps?: ActionButtonProps
}

const SoloVideo: FC<SoloVideoProps> = (props) => {
  const {
    className,
    htmlTag,
    videoProps,
    title,
    htmlTitleTag,
    videoTitle,
    htmlVideoTitleTag,
    htmlProps,
    actionButtonProps,
  } = props

  const description = htmlProps?.text?.replace(/(<([^>]+)>)/gi, '')

  return (
    <>
      <Head>
        <script
          {...jsonLdScriptProps<VideoObject>({
            '@context': 'https://schema.org',
            '@type': 'VideoObject',
            name: title ?? 'Video',
            description: description,
            uploadDate:
              videoProps?.jsonLdInfo?.uploadDate ?? new Date().toISOString(),
            thumbnailUrl: videoProps.poster
              ? videoProps.poster?.match(/^http(s*):/)
                ? videoProps.poster
                : videoProps.jsonLdBasePath &&
                  videoProps.jsonLdBasePath + videoProps.poster
              : videoProps?.sources?.[0]?.src,
            contentUrl: videoProps?.sources?.[0]?.src,
          })}
        />
      </Head>
      <SC.Container className={cx('SoloVideo', className)} as={htmlTag}>
        {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
        <SC.Wrapper>
          <SC.StyledVideo {...videoProps} disableJsonLd={true} />
          {(title || htmlProps || actionButtonProps) && (
            <SC.Content>
              {videoTitle && (
                <SC.VideoTitle as={htmlVideoTitleTag}>
                  {videoTitle}
                </SC.VideoTitle>
              )}
              {htmlProps && <SC.Text {...htmlProps} />}
              {actionButtonProps && (
                <SC.Action
                  variant={ActionButtonVariant.secondary}
                  {...actionButtonProps}
                />
              )}
            </SC.Content>
          )}
        </SC.Wrapper>
      </SC.Container>
    </>
  )
}

export default SoloVideo
