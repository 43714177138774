import { Brand, Format } from '../../graphql/generated/api-graphql'
import { RelatedProductsBlockProps } from '../../components/molecules/RelatedProductsBlock'
import { Icons } from '../../components/atoms/Icon'
import { toRange } from '../Common/range'

export const brandProductsTransformer = (
  t: any,
  data?: Brand,
  popinHandler?: (data: Format) => void
): RelatedProductsBlockProps | undefined => ({
  title: t('brand_cheeses_title'),
  ranges:
    data?.cheeses?.map((cheese) => toRange(t, cheese, popinHandler)) ?? [],
  defaultNumberOnMobile: 1,
  defaultNumberOnDesktop: 3,
  texts: {
    seeMoreProducts: t('brand_seeMore_products'),
    seeLessProducts: t('brand_seeLess_products'),
  },
  ecopush: {
    iconProps: {
      icon: Icons.triman,
    },
    htmlProps: {
      text: t('ecoPush_text'),
    },
  },
})
