import React, { FC, Fragment } from 'react'
import cx from 'classnames'

import { ActionButtonProps } from '../../../../atoms/ActionButton'
import { FormikFormProps } from '../../../../form/FormikForm'
import { FormikFieldProps } from '../../../../form/FormikField'
import { FormSubmitButtonProps } from '../../../../form/FormSubmitButton'
import LightAuthLoginModal, {
  LightAuthLoginModalProps,
} from '../../../LightAuthModal/LightAuthLoginModal'
import LightAuthRegisterModal, {
  LightAuthRegisterModalProps,
} from '../../../LightAuthModal/LightAuthRegisterModal'
import LightAuthOptinModal, {
  LightAuthOptinModalProps,
} from '../../../LightAuthModal/LightAuthOptinModal'
import { PictureProps } from '../../../../atoms/Picture'

import * as SC from './styled'

export type SoloCapriceValentineGameIntroProps = Pick<
  MinimalSeoBlock,
  'htmlTag'
> & {
  id?: string
  className?: string
  discover?: string[]
  ai?: string[]
  win?: string[]
  images?: PictureProps[]
  actions?: ActionButtonProps[]
  form?: {
    title: string
    formikForm: FormikFormProps
    fields: FormikFieldProps[]
    submit: FormSubmitButtonProps
  }
  loginProps?: LightAuthLoginModalProps
  registerProps?: LightAuthRegisterModalProps
  optinProps?: LightAuthOptinModalProps
}

const SoloCapriceValentineGameIntro: FC<SoloCapriceValentineGameIntroProps> = ({
  id,
  className,
  discover,
  ai,
  win,
  actions,
  form,
  loginProps,
  registerProps,
  optinProps,
  images,
}) => {
  return (
    <SC.Container
      id={id}
      className={cx('SoloCapriceValentineGameIntro', className)}
    >
      <SC.Title>
        {discover?.map((l, j) =>
          l === '%LOGO' ? (
            <Fragment key={j}>
              <SC.Valentin
                src={'/static/assets/images/caprice/caprice-valentin.svg'}
              />
              <SC.MobileSplit />
            </Fragment>
          ) : (
            <Fragment key={j}>
              <SC.Text>{l}</SC.Text>
              <SC.MobileSplit />
            </Fragment>
          )
        )}
        <SC.Split />
        {ai?.map((l, j) => (
          <Fragment key={j}>
            {j > 0 ? (
              <Fragment>
                <SC.Capital>{l.substring(0, 1)}</SC.Capital>
                <SC.Text2>{l.substring(1)}</SC.Text2>
                <SC.MobileSplit />
              </Fragment>
            ) : (
              <SC.Text>{l}</SC.Text>
            )}
          </Fragment>
        ))}
        <SC.Split />
        {win?.map((l, j) => (
          <Fragment key={j}>
            <SC.Text>{l}</SC.Text>
            <SC.MobileSplit />
          </Fragment>
        ))}
      </SC.Title>
      {form && (
        <SC.Form>
          <SC.FormTitle>{form.title}</SC.FormTitle>
          <SC.Formik {...form.formikForm}>
            {(formikProps) => (
              <>
                <SC.Fields>
                  {form.fields?.map((field, i) => (
                    <SC.Field key={i} {...field} />
                  ))}
                </SC.Fields>
                {(formikProps.isSubmitting || formikProps.submitCount > 0) &&
                  ((formikProps.touched.age && formikProps.errors?.age) ||
                    (formikProps.touched.rules &&
                      formikProps.errors?.rules)) && (
                    <SC.Errors
                      messages={(
                        Object.values(formikProps.errors) as string[]
                      ).map((v) => ({ label: v }))}
                    />
                  )}
                <SC.Submit
                  {...form.submit}
                  disabled={!formikProps.isValid || !formikProps.dirty}
                />
              </>
            )}
          </SC.Formik>
        </SC.Form>
      )}
      {(actions?.length ?? 0) > 0 && (
        <SC.Actions>
          {actions?.map((action, i) => (
            <SC.Button key={i} {...action} />
          ))}
        </SC.Actions>
      )}
      {(images?.length ?? 0) > 0 && (
        <SC.Images>
          {images?.map((image, i) => (
            <SC.Image key={i} {...image} />
          ))}
        </SC.Images>
      )}

      {loginProps && <LightAuthLoginModal {...loginProps} />}
      {optinProps && <LightAuthOptinModal {...optinProps} />}
      {registerProps && <LightAuthRegisterModal {...registerProps} />}
    </SC.Container>
  )
}

export default SoloCapriceValentineGameIntro
