import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'

import { SoloBannerSize } from './types'

export const Container = styled.section<{ $size?: SoloBannerSize }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: center;
  aspect-ratio: ${({ $size }) =>
    $size === SoloBannerSize.Big ? '1 / 1' : '375 / 281'};
  @media ${mq(sizes.tablet)} {
    aspect-ratio: ${({ $size }) =>
      $size === SoloBannerSize.Big ? '5 / 2' : '1440 / 360'};
  }
`
export const Title = styled.h2<{ $size?: SoloBannerSize }>`
  position: relative;
  z-index: 3;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  align-self: ${({ $size }) =>
    $size === SoloBannerSize.Big ? 'start' : 'center'};
  display: flex;
  flex-flow: column;
  gap: 1rem;
  padding: 5rem 2rem;
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.pureWhite};
  & > * {
    color: inherit;
  }
  & > *:first-child {
    ${({ theme }) => theme.textStyles.titleH2}
  }
  & *:not(:first-child) {
    ${({ theme }) => theme.textStyles.titleH4}
  }
  @media ${mq(sizes.tablet)} {
    gap: 2rem;
    padding: 4rem 10rem;
  }
  @media ${mq(sizes.desktop)} {
    & > *:first-child {
      font-size: 5.2rem;
    }
    & *:not(:first-child) {
      ${({ theme }) => theme.textStyles.titleH2}
    }
  }
`
export const Images = styled.div<{ $size?: SoloBannerSize }>`
  position: relative;
  z-index: 1;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  // safari does not play well with aspect-ratio  and min-sizes so commented for now but here to play with title getting too long
  height: 100%;
  width: 100%;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    pointer-events: none;
  }
`
const imageStyles = css`
  height: 100%;
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const Banner = styled(Picture)<{ $withMobileALternative?: boolean }>`
  ${imageStyles}
  display: ${({ $withMobileALternative }) =>
    $withMobileALternative ? 'none' : 'block'};
  @media ${mq(sizes.tablet)} {
    display: block;
  }
`
export const MobileBanner = styled(Picture)`
  ${imageStyles}
  display: block;
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`
export const Bezel = styled.svg`
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  height: 2rem;
  pointer-events: none;
`
export const TopBezel = styled(Bezel)`
  top: 0;
  color: ${({ theme }) =>
    theme.scheme.generalHeaderFill ?? theme.colors.pureWhite};
`
export const BottomBezel = styled(Bezel)`
  bottom: 0;
  color: ${({ theme }) =>
    theme.scheme.generalBodyFill ?? theme.colors.pureWhite};
`
