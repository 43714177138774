import styled, { css } from 'styled-components'

import { mq, sizes } from '../../theme'
import BrandHero from '../../components/molecules/BrandHero'
import MainLayout from '../../layouts/MainLayout'
import Baggy from '../../components/atoms/Baggy'
import CouponCardsBlock from '../../components/molecules/CouponCardsBlock'
import RelatedProductsBlock from '../../components/molecules/RelatedProductsBlock'
import ContactUsBlock from '../../components/molecules/ContactUsBlock'
import CardsBlock from '../../components/molecules/CardsBlock'
import VideoBlock from '../../components/molecules/VideoBlock'
import HighlightBlock from '../../components/molecules/HighlightBlock'
import _PushFormats from '../../components/molecules/PushFormats'

const boxedBlock = css`
  align-self: center;
  width: 100%;
  max-width: min(
    calc(100vw - 3rem),
    ${({ theme }) => theme.sizes.scoping / 10}rem
  );
  margin-left: auto;
  margin-right: auto;
`

export const Layout = styled(MainLayout)``
export const StyledBrandHero = styled(BrandHero)``
export const TopZone = styled(Baggy)`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  & > * {
    padding: 2rem;
  }
  @media ${mq(sizes.tablet)} {
    padding-left: 0;
    padding-right: 0;
    & > * {
      padding: 4rem;
    }
  }
`
export const Highlight = styled(HighlightBlock)`
  &:not(:first-child) {
    margin-top: 2rem;
  }
  @media ${mq(sizes.tablet)} {
    ${boxedBlock}
    &:not(:first-child) {
      margin-top: 4rem;
    }
  }
`
export const Video = styled(VideoBlock)`
  max-width: ${({ theme }) => theme.sizes.video / 10}rem;
  &:not(:first-child) {
    margin-top: 2rem;
  }
  @media ${mq(sizes.tablet)} {
    &:first-child {
      margin-top: 3rem !important;
    }
    &:last-child {
      margin-bottom: 3rem !important;
    }
    &:not(:first-child) {
      margin-top: 9rem;
    }
  }
`
export const Toothpick = styled(HighlightBlock)`
  &:not(:first-child) {
    margin-top: 4rem;
  }
  &:last-child {
    margin-bottom: 4rem;
  }
  @media ${mq(sizes.tablet)} {
    ${boxedBlock}
  }
`
export const Coupons = styled(CouponCardsBlock)`
  border-radius: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  &:not(:first-child) {
    margin-top: 4rem;
  }
  &:last-child {
    margin-bottom: 4rem;
  }
  @media ${mq(sizes.tablet)} {
    ${boxedBlock}
  }
`
export const PushFormats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`
export const PushFormat = styled(_PushFormats)``
export const BottomZone = styled(Baggy)``
export const Related = styled(RelatedProductsBlock)``
export const Recipes = styled(CardsBlock)`
  padding: 4rem;
`
export const ContactUs = styled(ContactUsBlock)``
