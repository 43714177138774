import { Icons } from 'src/components/atoms/Icon'
import { BrandSocialPicto } from 'src/graphql/generated/api-graphql'

export function enumBrandSocialPicto(name: string) {
  switch (name) {
    case BrandSocialPicto.Facebook:
      return Icons.socialFacebook
    case BrandSocialPicto.Instagram:
      return Icons.socialInstagram
    case BrandSocialPicto.Pinterest:
      return Icons.socialPinterest
    case BrandSocialPicto.Twitter:
      return Icons.socialX
    default:
      return undefined
  }
}
