// concerns both simple banner and a big banner (for caprice)

import { FC } from 'react'
import { PictureProps } from 'src/components/atoms/Picture'
import cx from 'classnames'

import { HtmlProps } from '../../atoms/Html'
import { ActionButtonProps } from '../../atoms/ActionButton'
import { ActionButtonVariant } from '../../atoms/ActionButton/styled'
import { Icons } from '../../atoms/Icon'

import * as SC from './styled'

export type SoloPushImageProps = MinimalSeoBlock & {
  className?: string
  title?: string
  htmlProps?: HtmlProps
  pictureProps?: PictureProps
  mobilePictureProps?: PictureProps
  actionButtonProps?: ActionButtonProps
  reverseOnMobile?: boolean
  reverseOnDesktop?: boolean
}

const SoloPushImage: FC<SoloPushImageProps> = ({
  className,
  htmlTag,
  htmlTitleTag,
  title,
  htmlProps,
  pictureProps,
  // in case we need to show a specific image on mobile
  mobilePictureProps,
  actionButtonProps,
  // not in mockups but possible to reverse text order on mobile and desktop
  reverseOnMobile,
  reverseOnDesktop,
}) => {
  return (
    <SC.Container
      className={cx('SoloPushImage', className)}
      as={htmlTag}
      $reverseOnMobile={reverseOnMobile}
      $reversOnDesktop={reverseOnDesktop}
    >
      {(title || htmlProps || actionButtonProps) && (
        <SC.Content>
          {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
          {htmlProps && <SC.StyledHtml {...htmlProps} />}
          {actionButtonProps && (
            <SC.StyledActionButton
              variant={ActionButtonVariant.secondary}
              iconProps={{ icon: Icons.arrowRight }}
              iconPosition="right"
              {...actionButtonProps}
            />
          )}
        </SC.Content>
      )}
      <SC.Pictures>
        <SC.StyledPicture
          {...pictureProps}
          $withMobileALternative={mobilePictureProps ? true : false}
        />
        {mobilePictureProps && (
          <SC.StyledMobilePicture {...mobilePictureProps} />
        )}
      </SC.Pictures>
    </SC.Container>
  )
}

export default SoloPushImage
