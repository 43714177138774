import styled from 'styled-components'

import CardsBlock from '../CardsBlock'
import { Title } from '../CardsBlock/styled'
import { mq, sizes } from '../../../theme'

export const Container = styled(CardsBlock)`
  background: ${({ theme }) => theme.colors.pureWhite};
  padding: 3rem 1.5rem;
  margin-inline: -1.5rem;
  width: auto;
  & ${Title} {
    color: ${({ theme }) => theme.scheme.newsCardsTitle};
  }
  @media ${mq(sizes.tablet)} {
    margin-inline: 0;
    padding: 2rem;
    border-radius: 1rem;
  }
  @media ${mq(sizes.desktop)} {
    padding: 4rem;
    border-radius: 3rem;
  }
`
