import { FC, useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useDebounce } from 'usehooks-ts'

import { LinkProps } from '../../atoms/Link'

import * as SC from './styled'

export type SoloContentAnchorsProps = MinimalSeoBlock & {
  className?: string
  links?: LinkProps[]
}

const SoloContentAnchors: FC<SoloContentAnchorsProps> = ({
  className,
  links,
}) => {
  const [isScrollable, setScrollable] = useState(false)

  const debouncedScrollable = useDebounce<boolean>(isScrollable, 10)

  const scrollRef = useRef<ScrollContainer>(null)

  const addScrollCursor = useCallback(() => {
    const element = scrollRef.current?.getElement()

    if (!element || !element?.children) return

    const childrenWidth = Array.from(element.children).reduce(
      (total, element) => {
        return total + element.clientWidth
      },
      0
    )

    setScrollable(element.offsetWidth < childrenWidth)
  }, [])

  useEffect(() => {
    const onResize = () => {
      addScrollCursor()
    }

    onResize()

    window && window.addEventListener('resize', onResize)
    return () => {
      window && window.removeEventListener('resize', onResize)
    }
  }, [addScrollCursor])

  return links && links.length > 0 ? (
    <SC.Container className={cx('SoloContentAnchors', className)}>
      <SC.Scroller $scrollable={debouncedScrollable} ref={scrollRef}>
        <SC.Links>
          {links.map((link, index) => (
            <li key={index}>
              <SC.StyledLink {...link}>{link.text}</SC.StyledLink>
            </li>
          ))}
        </SC.Links>
      </SC.Scroller>
    </SC.Container>
  ) : null
}

export default SoloContentAnchors
