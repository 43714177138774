import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useRef, useState } from 'react'
import html2canvas from 'html2canvas'
import { useRouter } from 'next/router'

import { SoloCapriceValentineStep } from '../../../components/molecules/SoloCapriceValentineGame/types'
import { actions, selectors } from '../../../redux'
import { SoloCapriceValentineGameResultProps } from '../../../components/molecules/SoloCapriceValentineGame/partial/SoloCapriceValentineGameResult'
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import { Icons } from '../../../components/atoms/Icon'
import { SoloCapriceValentineGameDownloadProps } from '../../../components/molecules/SoloCapriceValentineGame/partial/SoloCapriceValentineGameDownload'
import { SoloCapriceValentineGameDownloadModalProps } from '../../../components/molecules/SoloCapriceValentineGame/partial/SoloCapriceValentineGameDownloadModal'
import { imageMocks } from '../../../components/atoms/Picture/mocks'
import { sizes } from '../../../theme'
import { app } from '../../../configuration'
import { CapriceMaxSessionCount } from '../../../redux/solo/types'

import { useCapriceValentineGameSocials } from './useCapriceValentineGameSocials'
import { useCapriceValentineGameShare } from './useCapriceValentineGameShare'
import { useCapriceValentineGameLogin } from './useCapriceValentineGameLogin'
import { useCapriceValentineGameRegister } from './useCapriceValentineGameRegister'
import { useCapriceValentineGameOptin } from './useCapriceValentineGameOptin'

const screenshot = async (div: HTMLDivElement) => {
  return html2canvas(div, { scale: 2 }).then(async function (canvas) {
    const url = canvas.toDataURL('image/png', 1)
    const image = await fetch(url)
    const imageBlog = await image.blob()
    return URL.createObjectURL(imageBlog)
  })
}

export const useCapriceValentineGameResult =
  (): SoloCapriceValentineGameResultProps => {
    const session = useSelector(selectors.solo.capriceValentineSession)
    const dispatch = useDispatch()
    const [downloadModalOpen, setDownloadModalOpen] = useState(false)
    const [image, setImage] = useState('')
    const [pending, setPending] = useState(false)
    const count = useSelector(selectors.solo.capriceValentineCount)

    const isMaxSessionsReached = count >= CapriceMaxSessionCount ?? false
    const remainingSessions = CapriceMaxSessionCount - count

    const contentRef = useRef<HTMLDivElement>(null)

    const downloadProps: SoloCapriceValentineGameDownloadProps = {
      title:
        'Voici votre Saint Valentin concoctée avec amour<br/>par Caprice des Dieux et l’INTELLIGENCE ARTIFICIELLE',
      mobileTitle:
        'Voici votre Saint Valentin concoctée avec amour par Caprice des Dieux et l’INTELLIGENCE ARTIFICIELLE',
      text: session?.response ?? '',
      tags: ['#InstantCaprice', '#ComplicesàDeux'],
      contentRef,
    }

    const handleDownload = () => {
      if (contentRef.current) {
        if (window.innerWidth > sizes.desktop) {
          setPending(true)
        } else {
          setDownloadModalOpen(true)
        }
        screenshot(contentRef.current).then(function (imageURL) {
          if (contentRef.current) {
            if (window.innerWidth > sizes.desktop) {
              const link = document.createElement('a')
              link.href = imageURL
              link.download = 'mon-instant-caprice'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
              setPending(false)
            } else {
              setImage(imageURL)
            }
          }
        })
      }
    }

    const downloadModalProps: SoloCapriceValentineGameDownloadModalProps = {
      logoProps: {
        ...imageMocks,
        images: [
          {
            src: '/static/assets/images/caprice/caprice-logo.png',
            size: 400,
          },
        ],
      },
      isOpen: downloadModalOpen,
      closeHandler: () => setDownloadModalOpen(false),
      hideCloseButton: true,
      imagePath: image,
      text: "Maintenez appuyé sur l'image pour l'enregistrer",
    }

    const { asPath } = useRouter()

    const isConnected = useSelector(selectors.auth.isConnected)
    const [loginOpen, setLoginOpen] = useState(false)
    const [registerOpen, setRegisterOpen] = useState(false)
    const [optinModalOpen, setOptinModalOpen] = useState(false)
    const memberRegister = useSelector(selectors.member.memberRegister)

    useEffect(() => {
      if (memberRegister.success) {
        dispatch(actions.member.memberRegisterReset())
        setOptinModalOpen(true)
      }
    }, [memberRegister.success, dispatch])

    const loginProps = useCapriceValentineGameLogin(
      loginOpen,
      useCallback(() => setLoginOpen(false), [])
    )
    const registerProps = useCapriceValentineGameRegister(
      registerOpen,
      useCallback(() => setRegisterOpen(false), [])
    )

    const optinProps = useCapriceValentineGameOptin(
      optinModalOpen,
      useCallback(() => setOptinModalOpen(false), [])
    )

    return {
      title:
        'Voici votre Saint Valentin concoctée avec amour<br/>par Caprice des Dieux et l’INTELLIGENCE ARTIFICIELLE',
      text: session?.response ?? '',
      tags: ['#InstantCaprice', '#ComplicesàDeux'],
      info: 'Votre participation au jeu concours est bien prise en compte !<br/>Le gagnant sera contacté à la fin du jeu concours.<br><strong>Caprice des Dieux vous souhaite une belle Saint-Valentin ❤️</strong>',
      stepperProps: {
        text: 'ÉTAPE 3 : VOTRE SAINT VALENTIN PERSONALISÉE',
        total: 3,
        value: 2,
      },
      restartLabel: isMaxSessionsReached
        ? 'Vous avez atteint le nombre maximum de participations'
        : `Vous pouvez jouer encore ${remainingSessions} fois !`,
      restartButton: {
        variant: ActionButtonVariant.secondary,
        text: 'recommencer',
        iconPosition: 'right',
        iconProps: {
          icon: Icons.heartFull,
        },
        disabled: isMaxSessionsReached,
        onClick: () => {
          dispatch(
            actions.solo.setCapriceValentineStep(SoloCapriceValentineStep.INTRO)
          )
        },
      },
      downloadLabel: 'Partagez votre Saint Valentin idéale avec vos proches.',
      downloadButton: {
        isPending: pending,
        text: 'télécharger',
        variant: ActionButtonVariant.secondary,
        iconPosition: 'right',
        iconProps: {
          icon: pending ? Icons.refresh : Icons.download,
        },
        onClick: handleDownload,
      },
      shareProps: useCapriceValentineGameShare(
        app.APP_URL + asPath,
        'Découvrez votre Saint -Valentin idéale grâce à l’Intelligence Artificielle et tenter de remporter votre soirée parfaite'
      ),
      socialProps: useCapriceValentineGameSocials(),
      downloadModalProps,
      downloadProps,
      optinProps,
      ...(!isConnected && {
        registerProps,
        loginProps,
        participate: {
          title:
            'Connectez-vous pour participer au jeu concours et tentez de gagner cette Saint Valentin sur mesure !',
          text:
            'Pour que votre participation soit prise en compte, connectez-vous.<br/>' +
            'Le gagnant sera contacté à la fin du jeu concours.<br/>' +
            'Caprice des Dieux vous souhaite une belle Saint Valentin ❤️',
          actions: [
            {
              text: "J'AI UN COMPTE",
              variant: ActionButtonVariant.red,
              iconPosition: 'right',
              iconProps: {
                icon: Icons.user,
              },
              onClick: () => setLoginOpen(true),
            },
            {
              text: 'JE CRÉE UN COMPTE',
              variant: ActionButtonVariant.secondary,
              iconPosition: 'right',
              iconProps: {
                icon: Icons.arrowRight,
              },
              onClick: () => setRegisterOpen(true),
            },
          ],
        },
      }),
    }
  }
