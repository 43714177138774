import React, { FC } from 'react'
import cx from 'classnames'

import { PictureProps } from '../../atoms/Picture'
import { RelatedProductProps } from '../RelatedProduct'

import * as SC from './styled'

export type PushFormatsProps = MinimalSeoBlock & {
  className?: string
  title?: string
  imageProps?: PictureProps
  products?: RelatedProductProps[]
}

const PushFormats: FC<PushFormatsProps> = (props) => {
  const { className, htmlTag, title, htmlTitleTag, imageProps, products } =
    props

  return (
    <SC.PushFormats className={cx('PushFormats', className)} as={htmlTag}>
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      <SC.Content>
        {imageProps && (
          <SC.IllustrationMobile>
            <SC.StyledPicture {...imageProps} />
          </SC.IllustrationMobile>
        )}
        {products && products.length > 0 && (
          <SC.Products $withPicture={imageProps ? true : false}>
            {imageProps && (
              <SC.IllustrationDesktop>
                <SC.StyledPicture {...imageProps} />
              </SC.IllustrationDesktop>
            )}
            {products.map((product, index) => (
              <SC.StyledProduct
                {...product}
                key={`PushFormats-product-${index}`}
              />
            ))}
          </SC.Products>
        )}
      </SC.Content>
    </SC.PushFormats>
  )
}

export default PushFormats
