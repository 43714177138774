import { FC } from 'react'
import cx from 'classnames'

import { Icons } from '../../atoms/Icon'

import * as SC from './styled'

export type SoloContentQuoteProps = Pick<MinimalSeoBlock, 'htmlTag'> & {
  id?: string
  className?: string
  text?: string
}

const SoloContentQuote: FC<SoloContentQuoteProps> = ({
  id,
  className,
  htmlTag,
  text,
}) => {
  return text ? (
    <SC.Container
      id={id}
      className={cx('SoloContentQuote', className)}
      as={htmlTag}
    >
      <p>{text}</p>
      <SC.Quote icon={Icons.quote} aria-hidden />
    </SC.Container>
  ) : null
}

export default SoloContentQuote
