import { FC } from 'react'
import { PictureProps } from 'src/components/atoms/Picture'
import cx from 'classnames'
import { useTheme } from 'styled-components'

import { IconButtonProps } from '../../atoms/IconButton'
import { Icons } from '../../atoms/Icon'

import * as SC from './styled'

export type SoloHeroProps = MinimalSeoBlock & {
  className?: string
  banner: PictureProps
  mobileBanner?: PictureProps
  logo?: PictureProps
  like?: IconButtonProps
}

const SoloHero: FC<SoloHeroProps> = ({
  className,
  htmlTag,
  banner,
  mobileBanner,
  logo,
  like,
}) => {
  const theme = useTheme()

  return (
    <SC.Container className={cx('SoloHero', className)} as={htmlTag}>
      {logo && <SC.Logo {...logo} />}
      {like && <SC.Like {...like} />}
      <SC.Images>
        <SC.Banner
          {...banner}
          $withMobileALternative={mobileBanner ? true : false}
        />
        {mobileBanner && <SC.MobileBanner {...mobileBanner} />}
      </SC.Images>
      <SC.Shape
        {...(theme.scheme.heroShape
          ? { icon: theme.scheme.heroShape }
          : { icon: Icons.soloHeroRound })}
        aria-hidden
      />
      <SC.Bezel
        viewBox="0 0 1440 42"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        aria-hidden
      >
        <path
          fill="white"
          fillRule="evenodd"
          d="M0 0v42h723.608C429.033 41.9871 167.042 25.5352 0 0Zm724.303 42H1440V1.19198C1272.66 26.046 1014.27 41.9873 724.303 42Z"
          clipRule="evenodd"
        />
      </SC.Bezel>
    </SC.Container>
  )
}

export default SoloHero
