import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SoloCapriceValentineStep } from '../../../components/molecules/SoloCapriceValentineGame/types'
import { actions, selectors } from '../../../redux'
import { SoloCapriceValentineGameLoadProps } from '../../../components/molecules/SoloCapriceValentineGame/partial/SoloCapriceValentineGameLoad'

export const useCapriceValentineGameInputLoad =
  (): SoloCapriceValentineGameLoadProps => {
    const step = useSelector(selectors.solo.capriceValentineStep)
    const dispatch = useDispatch()

    useEffect(() => {
      if (step === SoloCapriceValentineStep.INPUT_LOAD) {
        setTimeout(() => {
          dispatch(
            actions.solo.setCapriceValentineStep(
              SoloCapriceValentineStep.INPUT2
            )
          )
        }, 500)
      }
    }, [step, dispatch])

    return {
      title: 'Décrivez votre Saint Valentin idéale',
      stepperProps: {
        text: `ÉTAPE 1 : VOTRE SAINT VALENTIN IDÉALE`,
        value: step === SoloCapriceValentineStep.INPUT1 ? 0 : 1,
        total: 3,
      },
    }
  }
