import styled, { css } from 'styled-components'

import { fonts, mq, sizes } from '../../../../../theme'
import Html from '../../../../atoms/Html'
import FormikField from '../../../../form/FormikField'
import FormikForm from '../../../../form/FormikForm'
import FormSubmitButton from '../../../../form/FormSubmitButton'
import {
  HelpMessage,
  Input,
  InputContainer,
} from '../../../../form/fields/FormFieldTextArea/styled'
import ActionButton from '../../../../atoms/ActionButton'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem 8rem;
  @media ${mq(sizes.desktop)} {
    padding: 0 8rem 16.8rem;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-width: 91rem;
`

export const Title = styled(Html)`
  ${fonts.TartineScriptBold}
  color: ${({ theme }) => theme.colors.pureWhite};
  text-align: center;
  font-size: 3.2rem;
  padding: 4rem 0 3rem;
  line-height: 120%;
  @media ${mq(sizes.desktop)} {
    font-size: 4rem;
  }
`

export const Fields = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`

export const FieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Error = styled(Html)`
  ${({ theme }) => theme.textStyles.textXSmall};
  color: ${({ theme }) => theme.colors.standardRed};
  padding: 0.4rem 0.8rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 6px;
  margin: 1rem 0;
  width: 100%;
`
export const Field = styled(FormikField)`
  & ${InputContainer} {
    padding: 0;
  }
  & ${Input} {
    padding: 1.8rem 2rem 3rem;
  }
  & ${HelpMessage} {
    ${({ theme }) => theme.textStyles.textXSmall};
    color: #fff;
  }
`

export const Help = styled(ActionButton)`
  position: absolute;
  bottom: 0;
  border-color: ${({ theme }) => theme.colors.arapawa};
  background-color: ${({ theme }) => theme.colors.witchHaze};
  color: ${({ theme }) => theme.colors.arapawa};

  ${({ disabled }) =>
    !disabled &&
    css<{
      disabled?: boolean
    }>`
      cursor: pointer;
      &:hover:not(:disabled),
      a:hover &:not(:disabled),
      [data-hover]:hover + &,
      a:focus-visible &:not(:disabled),
      &:focus-visible:not(:disabled),
      [data-hover]:focus-within + & {
        border-color: ${({ theme }) => theme.colors.arapawa};
        background-color: ${({ theme }) => theme.colors.arapawa};
        color: ${({ theme }) => theme.colors.witchHaze};
      }
    `}
`
export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`
export const Submit = styled(FormSubmitButton)`
  align-self: center;
  margin: 3rem 0 2rem;
  @media ${mq(sizes.desktop)} {
    margin: 3rem 0 4rem;
  }
`

export const Advice = styled.div`
  border-radius: 1rem;
  background-color: #0067b2;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
`

export const AdviceTitle = styled.div`
  ${({ theme }) => theme.textStyles.titleH7};
  color: ${({ theme }) => theme.colors.pureWhite};
`
export const AdviceText = styled(Html)`
  ${({ theme }) => theme.textStyles.textXSmall};
  color: ${({ theme }) => theme.colors.pureWhite};
`
