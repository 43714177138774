import { SoloPage, SoloPageMenuIcon } from '../../graphql/generated/api-graphql'
import { Icons } from '../../components/atoms/Icon'
import { SoloNavProps } from '../../components/molecules/SoloNav'
import { wediaImagesTransform } from '../Common/wedia'
import Router from '../../routes/Router'
import routes from '../../routes/definitions'

const transformSoloNav = (data?: SoloPage, referer?: string): SoloNavProps => {
  return {
    logo: {
      withHD: true,
      withLazyLoading: true,
      maxWidth: 180,
      // @TODO change image
      alt:
        data?.brand?.soloLogo?.alt ??
        data?.brand?.soloLogo?.alt ??
        data?.brand.name ??
        ' ',
      images: wediaImagesTransform(
        data?.brand?.soloLogo ?? data?.brand?.pictureLogo ?? undefined
      ),
    },
    links:
      data?.menuLinks?.map((item) => {
        return {
          text: item.title,
          href: Router.getRouteUrl(routes.brand, {
            slug: item.url ?? '',
          }),
          ...(item.isActive ? { 'aria-current': true } : {}),
          ...(item.icon === SoloPageMenuIcon.Contact
            ? {
                iconProps: {
                  icon: Icons.mail,
                },
              }
            : {}),
        }
      }) ?? undefined,
    referer,
  }
}

export default transformSoloNav
