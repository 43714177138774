import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Text = styled.div`
  ${({ theme }) => theme.textStyles.textXXSmallCaps};
  color: ${({ theme }) => theme.colors.pureWhite};
  text-align: center;
  padding: 1rem;
`

export const Steps = styled.ul<{ $total: number }>`
  list-style: none;
  width: 100%;
  max-width: 39rem;
  display: grid;
  gap: 0.4rem;
  margin: 0;
  padding: 0;
  grid-template-columns: ${({ $total }) =>
    Array.from({ length: $total })
      .map(() => '1fr')
      .join(' ')};
`

export const Step = styled.li<{ $selected: boolean }>`
  width: 100%;
  border-radius: 8px;
  height: 6px;
  background-color: ${({ $selected }) => ($selected ? '#FFF37A' : '#fff')};
  opacity: ${({ $selected }) => ($selected ? '1' : '0.5')};
`
