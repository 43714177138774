import styled from 'styled-components'

import Header from '../../components/molecules/Header'
import { mq, sizes } from '../../theme'
import BreadcrumbComponent from '../../components/molecules/Breadcrumb'
import ContactUsBlock from '../../components/molecules/ContactUsBlock'
// import { Container as SoloVideo } from '../../components/molecules/SoloVideo/styled'
import { Container as SoloContentTitle } from '../../components/molecules/SoloContentTitle/styled'
import { Container as SoloContentBlock } from '../../components/molecules/SoloContentBlock/styled'
import { Container as Iframe } from '../../components/atoms/Iframe/styled'
import Ad from '../../components/molecules/Ad'

export const Layout = styled.div<{ $adBackground?: string }>`
  background-color: ${({ theme, $adBackground }) =>
    $adBackground ?? theme.colors.linen};
  --padding-inline--content: 2rem;
  --max-width--content: ${({ theme }) => theme.sizes.scopingMedium / 10}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  @supports (min-height: 100svh) {
    min-height: 100svh;
  }
`
export const StyledHeader = styled(Header)``
export const StyledAd = styled(Ad)``
export const Main = styled.main<{ $withMobileModal?: boolean }>`
  position: relative;
  z-index: ${({ theme, $withMobileModal }) =>
    $withMobileModal ? theme.zIndexes.modal : theme.zIndexes.default};
  width: 100%;
  flex: 1;
  padding: 0;
  background: ${({ theme }) => theme.scheme.generalBodyFill};
  @media ${mq(sizes.desktop)} {
    z-index: ${({ theme }) => theme.zIndexes.default};
  }
`
export const Top = styled.div`
  background: ${({ theme }) =>
    theme.scheme.generalHeaderFill ?? theme.colors.pureWhite};
`
export const Title = styled(SoloContentTitle)``
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  padding-inline: 1.5rem;
  padding-block: 3rem;
  // content blocks spacing
  & > :not(:first-child) {
    margin-block-start: 4rem;
  }
  & > ${SoloContentTitle} + ${SoloContentBlock} {
    margin-block-start: 3rem;
  }
  & > ${SoloContentBlock} + ${SoloContentTitle} {
    margin-block-start: 6rem;
  }
  @media ${mq(sizes.desktop)} {
    padding-inline: var(--padding-inline--content);
    max-width: calc(
      var(--max-width--content) + var(--padding-inline--content) * 2
    );
    padding-block: 4rem;
    // content blocks spacing
    & > :not(:first-child) {
      margin-block-start: 8rem;
    }
    & > ${SoloContentTitle} + ${SoloContentBlock} {
      margin-block-start: 5rem;
    }
    & > ${SoloContentBlock} + ${SoloContentTitle} {
      margin-block-start: 10rem;
    }
  }
  @media ${mq(sizes.scopingMedium)} {
    // fucking exceptions
    & > ${Iframe} {
      flex: 0 0 auto;
      width: 100%;
      max-width: ${({ theme }) => theme.sizes.scoping / 10}rem;
      margin-inline: auto;
    }
  }
`
export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-inline: auto;
  padding-inline: 1.5rem;
  padding-block: 2rem;
  @media ${mq(sizes.desktop)} {
    padding-inline: var(--padding-inline--content);
    padding-block: 4rem;
    max-width: calc(
      var(--max-width--content) + var(--padding-inline--content) * 2
    );
  }
`
export const StyledContact = styled(ContactUsBlock)`
  border-radius: 2rem;
  @media ${mq(sizes.desktop)} {
    border-radius: 4rem;
  }
`
export const Breadcrumb = styled(BreadcrumbComponent)``
