import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Link from '../../atoms/Link'

export const Container = styled.nav`
  display: none;
  @media ${mq(sizes.desktop)} {
    display: block;
    pointer-events: none;
  }
`

export const List = styled.ul<{ $offset?: number; $sticky?: boolean }>`
  list-style: none;
  position: fixed;
  top: calc(4rem + ${({ $offset }) => ($offset ? $offset / 10 : 0)}rem);
  right: 2rem;
  z-index: ${({ theme }) => theme.zIndexes.navigation - 1};
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
  width: fit-content;
  pointer-events: none;
  transition: opacity 150ms ease-in-out, visibility 0ms 200ms;
  ${({ $sticky }) =>
    $sticky !== true &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`
export const Item = styled.li`
  @media ${mq(sizes.desktop)} {
  }
`
export const StyledLink = styled(Link)<{ $active?: boolean }>`
  ${({ theme }) => theme.textStyles.titleH8}
  justify-content: center;
  max-width: 20rem;
  padding: 0.4rem 1rem;
  border-radius: 0.8rem;
  box-shadow: 0 1rem 3rem 0 rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: color 300ms ease-in-out, background-color 300ms ease-in-out,
    box-shadow 200ms ease-in-out;
  pointer-events: all;
  // states
  color: ${({ theme, $active }) =>
    $active === true
      ? theme.scheme.historyAnchorsColorActive
      : theme.scheme.historyAnchorsColor};
  background-color: ${({ theme, $active }) =>
    $active === true
      ? theme.scheme.historyAnchorsFillActive
      : theme.scheme.historyAnchorsFill};
  &[aria-current] {
    color: ${({ theme }) => theme.scheme.historyAnchorsColorActive};
    background-color: ${({ theme }) => theme.scheme.historyAnchorsFillActive};
  }
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 1rem 3rem 0 rgba(0, 0, 0, 0.1);
  }
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.scheme.historyAnchorsColorHover};
    background-color: ${({ theme }) => theme.scheme.historyAnchorsFillHover};
  }
  &:active {
    color: ${({ theme }) => theme.scheme.historyAnchorsColorActive};
    background-color: ${({ theme }) => theme.scheme.historyAnchorsFillActive};
  }
  @media ${mq(sizes.scoping)} {
    ${({ theme }) => theme.textStyles.titleH7}
  }
  @media ${mq(sizes.scopingLarge)} {
    ${({ theme }) => theme.textStyles.titleH6}
  }
`
