import { FC } from 'react'
import slugify from 'slugify'

import { SoloSectionTitleBlock as Data } from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import SoloContentTitle from '../../../components/molecules/SoloContentTitle'
import { SoloContentTitleVariant } from '../../../components/molecules/SoloContentTitle/types'

export type SoloSectionTitleBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

const SoloSectionTitleBlock: FC<SoloSectionTitleBlockProps> = ({ data }) => {
  return data.title ? (
    <SoloContentTitle
      id={slugify(data.title)}
      title={data.title}
      htmlTag="h2"
      variant={SoloContentTitleVariant.Alternative}
    />
  ) : null
}

export default SoloSectionTitleBlock
