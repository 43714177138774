import { FC } from 'react'
import { PictureProps } from 'src/components/atoms/Picture'
import cx from 'classnames'

import Html, { HtmlProps } from '../../atoms/Html'
import { ActionButtonProps } from '../../atoms/ActionButton'

import * as SC from './styled'

export type SoloBrandIntroProps = MinimalSeoBlock & {
  id?: string
  className?: string
  title?: string
  subtitle?: string
  htmlProps?: HtmlProps
  actionButtonProps?: ActionButtonProps
  pictureProps?: PictureProps
  pictureRatio?: number
  reverseOnDesktop?: boolean
}

const SoloBrandIntro: FC<SoloBrandIntroProps> = ({
  id,
  className,
  htmlTag,
  htmlTitleTag,
  title,
  subtitle,
  htmlProps,
  actionButtonProps,
  pictureProps,
  pictureRatio,
  reverseOnDesktop,
}) => {
  return (
    <SC.Container
      id={id}
      className={cx('SoloBrandIntro', className)}
      as={htmlTag}
      $reverseOnDesktop={reverseOnDesktop}
    >
      {title && (
        <SC.Title as={htmlTitleTag}>
          <strong>{title}</strong>
          {` `}
          {subtitle && <Html htmlTag={'span'}>{subtitle}</Html>}
        </SC.Title>
      )}
      {pictureProps && (
        <SC.StyledPicture {...pictureProps} $pictureRatio={pictureRatio} />
      )}
      {htmlProps && <SC.Text htmlTag={'div'} {...htmlProps} />}
      {actionButtonProps && <SC.Action {...actionButtonProps} />}
    </SC.Container>
  )
}

export default SoloBrandIntro
