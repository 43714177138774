import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  SoloVideoBlock as Data,
  LinkTarget,
} from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import SoloContentBlock from '../../../components/molecules/SoloContentBlock'
import { transformWediaVideo } from '../../Common/wedia'
import SoloVideo from '../../../components/molecules/SoloVideo'

export type SoloVideoBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

const SoloVideoBlock: FC<SoloVideoBlockProps> = ({ data }) => {
  const { t } = useTranslation()

  return data.type == 'news' && data.video ? (
    <SoloVideo
      title={data.title ?? undefined}
      videoProps={transformWediaVideo(data.video)}
      htmlProps={
        data.videoDescription
          ? {
              text: data.videoDescription,
            }
          : undefined
      }
      actionButtonProps={
        data.ctaBlock && data.ctaBlock.href
          ? {
              href: data.ctaBlock.href,
              label: data.ctaBlock.label ?? t('learn_more'),
              target:
                data.ctaBlock?.target === LinkTarget.Blank ? '_blank' : '_self',
            }
          : undefined
      }
    />
  ) : (
    <SoloContentBlock
      title={data.title ?? undefined}
      {...(data.video
        ? {
            videoProps: transformWediaVideo(data.video),
          }
        : {})}
      htmlProps={
        data.videoDescription
          ? {
              text: data.videoDescription,
            }
          : undefined
      }
      actionButtonProps={
        data.ctaBlock && data.ctaBlock.href
          ? {
              href: data.ctaBlock.href,
              label: data.ctaBlock.label ?? t('learn_more'),
              target:
                data.ctaBlock?.target === LinkTarget.Blank ? '_blank' : '_self',
            }
          : undefined
      }
    />
  )
}

export default SoloVideoBlock
