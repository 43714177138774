import { FC } from 'react'

import { SoloLayoutProps } from '../../layouts/SoloLayout'

import * as SC from './styled'

export type SoloTemplateProps = SoloLayoutProps

const SoloTemplate: FC<SoloTemplateProps> = ({ ...layoutProps }) => {
  return <SC.Layout {...layoutProps}>{layoutProps.children}</SC.Layout>
}

export default SoloTemplate
