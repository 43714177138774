import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import RelatedProduct from '../RelatedProduct'

export const PushFormats = styled.section`
  & > :not(:first-child) {
    margin-top: 1.5rem;
  }
  padding: 2rem 1.5rem;
  background: ${({ theme }) => theme.colors.snow};
  @media ${mq(sizes.tablet)} {
    border-radius: 4rem;
    padding: 4rem;
  }
`

export const Title = styled.h3`
  ${({ theme }) => theme.textStyles.titleH3}
  color: ${({ theme }) => theme.colors.bismark};
  margin: 0;
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH2}
  }
`

export const Content = styled.div`
  & > :not(:first-child) {
    margin-top: 1.5rem;
  }
  @media ${mq(sizes.tablet)} {
    & > :not(:first-child) {
      margin-top: 0;
    }
  }
`

export const IllustrationMobile = styled.div`
  position: relative;
  border-radius: 3rem;
  overflow: hidden;
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`

export const IllustrationDesktop = styled.div`
  display: none;
  @media ${mq(sizes.tablet)} {
    position: relative;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    align-self: stretch;
    display: block;
    border-radius: 3rem;
    overflow: hidden;
  }
  @media ${mq(sizes.desktop)} {
    grid-row: 1 / 4;
  }
  @media ${mq(sizes.scoping)} {
    grid-row: 1 / 3;
  }
`

export const StyledPicture = styled(Picture)`
  background: rgba(0, 0, 0, 0.1);
  &,
  & img {
    display: block;
    width: 100%;
    height: 100%;
  }
  & img {
    object-fit: cover;
  }
`

export const Products = styled.div<{ $withPicture?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  gap: 2.4rem;
  overflow: auto hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${mq(sizes.tablet)} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-gap: 2rem;
    overflow: unset;
  }
  @media ${mq(sizes.desktop)} {
  }
  @media ${mq(sizes.scoping)} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;
  }
`

export const StyledProduct = styled(RelatedProduct)`
  flex: 0 0 23rem;
  @media ${mq(sizes.tablet)} {
    max-width: none;
  }
`
