import React, { FC, useCallback } from 'react'

import { SoloPageBlocksUnion } from '../../graphql/generated/api-graphql'

import * as components from './components'
import { BlockProps, BlocksProps } from './props'

const Blocks: FC<BlocksProps> = ({ blocks, ...otherParams }) => {
  const renderBlock: any = useCallback(
    (block: SoloPageBlocksUnion & { __typename: string }, key: number) => {
      const Block: FC<BlockProps> =
        (components as any)[block.__typename] ?? null

      if (!Block) {
        console.warn('Missing block : ' + block.__typename)
        return null
      }

      return (
        <Block
          key={`${block.__typename}-${key}`}
          index={key}
          data={block}
          {...otherParams}
        />
      )
    },
    [otherParams]
  )

  if (blocks?.length === 0) {
    return null
  }

  return <>{blocks?.map(renderBlock)}</>
}

export default Blocks
