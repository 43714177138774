import { Icons } from '../../../components/atoms/Icon'
import { SoloCapriceValentineGameSocialsProps } from '../../../components/molecules/SoloCapriceValentineGame/partial/SoloCapriceValentineGameSocials'

export const useCapriceValentineGameShare = (
  url: string,
  text: string
): SoloCapriceValentineGameSocialsProps => {
  return {
    text: 'Partager',
    actions: [
      {
        iconProps: {
          icon: Icons.socialFacebook,
        },
        linkProps: {
          href: `https://facebook.com/sharer/sharer.php?u=${url}`,
          text: 'Facebook',
          target: '_blank',
        },
      },
      {
        iconProps: {
          icon: Icons.socialX,
        },
        linkProps: {
          href: `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
            `${text} - ${url}`
          )}&amp;url=${url}`,
          text: 'X',
          target: '_blank',
        },
      },
      {
        iconProps: {
          icon: Icons.socialWhatsapp,
        },
        linkProps: {
          text: 'Whatsapp',
          href: `whatsapp://send?text=${text} - ${url}`,
          data: { action: 'share/whatsapp/share' },
        },
      },
      {
        iconProps: {
          icon: Icons.socialMessenger,
        },
        linkProps: {
          isNativeLink: true,
          text: 'Messenger',
          href: `fb-messenger://share?link=${url}`,
        },
      },
    ],
  }
}
