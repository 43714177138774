import styled from 'styled-components'

import { fonts } from '../../../../../theme'
import Link from '../../../../atoms/Link'
import Icon from '../../../../atoms/Icon'

export const Container = styled.div`
  padding: 0 1.5rem;
`

export const Text = styled.div`
  ${fonts.PoppinsBold}
  color: ${({ theme }) => theme.colors.pureWhite};
  text-align: center;
  font-size: 2rem;
  padding: 0 1.5rem 1.5rem;
`

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  gap: 2rem;
  margin: 0;
  padding: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const Item = styled.li`
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
`
export const StyledLink = styled(Link)`
  display: flex;
  color: ${({ theme }) => theme.colors.pureWhite};
  transition: opacity 200ms ease-in-out;
  &:hover {
    opacity: 0.8;
  }
`
export const Label = styled.span`
  ${({ theme }) => theme.textStyles.accessibleHiddenText}
`
export const StyledIcon = styled(Icon)`
  width: ${({ width }) => (width ? `${width / 10}rem` : '3.2rem')};
  height: auto;
`
