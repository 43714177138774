import styled from 'styled-components'

import { fonts, mq, sizes } from '../../../../../theme'
import ActionButton from '../../../../atoms/ActionButton'
import Html from '../../../../atoms/Html'
import FormSubmitButton from '../../../../form/FormSubmitButton'

export const Title = styled(Html)`
  ${fonts.TartineScriptBold}
  font-size: 2.4rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.capriceDark};
  text-align: center;
  padding: 0;
  margin: 0 0 2rem;
  @media ${mq(sizes.desktop)} {
    font-size: 3.6rem;
  }
`

export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.titleH5};
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.capriceDark};
  text-align: center;
  padding: 2rem 0 0;
  @media ${mq(sizes.desktop)} {
    font-size: 2.4rem;
  }
`

export const Content = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 0 0 2rem 2rem;
  padding: 0 0.5rem 3rem;
  @media ${mq(sizes.desktop)} {
    border-radius: 0 0 3rem 3rem;
    padding: 0 1rem 4rem 1rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 225px;
  height: 400px;
  @media ${mq(sizes.desktop)} {
    width: 600px;
    height: 337.5px;
  }
`
export const Image = styled.img`
  width: 100%;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  gap: 1rem;
  height: 34rem;
  padding: 0 1.5rem;
  @media ${mq(sizes.desktop)} {
    height: 38rem;
    padding: 0 2.5rem;
  }
`
export const Option = styled.div<{ $active: boolean }>`
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.capriceDark : theme.colors.capriceUltraLight};
  color: ${({ theme, $active }) =>
    $active ? theme.colors.pureWhite : theme.colors.bismark};
  border-radius: 6px;
  padding: 1.2rem 1rem 1.2rem 1.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  & svg {
    opacity: ${({ $active }) => ($active ? 1 : 0)};
  }
  cursor: pointer;
`
export const Actions = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding: 2rem 1.5rem 0;
  flex-direction: column;
  @media ${mq(sizes.desktop)} {
    padding: 2rem 2.5rem 0;
    align-items: center;
    flex-direction: row;
  }
`

export const Button = styled(ActionButton)``

export const SubmitButton = styled(FormSubmitButton)``
