import { FC } from 'react'

import { CardsBlockProps } from '../CardsBlock'

import * as SC from './styled'

export type SoloCardsBlockProps = CardsBlockProps

const SoloCardsBlock: FC<SoloCardsBlockProps> = ({ ...props }) => {
  return <SC.Container {...props} />
}

export default SoloCardsBlock
