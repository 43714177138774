import { useDispatch } from 'react-redux'

import { SoloCapriceValentineStep } from '../../../components/molecules/SoloCapriceValentineGame/types'
import { actions } from '../../../redux'
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import { Icons } from '../../../components/atoms/Icon'
import { SoloCapriceValentineGameErrorProps } from '../../../components/molecules/SoloCapriceValentineGame/partial/SoloCapriceValentineGameError'

export const useCapriceValentineGameGptError =
  (): SoloCapriceValentineGameErrorProps => {
    const dispatch = useDispatch()

    return {
      title:
        'Votre Saint Valentin  idéale est concoctée avec amour<br/>par Caprice des Dieux et l’INTELLIGENCE ARTIFICIELLE',
      error: 'Service ChatGPT indisponible, veuillez réessayer ultérieurement',
      actions: [
        {
          text: "RETOUR À L'ÉTAPE 1",
          variant: ActionButtonVariant.red,
          iconPosition: 'left',
          iconProps: {
            icon: Icons.chevronLeft,
          },
          onClick: () =>
            dispatch(
              actions.solo.setCapriceValentineStep(
                SoloCapriceValentineStep.INPUT1
              )
            ),
        },
      ],
    }
  }
