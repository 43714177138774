import React, { FC } from 'react'
import cx from 'classnames'

import { LinkProps } from '../../atoms/Link'
import { IconProps } from '../../atoms/Icon'

import * as SC from './styled'

export type SoloSocialsProps = MinimalSeoBlock & {
  className?: string
  title?: string
  socials?: {
    linkProps?: LinkProps
    iconProps?: IconProps
  }[]
}

const SoloSocials: FC<SoloSocialsProps> = ({
  className,
  title,
  socials,
  htmlTag,
  htmlTitleTag,
}) => {
  return (
    <SC.Container className={cx('SoloSocials', className)} as={htmlTag}>
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      {socials && socials.length > 0 && (
        <SC.Socials>
          {socials.map((social, index) => (
            <SC.Social key={index}>
              <SC.StyledLink {...social.linkProps}>
                {social.linkProps?.label && (
                  <span>{social.linkProps.label}</span>
                )}
                {social.iconProps && (
                  <SC.StyledIcon width={44} height={44} {...social.iconProps} />
                )}
              </SC.StyledLink>
            </SC.Social>
          ))}
        </SC.Socials>
      )}
    </SC.Container>
  )
}

export default SoloSocials
