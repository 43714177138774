import { FC } from 'react'

import {
  CouponModel,
  SoloCouponsBlock as Data,
} from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import { useBrandCoupons } from '../../Coupons/useBrandCoupons'
import { PagesByTemplate } from '../../Page/PageByTemplate'
import CouponCardsBlock from '../../../components/molecules/CouponCardsBlock'

export type SoloCouponsBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
  brandId: string
  pagesByTemplate: PagesByTemplate
}

const SoloCouponsBlock: FC<SoloCouponsBlockProps> = (props) => {
  const couponsProps = useBrandCoupons(
    props?.brandId,
    3,
    props.pagesByTemplate,
    CouponModel.Brand
  )

  return couponsProps ? <CouponCardsBlock {...couponsProps} /> : null
}

export default SoloCouponsBlock
