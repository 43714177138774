import React, { FC } from 'react'
import cx from 'classnames'

import SoloCapriceValentineGameStepper, {
  SoloCapriceValentineGameStepperProps,
} from '../SoloCapriceValentineGameStepper'
import { ActionButtonProps } from '../../../../atoms/ActionButton'
import SoloCapriceValentineGameSocials, {
  SoloCapriceValentineGameSocialsProps,
} from '../SoloCapriceValentineGameSocials'
import Icon, { Icons } from '../../../../atoms/Icon'
import { FormSubmitButtonProps } from '../../../../form/FormSubmitButton'
import SoloCapriceValentineGameDownloadModal, {
  SoloCapriceValentineGameDownloadModalProps,
} from '../SoloCapriceValentineGameDownloadModal'
import SoloCapriceValentineGameDownload, {
  SoloCapriceValentineGameDownloadProps,
} from '../SoloCapriceValentineGameDownload'
import LightAuthLoginModal, {
  LightAuthLoginModalProps,
} from '../../../LightAuthModal/LightAuthLoginModal'
import LightAuthRegisterModal, {
  LightAuthRegisterModalProps,
} from '../../../LightAuthModal/LightAuthRegisterModal'
import LightAuthOptinModal, {
  LightAuthOptinModalProps,
} from '../../../LightAuthModal/LightAuthOptinModal'

import * as SC from './styled'

export type SoloCapriceValentineGameResultProps = Pick<
  MinimalSeoBlock,
  'htmlTag'
> & {
  id?: string
  className?: string
  stepperProps?: SoloCapriceValentineGameStepperProps
  title?: string
  text?: string
  tags?: string[]
  info?: string
  restartLabel?: string
  restartButton?: ActionButtonProps
  downloadLabel?: string
  downloadButton?: FormSubmitButtonProps
  shareProps?: SoloCapriceValentineGameSocialsProps
  socialProps?: SoloCapriceValentineGameSocialsProps
  downloadProps?: SoloCapriceValentineGameDownloadProps
  downloadModalProps?: SoloCapriceValentineGameDownloadModalProps
  loginProps?: LightAuthLoginModalProps
  registerProps?: LightAuthRegisterModalProps
  optinProps?: LightAuthOptinModalProps
  participate?: {
    title: string
    text: string
    actions: ActionButtonProps[]
  }
}

const SoloCapriceValentineGameResult: FC<
  SoloCapriceValentineGameResultProps
> = ({
  id,
  className,
  title,
  stepperProps,
  text,
  tags,
  info,
  restartLabel,
  restartButton,
  downloadLabel,
  downloadButton,
  shareProps,
  socialProps,
  downloadProps,
  downloadModalProps,
  loginProps,
  registerProps,
  optinProps,
  participate,
}) => {
  return (
    <SC.Container
      id={id}
      className={cx('SoloCapriceValentineGameResult', className)}
    >
      <SC.Content>
        <SC.Wrapper>
          {stepperProps && (
            <SoloCapriceValentineGameStepper {...stepperProps} />
          )}
          <SC.Title>{title}</SC.Title>
          <SC.Message>
            <SC.Text>{text}</SC.Text>
            <SC.Tags>
              {tags?.map((tag, i) => (
                <SC.Tag key={i}>{tag}</SC.Tag>
              ))}
            </SC.Tags>
          </SC.Message>
          {participate ? (
            <SC.Participate>
              <SC.ParticipateTitle>{participate.title}</SC.ParticipateTitle>
              <SC.ParticipateText>{participate.text}</SC.ParticipateText>
              <SC.ParticipateButtons>
                {participate.actions.map((action, key) => (
                  <SC.Button key={key} {...action} />
                ))}
              </SC.ParticipateButtons>
            </SC.Participate>
          ) : (
            <>{info && <SC.Info>{info}</SC.Info>}</>
          )}

          <SC.Download>
            {downloadLabel && (
              <SC.DownloadLabel>{downloadLabel}</SC.DownloadLabel>
            )}
            {downloadButton && <SC.DownloadButton {...downloadButton} />}
          </SC.Download>
          <SC.Restart>
            {restartButton && <SC.RestartButton {...restartButton} />}
            {restartLabel && (
              <SC.RestartLabel>
                <Icon icon={Icons.info} />
                <span>{restartLabel}</span>
              </SC.RestartLabel>
            )}
          </SC.Restart>
        </SC.Wrapper>
      </SC.Content>
      {(shareProps || socialProps) && (
        <SC.Footer>
          {shareProps && <SoloCapriceValentineGameSocials {...shareProps} />}
          {shareProps && socialProps && <SC.Divider />}
          {socialProps && <SoloCapriceValentineGameSocials {...socialProps} />}
        </SC.Footer>
      )}
      {downloadProps && (
        <SC.Offscreen>
          <SoloCapriceValentineGameDownload {...downloadProps} />
        </SC.Offscreen>
      )}
      {downloadModalProps && (
        <SoloCapriceValentineGameDownloadModal {...downloadModalProps} />
      )}
      {loginProps && <LightAuthLoginModal {...loginProps} />}
      {optinProps && <LightAuthOptinModal {...optinProps} />}
      {registerProps && <LightAuthRegisterModal {...registerProps} />}
    </SC.Container>
  )
}

export default SoloCapriceValentineGameResult
