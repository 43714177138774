import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type SoloCapriceValentineGameStepperProps = Pick<
  MinimalSeoBlock,
  'htmlTag'
> & {
  id?: string
  className?: string
  total: number
  value?: number
  text?: string
}

const SoloCapriceValentineGameStepper: FC<
  SoloCapriceValentineGameStepperProps
> = ({ id, className, text, total, value }) => {
  return (
    <SC.Container
      id={id}
      className={cx('SoloCapriceValentineGameStepper', className)}
    >
      <SC.Steps $total={total}>
        {Array.from({ length: total }).map((_v, i) => (
          <SC.Step key={i} $selected={i <= (value ?? 0)} />
        ))}
      </SC.Steps>
      <SC.Text>{text}</SC.Text>
    </SC.Container>
  )
}

export default SoloCapriceValentineGameStepper
