import { FC } from 'react'
import cx from 'classnames'

import { HtmlProps } from '../../atoms/Html'
import { PictureProps } from '../../atoms/Picture'
import { VideoProps } from '../../atoms/Video'
import { ActionButtonProps } from '../../atoms/ActionButton'
import { ActionButtonVariant } from '../../atoms/ActionButton/styled'

import * as SC from './styled'
import {
  SoloContentBlockMediaPosition,
  SoloContentBlockMediaSize,
} from './types'

export type SoloContentBlockProps = MinimalSeoBlock & {
  id?: string
  className?: string
  title?: string
  side?: string
  htmlProps?: HtmlProps
  pictureProps?: PictureProps
  videoProps?: VideoProps
  mediaPosition?: SoloContentBlockMediaPosition
  mediaSize?: SoloContentBlockMediaSize
  withMediaCentered?: boolean
  withTitleCentered?: boolean
  actionButtonProps?: ActionButtonProps
}

const SoloContentBlock: FC<SoloContentBlockProps> = ({
  id,
  className,
  htmlTag,
  htmlTitleTag,
  title,
  side,
  htmlProps,
  pictureProps,
  videoProps,
  mediaPosition,
  mediaSize,
  withMediaCentered,
  actionButtonProps,
}) => {
  return (
    <SC.Container
      id={id}
      className={cx('SoloContentBlock', className)}
      as={htmlTag}
      $withSide={side ? true : false}
      $withTitle={title ? true : false}
    >
      {side && (
        <SC.Side>
          {[...side].map((letter, index) => (
            <span key={`SoloContentBlock-letter-${index}`}>{letter}</span>
          ))}
        </SC.Side>
      )}
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      <SC.Content
        $mediaPosition={mediaPosition}
        $withMediaCentered={withMediaCentered}
      >
        {pictureProps ? (
          <SC.StyledPicture
            {...pictureProps}
            $mediaPosition={mediaPosition}
            $mediaSize={mediaSize}
          />
        ) : videoProps ? (
          <SC.StyledVideo
            {...videoProps}
            $mediaPosition={mediaPosition}
            $mediaSize={mediaSize}
          />
        ) : null}
        {(htmlProps || actionButtonProps) && (
          <SC.Text>
            {htmlProps && <SC.StyledHtml {...htmlProps} />}
            {actionButtonProps && (
              <SC.StyledActionButton
                variant={ActionButtonVariant.secondary}
                {...actionButtonProps}
              />
            )}
          </SC.Text>
        )}
      </SC.Content>
    </SC.Container>
  )
}

export default SoloContentBlock
