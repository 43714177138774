import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'

export const Container = styled.div``
export const StyledPicture = styled(Picture)<{ $widthRoundedCorner?: boolean }>`
  display: block;
  border-radius: ${({ $widthRoundedCorner }) =>
    $widthRoundedCorner === true ? '1rem' : 0};
  overflow: hidden;
  & img {
    display: block;
    width: 100%;
  }
  @media ${mq(sizes.tablet)} {
    border-radius: ${({ $widthRoundedCorner }) =>
      $widthRoundedCorner === true ? '2rem' : 0};
  }
`
export const DesktopPicture = styled(StyledPicture)`
  &:not(:only-child) {
    display: none;
  }
  @media ${mq(sizes.tablet)} {
    &,
    &:not(:only-child) {
      display: block;
    }
  }
`
export const MobilePicture = styled(StyledPicture)`
  display: block;
  @media ${mq(sizes.tablet)} {
    &:not(:only-child) {
      display: none;
    }
  }
`
