import styled from 'styled-components'

import FormFieldTextArea from '../FormFieldTextArea'

export const Input = styled(FormFieldTextArea)`
  width: 100%;
  & input {
    &::placeholder {
      opacity: 1;
      color: ${({ theme }) => theme.colors.bismark};
    }
  }
`
