import * as Yup from 'yup'

import { SoloCapriceValentineGameIntroProps } from '../../../components/molecules/SoloCapriceValentineGame/partial/SoloCapriceValentineGameIntro'
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import { Icons } from '../../../components/atoms/Icon'
import { actions, selectors } from '../../../redux'
import FormFieldCheckbox from '../../../components/form/fields/FormFieldCheckbox'
import { useServiceRequest } from '../../../hooks/useServiceRequest'

export const useCapriceValentineGameIntro =
  (): SoloCapriceValentineGameIntroProps => {
    const [createSession, createSessionHandler] = useServiceRequest(
      selectors.solo.capriceValentineCreateSession,
      actions.solo.capriceValentineCreateSessionRequest,
      actions.solo.capriceValentineCreateSessionReset
    )

    return {
      discover: ['Découvrez votre', '%LOGO', 'idéale, grâce à'],
      ai: ["l'", 'INTELLIGENCE', 'ARTIFICIELLE'],
      win: ['Et tentez de la remporter !'],
      images: [
        {
          width: 210,
          height: 210,
          alt: '',
          images: [
            {
              src: '/static/assets/images/caprice/dinner.png',
              size: 210,
            },
          ],
        },
        {
          width: 210,
          height: 210,
          alt: '',
          images: [
            {
              src: '/static/assets/images/caprice/ski.png',
              size: 210,
            },
          ],
        },
        {
          width: 210,
          height: 210,
          alt: '',
          images: [
            {
              src: '/static/assets/images/caprice/minivan.png',
              size: 210,
            },
          ],
        },
        {
          width: 210,
          height: 210,
          alt: '',
          images: [
            {
              src: '/static/assets/images/caprice/shooting.png',
              size: 210,
            },
          ],
        },
      ],
      form: {
        title: 'Pour participer vous devez accepter les conditions suivantes :',
        formikForm: {
          validateOnChange: true,
          initialValues: {
            rules: false,
            age: false,
          },
          validationSchema: Yup.object().shape({
            rules: Yup.bool().oneOf(
              [true],
              'Veuillez accepter le Règlement du jeu concours'
            ),
            age: Yup.bool().oneOf(
              [true],
              'Veuillez confirmer que vous avez plus de 18 ans'
            ),
          }),
          onSubmit: createSessionHandler,
        },
        fields: [
          {
            name: 'rules',
            Component: FormFieldCheckbox,
            label:
              'J’ai lu et j’accepte le <a href="/reglement-jeu-concours-caprice-des-dieux-ia" target="_blank">Règlement du jeu concours</a>',
            hideErrors: true,
          },
          {
            name: 'age',
            Component: FormFieldCheckbox,
            label: 'J’atteste avoir plus de 18 ans',
            hideErrors: true,
          },
        ],
        submit: {
          isPending: createSession.pending,
          text: 'COMMENCER',
          variant: ActionButtonVariant.red,
          iconPosition: 'right',
          iconProps: {
            icon: createSession.pending ? Icons.refresh : Icons.arrowRight,
          },
        },
      },
    }
  }
