import { FC, Fragment } from 'react'

import {
  SoloHistoryContainerBlock as Data,
  SoloHistoryQuoteBlock,
  SoloHistoryYearCardBlock,
} from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import SoloContentDate from '../../../components/molecules/SoloContentDate'
import SoloContentBlock from '../../../components/molecules/SoloContentBlock'
import { transformWediaVideo, wediaImagesTransform } from '../../Common/wedia'
import SoloContentQuote from '../../../components/molecules/SoloContentQuote'
import SoloContentTitle from '../../../components/molecules/SoloContentTitle'
import { getMediaPosition } from '../../Common/getSoloMediaDetails'

export type SoloHistoryContainerBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

const SoloHistoryContainerBlock: FC<SoloHistoryContainerBlockProps> = ({
  data,
}) => {
  const renderSoloContentBlock = (
    block: SoloHistoryYearCardBlock,
    periodIndex: number,
    index: number
  ) => {
    return (
      <SoloContentBlock
        key={`SoloContentBlock-${periodIndex}-${index}`}
        side={block?.year ?? undefined}
        title={block?.title ?? undefined}
        {...(block?.image
          ? {
              mediaPosition: getMediaPosition(block.bodyPosition),
              pictureProps: {
                alt: block.image.alt ?? ' ',
                maxWidth: 1280,
                width: block.image?.variations?.[0].width ?? undefined,
                height: block.image?.variations?.[0].height ?? undefined,
                withHD: true,
                images: wediaImagesTransform(block.image, true),
              },
            }
          : {})}
        {...(block?.video
          ? {
              mediaPosition: getMediaPosition(block.bodyPosition),
              videoProps: transformWediaVideo(block.video),
            }
          : {})}
        htmlProps={
          block?.body
            ? {
                text: block.body,
              }
            : undefined
        }
      />
    )
  }
  const renderSoloContentQuote = (
    block: SoloHistoryQuoteBlock,
    periodIndex: number,
    index: number
  ) => {
    return (
      <SoloContentQuote
        key={`SoloContentQuote-${periodIndex}-${index}`}
        text={block.quote ?? undefined}
      />
    )
  }

  return data.periodBlocks && data.periodBlocks.length > 0 ? (
    <>
      {data.title && <SoloContentTitle title={data.title} />}
      {data.periodBlocks.map((period, index) => {
        return (
          <Fragment key={`period-${index}`}>
            {period.years && (
              <SoloContentDate title={period.years} id={period.years} />
            )}
            {period.blocks &&
              period.blocks.length > 0 &&
              period.blocks.map((block, i) => {
                switch (block.__typename) {
                  case 'SoloHistoryYearCardBlock':
                    return renderSoloContentBlock(block, index, i)
                  case 'SoloHistoryQuoteBlock':
                    return renderSoloContentQuote(block, index, i)
                  default:
                    return null
                }
              })}
          </Fragment>
        )
      })}
    </>
  ) : null
}

export default SoloHistoryContainerBlock
