import slugify from 'slugify'

import {
  SoloPage,
  SoloSectionTitleBlock,
} from '../../graphql/generated/api-graphql'
import { SoloContentAnchorsProps } from '../../components/molecules/SoloContentAnchors'

const transformSoloContentAnchors = (
  data?: SoloPage
): SoloContentAnchorsProps | undefined => {
  const blocks = data?.blocks?.filter(
    (item) => item.__typename === 'SoloSectionTitleBlock' && item.title
  ) as SoloSectionTitleBlock[] | undefined

  // don't return the menu unless more than 1 item
  return blocks && blocks.length > 1
    ? {
        links: blocks.map((item) => {
          return {
            text: item.title ?? undefined,
            href: `#${item.title ? slugify(item.title) : undefined}`,
          }
        }),
      }
    : undefined
}

export default transformSoloContentAnchors
