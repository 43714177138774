import React, { FC } from 'react'

import * as SC from './styled'

export type BannerProps = MinimalSeoBlock & {
  text?: string
}

const WipBanner: FC<BannerProps> = ({ htmlTag, text }) => {
  return <SC.Banner>{text && <SC.Text as={htmlTag}>{text}</SC.Text>}</SC.Banner>
}

export default WipBanner
