import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type SoloCapriceValentineGameHeaderProps = Pick<
  MinimalSeoBlock,
  'htmlTag'
> & {
  id?: string
  className?: string
}

const SoloCapriceValentineGameHeader: FC<
  SoloCapriceValentineGameHeaderProps
> = ({ id, className }) => {
  return (
    <SC.Container
      id={id}
      className={cx('SoloCapriceValentineGameHeader', className)}
    >
      <SC.Head>
        <div>
          <SC.Leaf src={'/static/assets/images/caprice/caprice-leaf.png'} />
        </div>
        <SC.Logo
          src={'/static/assets/images/caprice/caprice-valentin-logo.png'}
        />
        <div>
          <SC.Heart src={'/static/assets/images/caprice/caprice-heart.svg'} />
        </div>
      </SC.Head>
    </SC.Container>
  )
}

export default SoloCapriceValentineGameHeader
