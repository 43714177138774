import React, { FC } from 'react'
import cx from 'classnames'

import { heart, logo, leaf } from './b64'
import * as SC from './styled'

export type SoloCapriceValentineGameDownloadProps = Pick<
  MinimalSeoBlock,
  'htmlTag'
> & {
  id?: string
  className?: string
  title?: string
  mobileTitle?: string
  text?: string
  tags?: string[]
  contentRef?: React.RefObject<HTMLDivElement>
}

const SoloCapriceValentineGameDownload: FC<
  SoloCapriceValentineGameDownloadProps
> = ({ id, className, title, mobileTitle, text, tags, contentRef }) => {
  return (
    <SC.Container
      id={id}
      className={cx('SoloCapriceValentineGameDownload', className)}
      ref={contentRef}
    >
      <SC.Wrapper>
        <SC.Head>
          <SC.Heart src={heart} />
          <SC.Logo src={logo} />
          <SC.Heart src={heart} />
        </SC.Head>
        <SC.Title>{title}</SC.Title>
        <SC.MobileTitle>{mobileTitle ?? title}</SC.MobileTitle>
        <SC.Message>
          <SC.TextContainer>
            <SC.Text>{text}</SC.Text>
          </SC.TextContainer>
          <SC.Tags>
            {tags?.map((tag, i) => (
              <SC.Tag key={i}>{tag}</SC.Tag>
            ))}
          </SC.Tags>
        </SC.Message>
        <SC.Leaf src={leaf} />
      </SC.Wrapper>
    </SC.Container>
  )
}

export default SoloCapriceValentineGameDownload
