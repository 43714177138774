import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Icon from '../../atoms/Icon'

export const Container = styled.blockquote`
  ${({ theme }) => theme.fonts.PoppinsLight}
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.scoping / 10}rem;
  margin-inline: auto;
  margin-block: 0;
  padding-inline-start: 6rem;
  padding-block: 4rem;
  font-size: 3.2rem;
  line-height: 1.2;
  color: ${({ theme }) => theme.scheme.contentQuote ?? theme.colors.arapawa};
  & p {
    position: relative;
    z-index: 2;
    margin: 0;
  }
  @media ${mq(sizes.desktop)} {
    font-size: 4.8rem;
  }
`
export const Quote = styled(Icon)`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 1;
  width: 9.6rem;
  color: ${({ theme }) => theme.colors.pureWhite};
`
