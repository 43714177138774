import React, { FC, RefObject } from 'react'
import cx from 'classnames'
import Script, { ScriptProps } from 'next/script'

import * as SC from './styled'
import SoloCapriceValentineGameIntro, {
  SoloCapriceValentineGameIntroProps,
} from './partial/SoloCapriceValentineGameIntro'
import SoloCapriceValentineGameError, {
  SoloCapriceValentineGameErrorProps,
} from './partial/SoloCapriceValentineGameError'
import SoloCapriceValentineGameHeader from './partial/SoloCapriceValentineGameHeader'
import SoloCapriceValentineGameInput, {
  SoloCapriceValentineGameInputProps,
} from './partial/SoloCapriceValentineGameInput'
import SoloCapriceValentineGameLoad, {
  SoloCapriceValentineGameLoadProps,
} from './partial/SoloCapriceValentineGameLoad'
import SoloCapriceValentineGameResult, {
  SoloCapriceValentineGameResultProps,
} from './partial/SoloCapriceValentineGameResult'

export type SoloCapriceValentineGameProps = Pick<MinimalSeoBlock, 'htmlTag'> & {
  id?: string
  className?: string
  introProps?: SoloCapriceValentineGameIntroProps
  errorProps?: SoloCapriceValentineGameErrorProps
  inputProps?: SoloCapriceValentineGameInputProps
  loadProps?: SoloCapriceValentineGameLoadProps
  resultProps?: SoloCapriceValentineGameResultProps
  containerRef?: RefObject<HTMLDivElement>
  recaptcha?: ScriptProps
}

const SoloCapriceValentineGame: FC<SoloCapriceValentineGameProps> = ({
  id,
  className,
  introProps,
  errorProps,
  inputProps,
  loadProps,
  resultProps,
  containerRef,
  recaptcha,
}) => {
  return (
    <SC.Container
      id={id}
      className={cx('SoloCapriceValentineGame', className)}
      ref={containerRef}
    >
      {recaptcha && <Script {...recaptcha} />}
      {!introProps && <a id="jcc"></a>}
      <SoloCapriceValentineGameHeader />
      {introProps && <SC.SpecialIntroAnchor id="jcc"></SC.SpecialIntroAnchor>}
      {introProps && <SoloCapriceValentineGameIntro {...introProps} />}
      {errorProps && <SoloCapriceValentineGameError {...errorProps} />}
      {inputProps && <SoloCapriceValentineGameInput {...inputProps} />}
      {loadProps && <SoloCapriceValentineGameLoad {...loadProps} />}
      {resultProps && <SoloCapriceValentineGameResult {...resultProps} />}
    </SC.Container>
  )
}

export default SoloCapriceValentineGame
