/* eslint-disable @next/next/no-sync-scripts */
import { FC, useCallback, useEffect } from 'react'
import useScript from 'src/hooks/useScript'
import { selectors as authSelectors } from 'src/redux/auth/redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'next/navigation'

import { SoloContactFormBlock as Data } from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import SoloContactForm, {
  SoloContactFormProps,
} from '../../../components/molecules/SoloContactForm'

export type SoloContactFormBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

const SoloContactFormBlock: FC<SoloContactFormBlockProps> = ({ data }) => {
  const { t } = useTranslation()

  const props: SoloContactFormProps = {
    title: data.title ?? undefined,
    texts: {
      introduction: data.subTitle ?? undefined,
      mandatory: t('*_mandatory_fields'),
    },
    htmlProps: {
      text: data.legal ?? undefined,
    },
  }

  const loadStatus = useScript(
    'https://savencia.easiwebforms.net/static/shell.js'
  )

  const user = useSelector(authSelectors.user)
  const paramObject = useSearchParams().get('object')

  const personalDataHandler = useCallback(
    (el: HTMLDivElement) => {
      if (!el) return

      // if the object is passed as a query param, add it to the form
      const mailObject = el.querySelector(
        '#fld_CS_Subject'
      ) as HTMLSelectElement
      if (paramObject && mailObject) {
        mailObject.value = paramObject
      }

      // add user values to form if the user is connected
      if (!user) return

      const mailFirstName = el.querySelector(
        '#fld_FirstName'
      ) as HTMLInputElement
      if (mailFirstName) {
        mailFirstName.value = user?.givenName ?? ''
      }

      const mailLastName = el.querySelector('#fld_LastName') as HTMLInputElement
      if (mailLastName) {
        mailLastName.value = user?.familyName ?? ''
      }

      const mailRecipient = el.querySelector('#fld_Email') as HTMLInputElement
      if (mailRecipient) {
        mailRecipient.value = user?.email ?? ''
      }
    },
    [user, paramObject]
  )

  useEffect(() => {
    if (loadStatus === 'ready') {
      ;(window as any).esw.forms.create({
        solutionId: 'bc27aa91c0d711a12fbb59f3e6e10204e75cd51c',
        formId: '3',
        language: 'fr',
        buildinelement: 'bbs-solo-easiform',
        callback: personalDataHandler,
      })
    }
  }, [loadStatus, personalDataHandler])

  return (
    <SoloContactForm {...props}>
      <script
        id="easiwebform-3"
        // eslint-disable-next-line react/no-unknown-property
        charSet="utf-8"
        type="text/javascript"
        src={loadStatus}
      ></script>
      <div id="bbs-solo-easiform" />
    </SoloContactForm>
  )
}

export default SoloContactFormBlock
