import { FC } from 'react'

import { SoloBodyBlock as Data } from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import SoloIntroduction from '../../../components/molecules/SoloIntroduction'

export type SoloBodyBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

const SoloBodyBlock: FC<SoloBodyBlockProps> = ({ data }) => {
  return data.body ? <SoloIntroduction text={data.body} /> : null
}

export default SoloBodyBlock
