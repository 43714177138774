import { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'
import { SoloContentTitleVariant } from './types'

export type SoloContentTitleProps = Pick<MinimalSeoBlock, 'htmlTag'> & {
  id?: string
  className?: string
  title?: string
  variant?: SoloContentTitleVariant
}

const SoloContentTitle: FC<SoloContentTitleProps> = ({
  id,
  className,
  htmlTag,
  title,
  variant = SoloContentTitleVariant.Default,
}) => {
  return title ? (
    <SC.Container
      id={id}
      className={cx('SoloContentTitle', className)}
      as={htmlTag}
      $variant={variant}
    >
      {title}
    </SC.Container>
  ) : null
}

export default SoloContentTitle
