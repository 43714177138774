import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import Html from '../../atoms/Html'
import ActionButton from '../../atoms/ActionButton'

export const Container = styled.section<{
  $reverseOnDesktop?: boolean
}>`
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
  @media ${mq(sizes.tablet)} {
    gap: 4rem;
  }
`
export const Title = styled.h1`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1.5rem;
  margin: 0;
  & strong {
    ${({ theme }) => theme.textStyles.titleH1}
    color: ${({ theme }) => theme.scheme.brandIntroTitleColor}
  }
`
export const SubTitle = styled.h2`
  & span {
    ${({ theme }) => theme.textStyles.titleH2}
    color: ${({ theme }) => theme.scheme.brandIntroSubtitleColor}
  }
  @media ${mq(sizes.tablet)} {
    grid-column-start: content;
    align-self: end;
  }
`

export const Item = styled.div<{
  $reverseOnDesktop?: boolean
}>`
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
  @media ${mq(sizes.tablet)} {
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    grid-template-columns: ${({ $reverseOnDesktop }) =>
      $reverseOnDesktop === true
        ? `[picture] 50% [content] 1fr`
        : `[content] 1fr [picture] 46.875%`};
    align-items: flex-start;
    gap: 1.5rem;
  }
  @media ${mq(sizes.desktop)} {
    gap: 1.5rem 4rem;
  }
`

export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.textLarge}
  @media ${mq(sizes.tablet)} {
    grid-column-start: content;
    align-self: center;
  }
`
export const Action = styled(ActionButton)`
  align-self: flex-start;
  &:not(:focus),
  &:not(:active),
  &:not(:hover) {
    background: transparent;
  }
  @media ${mq(sizes.tablet)} {
    grid-column-start: content;
    justify-self: start;
  }
`
export const StyledPicture = styled(Picture)<{ $pictureRatio?: number }>`
  align-self: center;
  & img {
    display: block;
    width: 100%;
    //max-height: 300px;
  }
  ${({ $pictureRatio }) =>
    $pictureRatio &&
    css`
      aspect-ratio: ${$pictureRatio};
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `}
  @media ${mq(sizes.tablet)} {
    grid-column-start: picture;
    grid-row: 1 / -1;
  }
`
