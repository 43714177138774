import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import Html from '../../atoms/Html'
import ActionButton from '../../atoms/ActionButton'

import { SoloActionBlockTextSize } from './types'

export const Container = styled.section`
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.scoping / 10}rem;
  margin-inline: auto;
`
export const Title = styled.h2`
  ${({ theme }) =>
    theme.scheme.contentActionTextSize === SoloActionBlockTextSize.Small
      ? theme.textStyles.titleH3
      : theme.textStyles.titleH2};
  margin: 0;
  text-align: center;
  color: ${({ theme }) =>
    theme.scheme.contentActionTitle ?? theme.colors.arapawa};
  @media ${mq(sizes.desktop)} {
    ${({ theme }) =>
      theme.scheme.contentActionTextSize === SoloActionBlockTextSize.Small
        ? theme.textStyles.titleH3
        : theme.textStyles.titleH1};
  }
`
export const StyledHtml = styled(Html)`
  ${({ theme }) => theme.textStyles.textLarge}
  &:not(:first-child) {
    margin-top: ${({ theme }) =>
      theme.scheme.contentActionTextSize === SoloActionBlockTextSize.Small
        ? '1.5rem'
        : '1rem'};
  }
  text-align: center;
  color: ${({ theme }) => theme.scheme.contentActionText ?? theme.colors.grey};
  & a {
    color: ${({ theme }) => theme.scheme.HighLightToneColor};
  }
  & b,
  & strong {
    ${({ theme }) => theme.textStyles.textLargeStrong};
  }
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin: 0;
    color: ${({ theme }) =>
      theme.scheme.contentInsideTitle ?? theme.colors.arapawa};
  }
  & h1:not(:first-child),
  & h2:not(:first-child),
  & h3:not(:first-child),
  & h4:not(:first-child),
  & h5:not(:first-child),
  & h6:not(:first-child) {
    margin-block-start: 3rem;
  }
  & h1,
  & h2,
  & h3,
  & h4 {
    ${({ theme }) => theme.textStyles.titleH4};
  }
  & h5,
  & h6 {
    ${({ theme }) => theme.textStyles.titleH5};
  }
  & p:not(:first-child) {
    margin-block-start: 1rem;
  }
  & p:not(:last-child) {
    padding-bottom: 0;
  }
  @media ${mq(sizes.desktop)} {
    ${({ theme }) =>
      theme.scheme.contentActionTextSize === SoloActionBlockTextSize.Small
        ? theme.textStyles.textLarge
        : theme.textStyles.textXXLarge}
    & b,
    & strong {
      ${({ theme }) =>
        theme.scheme.contentActionTextSize === SoloActionBlockTextSize.Small
          ? theme.textStyles.textLargeStrong
          : theme.textStyles.textXXLargeStrong};
    }
    & h1,
    & h2,
    & h3,
    & h4 {
      ${({ theme }) => theme.textStyles.titleH3};
    }
    & h5,
    & h6 {
      ${({ theme }) => theme.textStyles.titleH4};
    }
  }
`
export const StyledActionButton = styled(ActionButton)`
  align-self: center;
  &:not(:first-child) {
    margin-top: 5rem;
  }
`
