import { ContactUsBlockProps } from '../../../components/molecules/ContactUsBlock'
import { Variant } from '../../../components/molecules/ContactUsBlock/types'
import { SoloContactUsBlock } from '../../../graphql/generated/api-graphql'
import Router from '../../../routes/Router'
import routes from '../../../routes/definitions'

export const transformSoloContactUsBlock = (
  t: any,
  contact?: SoloContactUsBlock
): ContactUsBlockProps | undefined => {
  return contact
    ? {
        title: t('brand_contact_title'),
        data: [
          ...(contact?.mail
            ? [
                {
                  variant: Variant.Mail,
                  title: t('brand_contact_mail_title'),
                  entries: [
                    {
                      htmlProps: {
                        text: `<a href="mailto:${contact.mail}">${contact.mail}</a>`,
                      },
                    },
                  ],
                  ...(contact?.contactPageUrl
                    ? {
                        actionButtonProps: {
                          href: Router.getRouteUrl(routes.brand, {
                            slug: contact.contactPageUrl,
                          }),
                          label: t('contact_page_button'),
                        },
                      }
                    : {}),
                },
              ]
            : []),
          ...(contact?.phoneService || contact?.phoneLocal
            ? [
                {
                  variant: Variant.Phone,
                  title: t('brand_contact_phone_title'),
                  entries: [
                    ...(contact?.phoneService
                      ? [
                          {
                            htmlProps: {
                              text: `<a href="tel:${contact?.phoneService}">${contact?.phoneService}</a>`,
                            },
                            note: contact.phoneServiceSubLabel ?? undefined,
                          },
                        ]
                      : []),
                    ...(contact?.phoneLocal
                      ? [
                          {
                            htmlProps: {
                              text: `<a href="tel:${contact?.phoneLocal}">${contact?.phoneLocal}</a>`,
                            },
                            note: contact.phoneLocalSubLabel ?? undefined,
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
          ...(contact?.address
            ? [
                {
                  variant: Variant.Address,
                  title: t('brand_contact_address_title'),
                  entries: [
                    {
                      htmlProps: {
                        text: contact.address.replace(
                          /(?:\r\n|\r|\n)/g,
                          '<br />'
                        ),
                      },
                    },
                  ],
                },
              ]
            : []),
        ],
      }
    : undefined
}
