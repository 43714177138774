import {
  SoloBrandBlock,
  SoloBrandBlockProduct,
  SoloBrandMultiProductItem,
  SoloPage,
} from '../../graphql/generated/api-graphql'
import { SoloAnchorsProps } from '../../components/molecules/SoloAnchors'
import { wediaImagesTransform } from '../Common/wedia'
import { LinkProps } from '../../components/atoms/Link'
import { PictureProps } from '../../components/atoms/Picture'

type SoloAnchorsItem = {
  linkProps: LinkProps
  pictureProps?: PictureProps
  isCurrent?: boolean
}

const transformSoloAnchors = (
  data?: SoloPage
): SoloAnchorsProps | undefined => {
  const block = data?.blocks?.filter(
    (item) => item.__typename === 'SoloBrandBlock'
  )?.[0] as SoloBrandBlock | undefined

  // don't return the menu unless more than 1 item
  return block && block.products && block.products.length > 1
    ? {
        items: block.products.reduce<Array<SoloAnchorsItem>>(
          (
            items: Array<SoloAnchorsItem>,
            brand: SoloBrandBlockProduct,
            index: number
          ): Array<SoloAnchorsItem> => {
            if (brand.__typename === 'SoloBrandProduct') {
              items.push({
                pictureProps: brand.image
                  ? {
                      alt: brand.image?.alt ?? ' ',
                      maxWidth: 100,
                      width: 100,
                      height: 100,
                      withHD: true,
                      images: wediaImagesTransform(brand.image),
                    }
                  : undefined,
                linkProps: {
                  href: `#Brand-${index}`,
                  label: brand.title ?? ' ',
                },
              })
            }

            if (brand.__typename === 'SoloBrandMultiProduct') {
              const product = brand
                .products?.[0] as SoloBrandMultiProductItem | null
              items.push({
                pictureProps: product?.image
                  ? {
                      alt: product?.image?.alt ?? ' ',
                      maxWidth: 100,
                      width: 100,
                      height: 100,
                      withHD: true,
                      images: product
                        ? wediaImagesTransform(product?.image)
                        : [],
                    }
                  : undefined,
                linkProps: {
                  href: `#Brand-${index}`,
                  label: brand.title ?? ' ',
                },
              })
            }
            return items
          },
          []
        ),
      }
    : undefined
}

export default transformSoloAnchors
