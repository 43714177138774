import Head from 'next/head'
import { useRouter } from 'next/router'
import React, {
  DetailedHTMLProps,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  VideoHTMLAttributes,
} from 'react'
import { jsonLdScriptProps } from 'react-schemaorg'
import { VideoObject } from 'schema-dts'

import { Icons } from '../Icon'

import * as SC from './styled'

const players: React.RefObject<HTMLVideoElement>[] = []

export type VideoProps = Omit<
  DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>,
  'ref'
> & {
  jsonLdBasePath?: string
  disableJsonLd?: boolean
  jsonLdInfo?: {
    name?: string
    uploadDate?: string
  }
  className?: string
  sources?: {
    src: string
    type?: string
  }[]
  tracks?: {
    src: string
    srcLang?: string
    kind?:
      | 'subtitles'
      | 'captions'
      | 'descriptions'
      | 'chapters'
      | 'metadata'
      | string
    label?: string
  }[]
  poster?: string
  posterAlt?: string
}

const Video = React.forwardRef<HTMLVideoElement, VideoProps>((props, ref) => {
  const {
    className,
    tracks,
    poster,
    sources,
    controls,
    key,
    disableJsonLd,
    jsonLdBasePath,
    jsonLdInfo,
    ...options
  } = props

  const internalRef = useRef<HTMLVideoElement | null>(null)
  useImperativeHandle<HTMLVideoElement | null, HTMLVideoElement | null>(
    ref,
    () => internalRef.current
  )
  const sureKey = `${key}-${JSON.stringify(sources?.[0] ?? sources)}`
  const router = useRouter()
  const [clicked, setClicked] = useState(false)
  const thumbnailUrl = poster
    ? poster?.match(/^http(s*):/)
      ? poster
      : jsonLdBasePath && jsonLdBasePath + poster
    : sources?.[0].src

  const onClick = useCallback(() => {
    internalRef?.current?.play?.()
    setClicked(true)
  }, [])

  const resetVideo = useCallback(() => {
    if (internalRef?.current) {
      internalRef.current.pause()
      internalRef.current.currentTime = 0
    }
    setClicked(false)
  }, [])

  const handlePlay = useCallback(
    (e: React.SyntheticEvent<HTMLVideoElement>) => {
      players
        ?.filter((p) => p?.current !== internalRef?.current)
        ?.forEach((p) => p?.current?.pause())
      options?.onPlay?.(e)
      setClicked(true)
    },
    [options]
  )

  useEffect(() => {
    players.push(internalRef)
  }, [])

  // stop the video on route change
  useEffect(() => {
    if (!router) return

    router?.events.on('routeChangeStart', resetVideo)
    return () => {
      router?.events.off('routeChangeStart', resetVideo)
    }
  }, [router, resetVideo])

  return sources && sources.length ? (
    <SC.Container className={className} key={sureKey}>
      {!disableJsonLd && (
        <Head>
          <script
            {...jsonLdScriptProps<VideoObject>({
              '@context': 'https://schema.org',
              '@type': 'VideoObject',
              name: jsonLdInfo?.name ?? 'Video',
              contentUrl: sources[0].src,
              // mandatory so fake it if not provided
              uploadDate: jsonLdInfo?.uploadDate ?? new Date().toISOString(),
              thumbnailUrl,
            })}
          />
        </Head>
      )}
      <SC.Video
        ref={internalRef}
        onPlay={handlePlay}
        {...options}
        poster={poster}
        controls={clicked && controls}
        tabIndex={!clicked ? -1 : undefined}
      >
        {sources.map((source, index) => (
          <source {...source} key={`Video-source-${index}`} />
        ))}
        {tracks?.map((track, index) => (
          <track {...track} key={`Video-track-${index}`} />
        ))}
      </SC.Video>
      {!clicked && (
        <SC.PlayButton onClick={onClick}>
          <SC.Play icon={Icons.play} />
        </SC.PlayButton>
      )}
    </SC.Container>
  ) : null
})

Video.displayName = 'Video'

export default Video
