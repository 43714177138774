import React, { FC } from 'react'
import cx from 'classnames'

import SoloCapriceValentineGameStepper, {
  SoloCapriceValentineGameStepperProps,
} from '../SoloCapriceValentineGameStepper'
import { FormikFormProps } from '../../../../form/FormikForm'
import { FormikFieldProps } from '../../../../form/FormikField'
import { FormSubmitButtonProps } from '../../../../form/FormSubmitButton'
import { ActionButtonProps } from '../../../../atoms/ActionButton'
import SoloCapriceValentineGameSuggestionsModal, {
  SoloCapriceValentineGameSuggestionsModalProps,
} from '../SoloCapriceValentineGameSuggestionsModal'

import * as SC from './styled'

export type SoloCapriceValentineGameInputProps = Pick<
  MinimalSeoBlock,
  'htmlTag'
> & {
  id?: string
  className?: string
  stepperProps?: SoloCapriceValentineGameStepperProps
  title?: string
  help?: ActionButtonProps
  formProps: FormikFormProps
  textarea: FormikFieldProps
  submit: FormSubmitButtonProps
  error?: string
  advice?: {
    title?: string
    text?: string
  }
  suggestionsProps?: SoloCapriceValentineGameSuggestionsModalProps
}

const SoloCapriceValentineGameInput: FC<SoloCapriceValentineGameInputProps> = ({
  id,
  className,
  title,
  formProps,
  textarea,
  submit,
  help,
  stepperProps,
  advice,
  error,
  suggestionsProps,
}) => {
  return (
    <SC.Container
      id={id}
      className={cx('SoloCapriceValentineGameInput', className)}
    >
      <SC.Wrapper>
        {stepperProps && <SoloCapriceValentineGameStepper {...stepperProps} />}
        <SC.Title>{title}</SC.Title>
        <SC.Form {...formProps}>
          {(formikProps) => {
            return (
              <>
                <SC.FieldContainer>
                  <SC.Field {...textarea} error={null} />
                  {help && <SC.Help {...help} type={'button'} />}
                </SC.FieldContainer>
                {(formikProps.errors?.prompt || error) && (
                  <SC.Error>
                    {typeof formikProps.errors?.prompt === 'object'
                      ? (formikProps.errors?.prompt as string[])?.[0]
                      : (formikProps.errors?.prompt as string) || error}
                  </SC.Error>
                )}
                <SC.Submit {...submit} />
              </>
            )
          }}
        </SC.Form>
        {advice && (
          <SC.Advice>
            <SC.AdviceTitle>{advice.title}</SC.AdviceTitle>
            <SC.AdviceText>{advice.text}</SC.AdviceText>
          </SC.Advice>
        )}
      </SC.Wrapper>
      {suggestionsProps && (
        <SoloCapriceValentineGameSuggestionsModal {...suggestionsProps} />
      )}
    </SC.Container>
  )
}

export default SoloCapriceValentineGameInput
