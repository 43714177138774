import { FC } from 'react'
import cx from 'classnames'

import { HtmlProps } from '../../atoms/Html'

import * as SC from './styled'

export type SoloIntroductionProps = Pick<MinimalSeoBlock, 'htmlTag'> &
  HtmlProps & {
    className?: string
  }

const SoloIntroduction: FC<SoloIntroductionProps> = ({
  className,
  htmlTag,
  ...htmlProps
}) => {
  return htmlProps.text || htmlProps.children ? (
    <SC.Container
      className={cx('SoloIntroduction', className)}
      htmlTag={htmlTag}
      {...htmlProps}
    />
  ) : null
}

export default SoloIntroduction
