import styled from 'styled-components'

import { mq, sizes } from '../../../../../theme'

export const Container = styled.div``

export const Head = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem;
  @media ${mq(sizes.desktop)} {
    padding: 1.5rem 8rem;
  }
  & div {
    width: 25%;
    &:last-of-type {
      text-align: right;
    }
  }
`
export const Leaf = styled.img`
  width: 64%;
  @media ${mq(sizes.desktop)} {
    width: 14rem;
  }
`
export const Logo = styled.img`
  width: 50%;
  max-width: 35rem;
`
export const Heart = styled.img`
  width: 60%;
  @media ${mq(sizes.desktop)} {
    width: 12rem;
  }
`
