import styled, { css } from 'styled-components'

import { fonts, mq, sizes } from '../../../../../theme'
import Html from '../../../../atoms/Html'
import ActionButton from '../../../../atoms/ActionButton'
import FormSubmitButton from '../../../../form/FormSubmitButton'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem 5rem;
  @media ${mq(sizes.desktop)} {
    padding: 0 8rem 5rem;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-width: 91rem;
`

export const Title = styled(Html)`
  ${fonts.PoppinsBold}
  color: ${({ theme }) => theme.colors.pureWhite};
  text-align: center;
  font-size: 2.4rem;
  @media ${mq(sizes.desktop)} {
    font-size: 3rem;
  }
`

export const Message = styled.div`
  background: ${({ theme }) => theme.colors.pureWhite};
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
`

export const Text = styled.div`
  ${({ theme }) => theme.textStyles.titleH5};
  color: ${({ theme }) => theme.colors.capriceDark};
  text-align: center;
`
export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`
export const Tag = styled.div`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.capriceDark};
`

export const Offscreen = styled.div`
  position: fixed;
  transform: translate(-100%, -100%);
  top: 0;
  left: 0;
`

export const Info = styled(Html)`
  border-radius: 1rem;
  background-color: #0067b2;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const Participate = styled.div`
  border-radius: 1rem;
  background-color: #0067b2;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.pureWhite};
`
export const ParticipateTitle = styled.div`
  ${({ theme }) => theme.textStyles.titleH3};
  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.titleH2};
  }
`
export const ParticipateText = styled(Html)`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.pureWhite};
  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.textLargeStrong};
  }
`
export const ParticipateButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  @media ${mq(sizes.desktop)} {
    flex-direction: row;
  }
`

export const Restart = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`
export const Button = styled(ActionButton)``
export const RestartButton = styled(ActionButton)`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed !important;
    `};
  & svg {
    color: ${({ theme }) => theme.colors.error};
  }
`
export const DownloadButton = styled(FormSubmitButton)``
export const RestartLabel = styled.div`
  color: ${({ theme }) => theme.colors.pureWhite};
  display: flex;
  gap: 1rem;
  text-align: center;
  align-items: center;
  flex-direction: column;
  & svg {
    background: ${({ theme }) => theme.colors.capriceMedium};
    border-radius: 50%;
    width: 2.4rem;
    height: 2.4rem;
    padding: 0.4rem;
  }
  & span {
    ${({ theme }) => theme.textStyles.textMedium};
  }
  @media ${mq(sizes.desktop)} {
    flex-direction: row;
  }
`
export const Download = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`
export const DownloadLabel = styled.div`
  ${({ theme }) => theme.textStyles.textLarge};
  text-align: center;
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const Footer = styled.div`
  background: #1d78bb;
  padding: 2.4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  @media ${mq(sizes.desktop)} {
    flex-direction: row;
    gap: 2.4rem;
    align-items: initial;
  }
`

export const Divider = styled.div`
  width: 20rem;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.capriceMedium};
  @media ${mq(sizes.desktop)} {
    width: 1px;
    height: auto;
  }
`

export const DownloadContainer = styled.div`
  position: absolute;
  left: -100%;
`
