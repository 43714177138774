import styled from 'styled-components'

import ActionButton from '../../../../atoms/ActionButton'
import { fonts, mq, sizes } from '../../../../../theme'
import FormikForm from '../../../../form/FormikForm'
import FormSubmitButton from '../../../../form/FormSubmitButton'
import FormikField from '../../../../form/FormikField'
import FormMessages from '../../../../form/FormMessages'
import Picture from '../../../../atoms/Picture'

export const Container = styled.div`
  padding: 0 0 8rem;
  @media ${mq(sizes.desktop)} {
    padding: 0 0 16.8rem;
  }
`

export const Title = styled.div`
  padding: 2.5rem 1.5rem 3rem;
  text-align: center;
  display: flex;
  flex-flow: wrap;
  align-items: flex-end;
  justify-content: center;
  line-height: 1.1;
  @media ${mq(sizes.tablet)} {
    line-height: initial;
  }
  @media ${mq(sizes.desktop)} {
    padding: 3rem 1.5rem 4rem;
  }
`
export const MobileSplit = styled.div`
  width: 100%;
  @media ${mq(sizes.desktop)} {
    display: none;
  }
`
export const Split = styled.div`
  width: 100%;
`
export const Capital = styled.div`
  ${fonts.SuperclarendonRegular}
  color: #015196;
  text-shadow: 0 2.156px 8.086px #015196;
  font-size: 4.6rem;
  @media ${mq(sizes.desktop)} {
    font-size: 6.5rem;
  }
`
export const Text2 = styled.div`
  ${fonts.Baloo2SemiBold}
  color: ${({ theme }) => theme.colors.pureWhite};
  text-shadow: 0 2.156px 8.086px #015196;
  font-size: 4rem;
  padding: 0 0.8rem 0 0;
  line-height: 120%;
  @media ${mq(sizes.desktop)} {
    font-size: 5.8rem;
  }
`
export const Text = styled.div`
  ${fonts.Baloo2SemiBold}
  color: ${({ theme }) => theme.colors.pureWhite};
  text-shadow: 0 2.156px 8.086px #015196;
  font-size: 2.7rem;
  padding: 0 0.25rem;
  @media ${mq(sizes.desktop)} {
    font-size: 4rem;
  }
`
export const Valentin = styled.img`
  width: 30rem;
  @media ${mq(sizes.desktop)} {
    width: 41rem;
  }
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 1.5rem;
`
export const FormTitle = styled.div`
  ${fonts.WorkSansRegular}
  color: #F2F9FD;
  font-size: 1.8rem;
  line-height: 150%;
  padding-bottom: 2rem;
  @media ${mq(sizes.desktop)} {
    font-size: 2rem;
  }
`

export const Fields = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  @media ${mq(sizes.desktop)} {
    flex-flow: wrap;
    flex-direction: row;
  }
`

export const Errors = styled(FormMessages)`
  background-color: #fff;
`
export const Field = styled(FormikField)`
  & label p {
    ${fonts.WorkSansRegular}
    color: #F2F9FD;
    font-size: 1.6rem;
    line-height: 150%;
    text-align: left;
    & a {
      color: #f2f9fd;
      display: block;
      @media ${mq(sizes.desktop)} {
        display: inline-block;
      }
    }
  }
  & svg {
    border-color: #f2f9fd;
  }
  & [aria-invalid='true'] ~ svg {
    border-color: ${({ theme }) => theme.colors.error};
  }
`
export const Formik = styled(FormikForm)``
export const Submit = styled(FormSubmitButton)`
  margin-top: 3rem;
`

export const Actions = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 2rem 1.5rem 0;
  flex-direction: column;
  @media ${mq(sizes.desktop)} {
    flex-direction: row;
  }
`

export const Button = styled(ActionButton)``
export const Images = styled.div`
  display: grid;
  grid-template-columns: 14.5rem 14.5rem;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 4rem 1.5rem 0;
  @media ${mq(sizes.desktop)} {
    display: flex;
    flex-direction: row;
  }
`

export const Image = styled(Picture)`
  align-items: center;
  display: flex;
  justify-content: center;
  & img {
    width: 14.5rem;
    height: 14.5rem;
  }
  @media ${mq(sizes.desktop)} {
    & img {
      width: 21rem;
      height: 21rem;
    }
  }
`
