import styled from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'

import { mq, sizes } from '../../../theme'
import Link from '../../atoms/Link'

export const Container = styled.div`
  position: relative;
`

export const Scroller = styled(ScrollContainer)<{ $scrollable?: boolean }>`
  --cursor--wrapper: ${({ $scrollable }) =>
    $scrollable === true ? 'grab' : 'initial'};
  --cursor--link: ${({ $scrollable }) =>
    $scrollable === true ? 'inherit' : 'pointer'};
  cursor: var(--cursor--wrapper);
`

export const Links = styled.ul`
  list-style: none;
  display: flex;
  gap: 2.2rem;
  width: max-content;
  padding: 0;
  margin: 0 auto;
  padding-inline: 1.5rem;
`

export const StyledLink = styled(Link)`
  ${({ theme }) => theme.textStyles.titleH7}
  height: 4.2rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.8rem;
  padding: 0.6rem 1.2rem;
  border-radius: 3.5rem;
  border: 0.1rem solid
    ${({ theme }) => theme.scheme.contentAnchorsBorder ?? theme.colors.arapawa};
  white-space: nowrap;
  color: ${({ theme }) =>
    theme.scheme.contentAnchorsColor ?? theme.colors.arapawa};
  background-color: ${({ theme }) =>
    theme.scheme.contentAnchorsBackground ?? 'transparent'};
  transition: all 200ms ease-in-out;
  cursor: var(--cursor--link);
  &[aria-current] {
    cursor: default;
  }
  &:hover,
  &:active,
  &[aria-current] {
    border-color: ${({ theme }) =>
      theme.scheme.contentAnchorsBorderActive ?? theme.colors.arapawa};
    color: ${({ theme }) =>
      theme.scheme.contentAnchorsColorActive ?? theme.colors.pureWhite};
    background-color: ${({ theme }) =>
      theme.scheme.contentAnchorsBackgroundActive ?? theme.colors.arapawa};
  }
  @media ${mq(sizes.tablet)} {
  }
`
