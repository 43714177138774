import tracking from 'src/tracking'

import { Brand, Tag } from '../../graphql/generated/api-graphql'
import { CardsBlockProps } from '../../components/molecules/CardsBlock'
import { Icons } from '../../components/atoms/Icon'
import { transformTagForCloud } from '../Common/tag'
import { transformRecipeTinyCard } from '../RecipeCard/transformRecipeTinyCard'
import Router, { routes } from '../../routes/Router'
import { SearchType } from '../SearchEngine/searchType'

export const brandRecipesTransformer = (
  t: any,
  data?: Brand,
  tags?: Tag[]
): CardsBlockProps | undefined =>
  data?.brandRecipes && data?.brandRecipes?.length > 0
    ? {
        title: t('brand_recipes_title'),
        tags: tags?.map((tag: Tag) => transformTagForCloud(tag, data.name)),
        cards: data?.brandRecipes?.map((item) =>
          transformRecipeTinyCard(item, t('brand_recipes_title'))
        ),
        actionButtonProps: {
          label: t('more_recipes'),
          iconPosition: 'right',
          iconProps: {
            icon: Icons.arrowRight,
          },
          href: Router.getRouteUrl(routes.searchType, {
            type: SearchType.Recipe,
            marques: data.id,
          }),
          onClick: () =>
            tracking.cta(t('more_recipes'), t('brand_recipes_title')),
        },
      }
    : undefined
