import styled from 'styled-components'

import { fonts, mq, sizes } from '../../../../../theme'
import Html from '../../../../atoms/Html'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem 8rem;
  @media ${mq(sizes.desktop)} {
    padding: 0 8rem 16.8rem;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-width: 91rem;
  align-items: center;
  text-align: center;
`

export const Title = styled(Html)`
  ${fonts.TartineScriptBold}
  color: ${({ theme }) => theme.colors.pureWhite};
  text-align: center;
  font-size: 3.2rem;
  padding: 4rem 0 3rem;
  line-height: 120%;
  @media ${mq(sizes.desktop)} {
    font-size: 4rem;
  }
`

export const Text = styled(Html)`
  ${fonts.PoppinsBold}
  color: ${({ theme }) => theme.colors.pureWhite};
  text-shadow: 0 2.156px 8.086px #015196;
  font-size: 2.4rem;
  padding: 0 1.5rem;
  @media ${mq(sizes.desktop)} {
    font-size: 3rem;
    padding: 0 8rem;
  }
`

export const Loader = styled.div`
  padding: 6rem 0;
  width: 6rem;
  color: ${({ theme }) => theme.colors.pureWhite};
  & svg {
    width: 100%;
  }
  @media ${mq(sizes.desktop)} {
    width: 8rem;
    padding: 8rem 0;
  }
`
