// this index has been kept in case the client want to use the banner inside content
// css not ready for it though

import { FC } from 'react'

import { SoloBannerBlock as Data } from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import SoloBanner, {
  SoloBannerProps,
} from '../../../components/molecules/SoloBanner'

import transformSoloBannerBlock from './transformer'

export type SoloBannerBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}
const SoloBannerBlock: FC<SoloBannerBlockProps> = ({ data }) => {
  const props: SoloBannerProps | undefined = transformSoloBannerBlock(data)

  return props ? <SoloBanner {...props} /> : null
}

export default SoloBannerBlock
