import styled from 'styled-components'

import { mq, sizes } from '../../../theme'

export const Container = styled.h2`
  ${({ theme }) => theme.fonts.PoppinsLight}
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.scoping / 10}rem;
  margin-inline: auto;
  margin-block: 0;
  padding-block-start: 1rem;
  font-size: 4.8rem;
  line-height: 1.2;
  color: ${({ theme }) =>
    theme.scheme.contentDateTitle ?? theme.colors.arapawa};
  text-align: center;
  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 6.4rem;
    height: 0.2rem;
    background-color: currentColor;
    transform: translateX(-50%);
  }
  @media ${mq(sizes.desktop)} {
    font-size: 9.6rem;
  }
`
