import styled from 'styled-components'

import Icon from '../../atoms/Icon'
import Link from '../../atoms/Link'
import { mq, sizes } from '../../../theme'

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4rem;
  padding: 3rem 2rem;
  border-radius: 2rem;
  background: ${({ theme }) => theme.colors.pureWhite};
  @media ${mq(sizes.tablet)} {
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 4rem;
  }
  @media ${mq(sizes.scoping)} {
    gap: 16rem;
  }
`
export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3}
  margin: 0;
  text-align: center;
  color: ${({ theme }) =>
    theme.scheme.socialsTitleColor ?? theme.colors.arapawa};
  @media ${mq(sizes.tablet)} {
    text-align: left;
  }
`
export const Socials = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  gap: 4rem;
  padding: 0;
  margin: 0;
  @media ${mq(sizes.tablet)} {
    grid-template-columns: auto;
    grid-auto-flow: column;
  }
`
export const Social = styled.li``
export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6.4rem;
  width: 6.4rem;
  border-radius: 50%;
  color: ${({ theme }) => theme.scheme.socialsColor};
  background: ${({ theme }) => theme.scheme.socialsFill};
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
  & > span {
    ${({ theme }) => theme.textStyles.accessibleHiddenText}
  }
  &:active,
  &:focus,
  &:hover {
    background: ${({ theme }) => theme.scheme.socialsFillActive};
  }
`
export const StyledIcon = styled(Icon)``
