import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect } from 'react'
import { call } from 'redux-saga/effects'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'

import { imageMocks } from '../../../components/atoms/Picture/mocks'
import { formikFormMocks } from '../../../components/form/FormikForm/mock'
import { formikFieldMocks } from '../../../components/form/FormikField/mocks'
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import { Icons } from '../../../components/atoms/Icon'
import { LightAuthRegisterModalProps } from '../../../components/molecules/LightAuthModal/LightAuthRegisterModal'
import FormFieldCheckbox from '../../../components/form/fields/FormFieldCheckbox'
import { useServiceRequest } from '../../../hooks/useServiceRequest'
import { actions, selectors } from '../../../redux'
import reduxStore from '../../../redux/store'
import ApiSagas from '../../../redux/api/sagas'
import { dqeCheckMail } from '../../../graphql/Services/Member/queries'
import {
  MemberAcquisitionChannel,
  MemberGender,
  MemberRegisterInput,
} from '../../../graphql/generated/api-graphql'
import FormFieldInput from '../../../components/form/fields/FormFieldInput'
import FormFieldPhone from '../../../components/form/fields/FormFieldPhone'
import FormFieldRadioGroup from '../../../components/form/fields/FormFieldRadioGroup'
import {
  PASSWORD_MIN_LENGTH,
  PASSWORD_REGEX,
  getPasswordInputPropsForRegister,
  getPasswordRules,
} from '../../Common/passwordRules'
import FormFieldPassword from '../../../components/form/fields/FormFieldPassword'

export const useCapriceValentineGameRegister = (
  isOpen: boolean,
  closeHandler: () => void
): LightAuthRegisterModalProps => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [register, registerHandler] = useServiceRequest(
    selectors.member.memberRegister,
    actions.member.memberRegisterRequest,
    null
  )

  useEffect(() => {
    return () => {
      dispatch(actions.member.memberRegisterReset())
    }
  }, [dispatch, isOpen])

  const handleCheckMail = useCallback((v?: string): Promise<boolean> => {
    if (!v || !Yup.string().email().isValidSync(v)) {
      return Promise.resolve(false)
    }
    return reduxStore.sagaMiddleware
      .run(function* fn() {
        const rs: any = yield call(ApiSagas.query, dqeCheckMail, { email: v })
        return rs
      })
      .toPromise()
      .then((result) => {
        return result?.data?.in
      })
  }, [])

  return {
    isOpen,
    closeHandler,
    logoProps: {
      ...imageMocks,
      images: [
        {
          src: '/static/assets/images/caprice/caprice-logo.png',
          size: 400,
        },
      ],
    },
    title: "Je m'inscris pour jouer",
    formikForm: {
      ...formikFormMocks.basic,
      initialValues: {
        gender: '',
        firstName: '',
        lastName: '',
        birthDate: '',
        phoneNumber: '',
        email: '',
        password: '',
        confirm: '',
        rules: false,
      },
      validationSchema: Yup.object().shape({
        gender: Yup.string().required(t('this_field_is_required')),
        firstName: Yup.string().required(t('this_field_is_required')),
        lastName: Yup.string().required(t('this_field_is_required')),
        birthDate: Yup.date()
          .max(dayjs().subtract(16, 'year'), t('signup_age_limit'))
          .required(t('this_field_is_required')),
        email: Yup.string()
          .required(t('email_is_invalid'))
          .email(t('please_fill_the_email'))
          .test(
            'checkEmail',
            t('email_is_invalid'),
            async (value) => await handleCheckMail(value)
          ),
        password: Yup.string()
          .required(t('this_field_is_required'))
          .min(PASSWORD_MIN_LENGTH, t('signup_password_length'))
          .matches(PASSWORD_REGEX, t('signup_password_complexity')),
        confirm: Yup.string()
          .required(t('this_field_is_required'))
          .oneOf(
            [Yup.ref('password'), null],
            t('signup_password_failed_confirmation')
          ),
        rules: Yup.bool().oneOf(
          [true],
          'Veuillez accepter le traitement des données'
        ),
      }),
      onSubmit: (values) =>
        registerHandler({
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            birthDate: values.birthDate,
            phoneNumber: values.phoneNumber,
            gender: values.gender,
            acquisitionChannel: MemberAcquisitionChannel.GameContest,
            email: values.email,
            password: values.password,
          } as MemberRegisterInput,
        }),
    },
    errors:
      register.errors && register.errors.length > 0
        ? {
            messages: register.errors,
          }
        : undefined,
    fields: {
      gender: {
        Component: FormFieldRadioGroup,
        name: 'gender',
        label: '',
        options: [
          { label: 'Madame', value: MemberGender.Female },
          { label: 'Monsieur', value: MemberGender.Male },
        ],
      },
      firstName: {
        ...formikFieldMocks.basic,
        name: 'firstName',
        label: 'Prénom',
        required: true,
        placeholder: '',
      },
      lastName: {
        ...formikFieldMocks.basic,
        name: 'lastName',
        label: 'Nom',
        required: true,
        placeholder: '',
      },
      birthDate: {
        Component: FormFieldInput,
        type: 'date',
        name: 'birthDate',
        label: 'Date de naissance',
        helpText: "Vous devez être agé(e) d'au moins 16 ans.",
        required: true,
        max: dayjs().subtract(16, 'years').format('YYYY-MM-DD'),
        placeholder: '',
      },
      phoneNumber: {
        name: 'phoneNumber',
        label: 'Numéro de téléphone',
        placeholder: '',
        Component: FormFieldPhone,
        selectName: 'phonePrefix',
        countries: [
          {
            value: '+33',
            prefix: '+33',
          },
          {
            value: '+32',
            prefix: '+32',
          },
          {
            value: '+41',
            prefix: '+41',
          },
          { value: '+352', prefix: '+352' },
          { value: '+1', prefix: '+1' },
          { value: '+262', prefix: '+262' },
          { value: '+508', prefix: '+508' },
          { value: '+590', prefix: '+590' },
          { value: '+594', prefix: '+594' },
          { value: '+596', prefix: '+596' },
        ],
        // mask: '9 99 99 99 99',
        // maskPlaceholder: '_',
      },
      email: {
        ...formikFieldMocks.basic,
        name: 'email',
        label: 'Email',
        required: true,
        placeholder: '',
      },
      password: {
        Component: FormFieldPassword,
        type: 'password',
        name: 'password',
        label: 'Mot de passe',
        required: true,
        placeholder: '',
        rules: getPasswordRules(t),
        inputProps: {
          ...getPasswordInputPropsForRegister(),
        },
      },
      confirm: {
        Component: FormFieldPassword,
        type: 'password',
        name: 'confirm',
        label: 'Confirmez le mot de passe',
        required: true,
        placeholder: '',
        inputProps: {
          ...getPasswordInputPropsForRegister(),
        },
      },
      rules: {
        name: 'rules',
        Component: FormFieldCheckbox,
        label:
          'Je consens à ce que mes données personnelles soient traitées par Qui Veut Du Fromage. <a href="/nf/privacy" target="_blank">En savoir plus sur mes droits</a>',
      },
    },
    submitButton: {
      isPending: register?.pending,
      text: register?.pending ? 'Validation...' : 'Valider',
      variant: ActionButtonVariant.secondary,
      iconPosition: 'right',
      iconProps: {
        icon: register?.pending ? Icons.refresh : Icons.arrowRight,
      },
    },
  }
}
