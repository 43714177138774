import styled from 'styled-components'

import ActionButton from '../../atoms/ActionButton'
import VideoContent from '../../atoms/Video'
import { mq, sizes } from '../../../theme'
import Html from '../../atoms/Html'
import { Play } from '../../atoms/Video/styled'

export const Container = styled.section`
  display: flex;
  flex-flow: column;
  gap: 2rem;
  @media ${mq(sizes.desktop)} {
    gap: 3rem;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH1}
  color: ${({ theme }) => theme.scheme.newsVideoTitle ?? theme.colors.arapawa};
  margin: 0;
  text-align: center;
`
export const Wrapper = styled.div`
  border-radius: 2rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.pureWhite};
`
export const StyledVideo = styled(VideoContent)`
  & ${Play} {
    color: ${({ theme }) => theme.scheme.HighLightToneColor};
  }
`
export const Content = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
  padding: 3rem 2rem;
  @media ${mq(sizes.desktop)} {
    padding: 3rem 5rem;
  }
`
export const VideoTitle = styled.h3`
  ${({ theme }) => theme.textStyles.titleH3}
  color: ${({ theme }) => theme.colors.arapawa};
  margin: 0;
`
export const Text = styled(Html)`
  ${({ theme }) => theme.textStyles.textSmall}
  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.textLarge}
  }
`
export const Action = styled(ActionButton)`
  align-self: flex-start;
`
