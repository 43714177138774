import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SoloVideosSliderBlock as Data } from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import { transformWediaVideo } from '../../Common/wedia'
import VideoListBlock, {
  VideoListBlockProps,
} from '../../../components/molecules/VideoListBlock'
import { ctaBlockToActionButtonProps } from '../../Common/ctaBlockToActionButtonProps'
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import tracking from '../../../tracking'

export type SoloVideosSliderBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

const SoloVideosSliderBlock: FC<SoloVideosSliderBlockProps> = ({ data }) => {
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState<number | undefined>(0)
  const props: VideoListBlockProps = {
    title: data?.title ?? '',
    littleTitle: data?.subTitle ?? '',
    videoListProps: {
      playingLabel: t('playing'),
      activeIndex,
      onClick: setActiveIndex,
      videoList: data?.videoBlocks?.map((video) => ({
        video: transformWediaVideo(video?.video, { image: video?.videoPoster }),
        title: video?.title ?? '',
        ...(video?.ctaBlock?.href && {
          actionButtonProps: {
            ...ctaBlockToActionButtonProps(video?.ctaBlock),
            variant: ActionButtonVariant.secondary,
            onClick: () =>
              tracking.cta(
                video?.ctaBlock?.label ?? undefined,
                video?.title ?? undefined
              ),
          },
        }),
        htmlProps: {
          text: video.videoDescription ?? '',
        },
      })),
    },
  }

  return <VideoListBlock {...props} />
}

export default SoloVideosSliderBlock
