import { FC } from 'react'
import cx from 'classnames'

import { AllFiltersFormProps } from '../../forms/AllCheesesFiltersForm'
import { CardsBlockProps } from '../CardsBlock'
import { PaginationProps } from '../Pagination'

import * as SC from './styled'

export type SoloRecipesProps = Pick<MinimalSeoBlock, 'htmlTag'> & {
  className?: string
  allFiltersFormProps?: AllFiltersFormProps
  cardsBlockProps?: CardsBlockProps
  paginationProps?: PaginationProps
}

const SoloRecipes: FC<SoloRecipesProps> = ({
  className,
  htmlTag,
  allFiltersFormProps,
  cardsBlockProps,
  paginationProps,
}) => {
  return (
    <SC.Container className={cx('SoloPushImage', className)} as={htmlTag}>
      {allFiltersFormProps && (
        <SC.StyledAllFiltersForm {...allFiltersFormProps} />
      )}
      {cardsBlockProps && (
        <SC.StyledCardsBlock
          {...cardsBlockProps}
          actionButtonProps={
            paginationProps ? undefined : cardsBlockProps.actionButtonProps
          }
        />
      )}
      {paginationProps && <SC.StyledPagination {...paginationProps} />}
    </SC.Container>
  )
}

export default SoloRecipes
