import styled from 'styled-components'

import Html from '../../atoms/Html'
import { mq, sizes } from '../../../theme'

export const Container = styled.section`
  min-height: 20rem;

  // overrides easiform
  #easiform {
    @media ${mq(sizes.mobile)} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }

    & #easi_fielddiv_Description {
      @media ${mq(sizes.mobile)} {
        grid-column: 3/1;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;

      & .mandatory {
        display: none;
      }

      & label {
        ${({ theme }) => theme.textStyles.textSmallStrong};
        color: ${(props) => props.theme.colors.bismark};

        &::after {
          content: ' *';
        }
      }

      & input,
      & select,
      & textarea {
        width: 100%;
        border-radius: 0.6rem;
        border: 1px solid ${(props) => props.theme.colors.pattensBlue};
        margin-top: 0.5rem;
        &:focus {
          outline: none;
        }
      }

      & input,
      & select {
        height: 5rem;
      }
      & textarea {
        resize: vertical;
        min-height: 5rem;
      }

      & textarea {
        height: 16rem;
      }
    }
  }

  #mandatoryfieldmention {
    display: none;
  }

  #validatebutton {
    margin: 3rem auto 0 auto;
    display: flex;
    ${({ theme }) => theme.textStyles.titleH7}
    color: ${(props) => props.theme.colors.pureWhite};
    padding: 1.75rem 1.6rem;
    border-radius: 1rem;
    border: 1px solid ${(props) => props.theme.colors.arapawa};
    background-color: ${(props) => props.theme.colors.arapawa};
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;

    &:hover {
      color: ${(props) => props.theme.colors.arapawa};
      background-color: ${(props) => props.theme.colors.pureWhite};
    }
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH2}
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.scheme.contactTitle ?? theme.colors.arapawa};
  &:not(:last-child) {
    padding-block-end: 3rem;
    margin-block-end: 3rem;
    border-block-end: 1px solid ${({ theme }) => theme.colors.silver};
  }
  @media ${mq(sizes.desktop)} {
    ${({ theme }) => theme.textStyles.titleH1}
  }
`
export const Wrapper = styled.div`
  max-width: 96rem;
  margin-inline: auto;
  display: flex;
  flex-flow: column;
  gap: 2rem;
`

export const Texts = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
`
export const Introduction = styled.p`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.matterhorn};
`
export const Mandatory = styled.p`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.grey};
`

export const StyledHtml = styled(Html)`
  ${({ theme }) => theme.textStyles.textXSmall}
  color: ${({ theme }) => theme.colors.grey};
  & a {
    color: ${({ theme }) => theme.scheme.HighLightToneColor};
  }
  & b,
  & strong {
    ${({ theme }) => theme.textStyles.textXSmallStrong};
  }
  & p:not(:first-child) {
    margin-block-start: 1rem;
  }
  & p:not(:last-child) {
    padding-bottom: 0;
  }
`
