import React, { FC } from 'react'
import cx from 'classnames'

import SoloCapriceValentineGameStepper, {
  SoloCapriceValentineGameStepperProps,
} from '../SoloCapriceValentineGameStepper'

import * as SC from './styled'

export type SoloCapriceValentineGameLoadProps = Pick<
  MinimalSeoBlock,
  'htmlTag'
> & {
  id?: string
  className?: string
  title?: string
  text?: string
  stepperProps?: SoloCapriceValentineGameStepperProps
}

const SoloCapriceValentineGameLoad: FC<SoloCapriceValentineGameLoadProps> = ({
  id,
  className,
  title,
  text,
  stepperProps,
}) => {
  return (
    <SC.Container
      id={id}
      className={cx('SoloCapriceValentineGameLoad', className)}
    >
      <SC.Wrapper>
        {stepperProps && <SoloCapriceValentineGameStepper {...stepperProps} />}
        {title && <SC.Title>{title}</SC.Title>}
        {text && <SC.Text>{text}</SC.Text>}
        <SC.Loader>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <circle cx="4" cy="12" r="3" fill="currentColor">
              <animate
                id="svgSpinners3DotsBounce0"
                attributeName="cy"
                begin="0;svgSpinners3DotsBounce1.end+0.25s"
                calcMode="spline"
                dur="0.6s"
                keySplines=".33,.66,.66,1;.33,0,.66,.33"
                values="12;6;12"
              />
            </circle>
            <circle cx="12" cy="12" r="3" fill="currentColor">
              <animate
                attributeName="cy"
                begin="svgSpinners3DotsBounce0.begin+0.1s"
                calcMode="spline"
                dur="0.6s"
                keySplines=".33,.66,.66,1;.33,0,.66,.33"
                values="12;6;12"
              />
            </circle>
            <circle cx="20" cy="12" r="3" fill="currentColor">
              <animate
                id="svgSpinners3DotsBounce1"
                attributeName="cy"
                begin="svgSpinners3DotsBounce0.begin+0.2s"
                calcMode="spline"
                dur="0.6s"
                keySplines=".33,.66,.66,1;.33,0,.66,.33"
                values="12;6;12"
              />
            </circle>
          </svg>
        </SC.Loader>
      </SC.Wrapper>
    </SC.Container>
  )
}

export default SoloCapriceValentineGameLoad
