import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  SoloFullWidthImageCardBlock as Data,
  LinkTarget,
} from '../../../graphql/generated/api-graphql'
import { BlockProps } from '../props'
import SoloPushImage from '../../../components/molecules/SoloPushImage'
import { wediaImagesTransform } from '../../Common/wedia'

export type SoloFullWidthImageCardBlockProps = Omit<BlockProps, 'data'> & {
  data: Data
}

const SoloFullWidthImageCardBlock: FC<SoloFullWidthImageCardBlockProps> = ({
  data,
}) => {
  const { t } = useTranslation()

  return (
    <SoloPushImage
      title={data?.title ?? undefined}
      htmlProps={
        data.body
          ? {
              text: data.body,
            }
          : undefined
      }
      actionButtonProps={
        data.ctaBlock?.href
          ? {
              href: data.ctaBlock.href,
              label: data.ctaBlock.label ?? t('learn_more'),
              target:
                data.ctaBlock?.target === LinkTarget.Blank ? '_blank' : '_self',
            }
          : undefined
      }
      // this might need another image for mobile (see available mobilePictureProps)
      pictureProps={{
        alt: data.image?.alt ?? ' ',
        maxWidth: 1200,
        width: data.image?.variations?.[0]?.width ?? undefined,
        height: data.image?.variations?.[0]?.height ?? undefined,
        withHD: true,
        images: wediaImagesTransform(data.image),
      }}
    />
  )
}

export default SoloFullWidthImageCardBlock
