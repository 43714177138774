import { GetServerSidePropsContext, NextPage } from 'next'
import { useTranslation } from 'react-i18next'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import tracking, {
  TrackingCategoryType,
  TrackingContentGroup,
  TrackingUrlException,
  TrackingUserType,
} from 'src/tracking'
import { useSelector } from 'react-redux'
import { selectors } from 'src/redux'
import { useFormatBrandCoupons } from 'src/relay/Coupons/useFormatBrandCoupons'
import { useRouter } from 'next/router'
import { ThemeProvider } from 'styled-components'

import {
  ssrPersistedQuery,
  isMaintenance,
  set503Header,
  setDefaultCacheHeader,
  setNoCacheHeader,
  transformSeoToMetas,
} from '../../../helpers/SSRHelpers'
import { initWithLangAndLabels } from '../../../i18n'
import {
  Brand,
  CouponModel,
  Format,
  PageTemplate,
  SoloContactUsBlock,
  SoloPage,
  SoloSocialNetworksBlock,
  Tag,
} from '../../../graphql/generated/api-graphql'
import { ProductPopinProps } from '../../../components/molecules/ProductPopin'
import brandPage from '../../../graphql/Services/Brand/queries/brandPage'
import BrandTemplate from '../../../templates/BrandTemplate'
import useBrandHero from '../../../relay/Brand/useBrandHero'
import useBreadcrumb from '../../../relay/Breadcrumb/useBreadcrumb'
import Router, { routes } from '../../../routes/Router'
import { brandContactTransformer } from '../../../relay/Brand/brandContactTranformer'
import { VideoBlockProps } from '../../../components/molecules/VideoBlock'
import { brandArticlesTransformer } from '../../../relay/Brand/brandArticlesTranformer'
import { brandRecipesTransformer } from '../../../relay/Brand/brandRecipesTranformer'
import { brandProductsTransformer } from '../../../relay/Brand/brandProductsTranformer'
import productPopinTransform from '../../../relay/ProductPopin/transform'
import highlightTransformer from '../../../relay/Common/highlightTransformer'
import useHeader from '../../../relay/Header/useHeader'
import { Configuration } from '../../../graphql/Services'
import useFooter from '../../../relay/Footer/useFooter'
import {
  GetServerSidePropsReturnType,
  PageProps,
} from '../../../relay/Common/PageProps'
import { useBrandCoupons } from '../../../relay/Coupons/useBrandCoupons'
import format from '../../../graphql/Services/Cheese/queries/format'
import BugsnagHelpers from '../../../helpers/BugsnagHelpers'
import soloPageBySlug from '../../../graphql/Services/Solo/queries/soloPageBySlug'
import SoloTemplate from '../../../templates/SoloTemplate'
import { SoloBannerProps } from '../../../components/molecules/SoloBanner'
import Blocks from '../../../relay/BlocksSolo'
import transformSoloBannerBlock from '../../../relay/BlocksSolo/SoloBannerBlock/transformer'
import { SoloHeroProps } from '../../../components/molecules/SoloHero'
import useSoloHero from '../../../relay/Solo/useSoloHero'
import { themes } from '../../../theme'
import { keyFromApi } from '../../../theme/themes'
import { SoloNavProps } from '../../../components/molecules/SoloNav'
import transformSoloNav from '../../../relay/Solo/transformSoloNav'
import { ContactUsBlockProps } from '../../../components/molecules/ContactUsBlock'
import { transformSoloContactUsBlock } from '../../../relay/BlocksSolo/SoloContactUs/transformer'
import { SoloSocialsProps } from '../../../components/molecules/SoloSocials'
import { transformSoloSocialNetworksBlock } from '../../../relay/BlocksSolo/SoloSocialNetworksBlock/transformer'
import CouponCardsBlock from '../../../components/molecules/CouponCardsBlock'
import { SoloAnchorsProps } from '../../../components/molecules/SoloAnchors'
import transformSoloAnchors from '../../../relay/Solo/transformSoloAnchors'
import { SoloHistoryAnchorsProps } from '../../../components/molecules/SoloHistoryAnchors'
import transformSoloHistoryAnchors from '../../../relay/Solo/transformSoloHistoryAnchors'
import { SoloContentAnchorsProps } from '../../../components/molecules/SoloContentAnchors'
import transformSoloContentAnchors from '../../../relay/Solo/transformSoloContentAnchors'
import { adPropsTransformer } from '../../../relay/Common/adPropsTransformer'
import { PushFormatsProps } from '../../../components/molecules/PushFormats'
import { brandPushFormatsTransformer } from '../../../relay/Brand/brandPushFormatsTransformer'

type BrandPageProps = PageProps & {
  data: Brand | SoloPage
  tags: Tag[]
  popinProps?: Format
  isSubPage?: boolean
  referer?: string
}

// @TODO RealBrandPage & SoloBrandPage in different files

// this is the component outputting the simple brand page
const RealBrandPage: FC<Omit<BrandPageProps, 'data'> & { data: Brand }> = ({
  data,
  tags,
  configuration,
  popinProps,
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  const [loadedFromSSRData, setLoadedFromSSRData] = useState(false)
  const [openOpin, setOpenPopin] = useState(false)
  const user = useSelector(selectors.auth.user)
  useEffect(() => {
    tracking.page({
      brand: data.name ?? undefined,
      group: TrackingContentGroup.BRAND,
      userType:
        user && user?.id ? TrackingUserType.MEMBER : TrackingUserType.VISITOR,
      userId: user?.id ?? undefined,
    })
  }, [data, user])

  const couponsProps = useBrandCoupons(
    data?.id,
    3,
    configuration?.pagesByTemplate,
    CouponModel.Brand
  )

  const [format, setFormat] = useState<any>(null)
  const couponsFormatProps = useFormatBrandCoupons(
    format?.id ?? '',
    3,
    configuration?.pagesByTemplate,
    CouponModel.Format
  )

  const popinData = productPopinTransform({
    t,
    data: {
      format: format,
      coupons: couponsFormatProps,
    },
    displayMoreButton: false,
  })

  const handleClose = useCallback(() => {
    setOpenPopin(false)
    setFormat(null)
    router.push(
      Router.getRouteUrl(routes.brand, {
        slug: data.slug,
      }),
      undefined,
      { shallow: true }
    )
  }, [data.slug, router])

  const handleLoadOpenPopin = useCallback((product: Format) => {
    setFormat(product)
    setOpenPopin(true)
  }, [])

  useEffect(() => {
    if (popinProps && !loadedFromSSRData) {
      setLoadedFromSSRData(true)
      handleLoadOpenPopin(popinProps)
    }
  }, [
    data?.cheeses,
    handleLoadOpenPopin,
    loadedFromSSRData,
    popinProps,
    router.query?.format,
  ])

  const breadcrumbProps = useBreadcrumb([
    {
      label:
        configuration?.pagesByTemplate?.[PageTemplate.HpCheeses]?.title ??
        t('breadcrumb_brands'),
      href: configuration?.pagesByTemplate?.[PageTemplate.HpCheeses]?.slug,
    },
    {
      label:
        configuration?.pagesByTemplate?.[PageTemplate.ListCheeses]?.title ??
        t('breadcrumb_brands'),
      href: configuration?.pagesByTemplate?.[PageTemplate.ListCheeses]?.slug,
    },
    { label: data?.name },
  ])

  const brandHeroProps = useBrandHero(data)
  const highlightProps = highlightTransformer(t, data, (format) => {
    handleLoadOpenPopin(format)
    router.push(
      Router.getRouteUrl(routes.brand, {
        slug: data.slug,
        format: format.slug,
      }),
      undefined,
      { shallow: true }
    )
  })
  const recipeProps = brandRecipesTransformer(t, data, tags)
  const newsProps = brandArticlesTransformer(t, data)
  const videoProps: VideoBlockProps | undefined = undefined // todo
  const handleFormatClick = useCallback(
    (format: Format) => {
      handleLoadOpenPopin(format)
      router.push(
        Router.getRouteUrl(routes.brand, {
          slug: data.slug,
          format: format.slug,
        }),
        undefined,
        { shallow: true }
      )
    },
    [data.slug, handleLoadOpenPopin, router]
  )

  const relatedProps = brandProductsTransformer(t, data, handleFormatClick)
  const pushFormatsProps: PushFormatsProps[] | undefined =
    brandPushFormatsTransformer(t, data, handleFormatClick)

  const contactUsProps = brandContactTransformer(t, data?.contacts?.[0])

  // ------
  // popin
  const productPopinProps: ProductPopinProps | undefined = popinData
    ? {
        ...popinData,
        popin: {
          ...popinData.popin,
          isOpen: openOpin,
          closeHandler: handleClose,
        },
      }
    : undefined

  const adProps = adPropsTransformer(data, configuration)

  return (
    <BrandTemplate
      idContent="Content"
      headerProps={useHeader(
        configuration?.header,
        configuration?.footer?.socialLinks
      )}
      footerProps={useFooter(configuration?.footer)}
      breadcrumbProps={breadcrumbProps}
      brandHeroProps={brandHeroProps}
      productPopinProps={productPopinProps}
      highlightProps={highlightProps}
      newsProps={newsProps}
      recipeProps={recipeProps}
      contactUsProps={contactUsProps}
      videoProps={videoProps}
      couponsProps={couponsProps}
      relatedProps={relatedProps}
      adProps={adProps}
      pushFormatsProps={pushFormatsProps}
    />
  )
}

// this is the component outputting the solo brand page
const SoloBrandPage: FC<Omit<BrandPageProps, 'data'> & { data: SoloPage }> = ({
  data,
  configuration,
  isSubPage,
  referer,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const user = useSelector(selectors.auth.user)

  const [openOpin, setOpenPopin] = useState(false)
  const [format, setFormat] = useState<any>(null)

  useEffect(() => {
    // exception for this because it has its own tracking
    if (!router.asPath.includes(TrackingUrlException.JC_CDD)) {
      tracking.page({
        brand: data.brand.name ?? undefined,
        group: TrackingContentGroup.SOLO_BRAND,
        page: data.menuLinks.find((item) => item.isActive)?.title ?? data.title,
        section: TrackingCategoryType.SOLO,
        userType:
          user && user?.id ? TrackingUserType.MEMBER : TrackingUserType.VISITOR,
        userId: user?.id ?? undefined,
        hideRubrique: true,
      })
    }
  }, [data, router.asPath, user])

  // coupons
  const couponsFormatProps = useFormatBrandCoupons(
    format?.id ?? '',
    3,
    configuration?.pagesByTemplate,
    CouponModel.Format
  )

  const popinData = productPopinTransform({
    t,
    data: {
      format: format,
      coupons: couponsFormatProps,
    },
    displayMoreButton: false,
  })

  // popin
  const handleClose = useCallback(() => {
    setOpenPopin(false)
    setFormat(null)
    router.push(
      Router.getRouteUrl(routes.brand, {
        slug: data.slug,
      }),
      undefined,
      { shallow: true }
    )
  }, [data.slug, router])

  const handleLoadOpenPopin = useCallback((product: Format) => {
    setFormat(product)
    setOpenPopin(true)
  }, [])

  // filter out blocks that should not be blocks
  const filteredBlocks = data?.blocks?.filter((block, index) => {
    return (index === 0 && block.__typename === 'SoloBannerBlock') ||
      block.__typename === 'SoloSocialNetworksBlock' ||
      block.__typename === 'SoloContactUsBlock' ||
      block.__typename === 'SoloCapriceValentineGameBlock'
      ? false
      : true
  })

  const filteredTopBlocks = data?.blocks?.filter((block) => {
    return block.__typename === 'SoloCapriceValentineGameBlock'
  })

  // hero
  const soloHeroProps: SoloHeroProps | undefined = useSoloHero(data)

  // brand space navigation
  const soloNavProps: SoloNavProps | undefined = transformSoloNav(data, referer)
  // @todo
  // on navigating in between we scroll to the position of the nav (before the nav is sticky)

  // brand products navigation
  const soloAnchorsProps: SoloAnchorsProps | undefined =
    transformSoloAnchors(data)

  // history navigation
  const soloHistoryAnchorsProps: SoloHistoryAnchorsProps | undefined =
    transformSoloHistoryAnchors(data)

  // history navigation
  const soloContentAnchorsProps: SoloContentAnchorsProps | undefined =
    transformSoloContentAnchors(data)

  // Banner appearing sub navigation but still in the top zone
  const soloBannerProps: SoloBannerProps | undefined = useMemo(() => {
    const bannerData =
      data?.blocks?.[0]?.__typename === 'SoloBannerBlock'
        ? data?.blocks[0]
        : undefined
    return bannerData && bannerData?.image
      ? transformSoloBannerBlock(bannerData)
      : undefined
  }, [data?.blocks])

  // coupons
  const couponsProps = useBrandCoupons(
    data.brand?.id,
    3,
    configuration?.pagesByTemplate,
    CouponModel.Brand
  )

  // contact us
  const contactUsProps: ContactUsBlockProps | undefined = useMemo(() => {
    const contactUsData =
      data?.blocks?.filter(
        (block) => block.__typename === 'SoloContactUsBlock'
      )?.[0] ?? undefined
    return contactUsData
      ? transformSoloContactUsBlock(t, contactUsData as SoloContactUsBlock)
      : undefined
  }, [data?.blocks, t])

  // socials
  const soloSocialsProps: SoloSocialsProps | undefined = useMemo(() => {
    const socialsData =
      data?.blocks?.filter(
        (block) => block.__typename === 'SoloSocialNetworksBlock'
      )?.[0] ?? undefined
    return socialsData
      ? transformSoloSocialNetworksBlock(
          t,
          socialsData as SoloSocialNetworksBlock
        )
      : undefined
  }, [data?.blocks, t])

  // popin
  const productPopinProps: ProductPopinProps | undefined = popinData
    ? {
        ...popinData,
        popin: {
          ...popinData.popin,
          isOpen: openOpin,
          closeHandler: handleClose,
        },
      }
    : undefined

  const blocks =
    filteredBlocks && filteredBlocks.length > 0 ? (
      <Blocks
        blocks={filteredBlocks}
        pagesByTemplate={configuration?.pagesByTemplate}
        popinHandler={handleLoadOpenPopin}
        brandId={data?.brand?.id}
      />
    ) : null

  const hasCouponBlock = data?.blocks?.some(
    (block) => block.__typename === 'SoloCouponsBlock'
  )

  const coupons =
    couponsProps && isSubPage !== true && !hasCouponBlock ? (
      <CouponCardsBlock {...couponsProps} />
    ) : null

  const adProps = adPropsTransformer(data.brand, configuration)

  // render
  return (
    <ThemeProvider theme={themes[keyFromApi(data.theme)]}>
      <SoloTemplate
        idContent="Content"
        headerProps={useHeader(
          configuration?.header,
          configuration?.footer?.socialLinks
        )}
        footerProps={useFooter(configuration?.footer)}
        soloHeroProps={soloHeroProps}
        soloNavProps={soloNavProps}
        soloAnchorsProps={soloAnchorsProps}
        soloHistoryAnchorsProps={soloHistoryAnchorsProps}
        soloContentAnchorsProps={soloContentAnchorsProps}
        soloBannerProps={soloBannerProps}
        contactUsProps={contactUsProps}
        soloSocialsProps={soloSocialsProps}
        productPopinProps={productPopinProps}
        adProps={adProps}
        topBlocks={
          filteredTopBlocks && filteredTopBlocks.length > 0 ? (
            <Blocks
              blocks={filteredTopBlocks}
              pagesByTemplate={configuration?.pagesByTemplate}
              popinHandler={handleLoadOpenPopin}
              brandId={data?.brand?.id}
            />
          ) : null
        }
      >
        {(blocks || coupons) && (
          <>
            {blocks}
            {coupons}
          </>
        )}
      </SoloTemplate>
    </ThemeProvider>
  )
}

const BrandPage: NextPage<BrandPageProps> = ({
  data,
  tags,
  configuration,
  popinProps,
  ...rest
}) => {
  return data.__typename === 'SoloPage' ? (
    <SoloBrandPage
      data={data as SoloPage}
      tags={tags}
      configuration={configuration}
      popinProps={popinProps}
      {...rest}
    />
  ) : data.__typename === 'Brand' ? (
    <RealBrandPage
      data={data as Brand}
      tags={tags}
      configuration={configuration}
      popinProps={popinProps}
      {...rest}
    />
  ) : null
}

export default BrandPage

export async function getServerSideProps(
  context: GetServerSidePropsContext
): GetServerSidePropsReturnType<BrandPageProps> {
  try {
    const slug = `${context?.query?.slug}${
      context?.query?.slug2 ? `/${context?.query?.slug2}` : ''
    }`
    const formatSlug = context?.query?.format

    const [rs, soloRs, configurationRs, formatRs] = await Promise.all([
      ssrPersistedQuery(brandPage, {
        slug: slug,
      }),
      ssrPersistedQuery(soloPageBySlug, {
        slug: slug,
      }),
      ssrPersistedQuery(Configuration.queries.configuration),
      ...(formatSlug ? [ssrPersistedQuery(format, { slug: formatSlug })] : []),
    ])
    const tags = rs?.data?.tags ?? null
    const data = soloRs?.data ?? rs?.data?.brand ?? {}
    const configuration = configurationRs?.data ?? null
    const i18n = initWithLangAndLabels('fr', {})

    if (!configuration || rs?.errors || soloRs?.errors || formatRs?.errors) {
      set503Header(context)
      return {
        props: {
          i18n,
          error: true,
        },
      }
    }

    if (isMaintenance(configuration)) {
      set503Header(context)
    } else {
      setDefaultCacheHeader(context)
    }

    if (!data?.id) {
      return {
        notFound: true,
        props: {
          i18n,
          configuration,
        },
      }
    }

    return {
      props: {
        i18n,
        configuration,
        metas: transformSeoToMetas(
          Router.getRouteUrl(
            context?.query?.slug2 ? routes.brandSub : routes.brand,
            {
              slug: context?.query?.slug,
              ...(context?.query?.slug2
                ? { slug2: context?.query?.slug2 }
                : {}),
            }
          ),
          data?.seo
        ),
        tags: tags,
        data,
        ...(formatRs?.data && {
          popinProps: formatRs?.data,
        }),
        isSubPage: context?.query?.slug2 ? true : false,
        referer: context?.req.headers.referer ?? null,
      },
    }
  } catch (e: any) {
    BugsnagHelpers?.notify(e, (event) => {
      const { resolvedUrl, query, params } = context
      event.addMetadata('context', { resolvedUrl, query, params })
    })
    setNoCacheHeader(context)

    return {
      notFound: true,
    }
  }
}
