import { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type SoloContentDateProps = Pick<MinimalSeoBlock, 'htmlTag'> & {
  id?: string
  className?: string
  title?: string
}

const SoloContentDate: FC<SoloContentDateProps> = ({
  id,
  className,
  htmlTag,
  title,
}) => {
  return title ? (
    <SC.Container
      id={id}
      className={cx('SoloContentDate', className)}
      as={htmlTag}
    >
      {title}
    </SC.Container>
  ) : null
}

export default SoloContentDate
