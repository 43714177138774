// for all the new Solo blocks,
// this establish a correspondance between the backend enums and the frontend enums

import {
  SoloContentBlockMediaPosition,
  SoloContentBlockMediaSize,
} from '../../components/molecules/SoloContentBlock/types'
import {
  SoloBodyImageRatio,
  SoloBodyPosition,
} from '../../graphql/generated/api-graphql'

export const getMediaPosition = (
  bodyPosition: SoloBodyPosition | undefined | null
): SoloContentBlockMediaPosition => {
  switch (bodyPosition) {
    case SoloBodyPosition.Left:
      return SoloContentBlockMediaPosition.Right
    case SoloBodyPosition.Right:
      return SoloContentBlockMediaPosition.Left
    default:
      return SoloContentBlockMediaPosition.Default
  }
}

export const getMediaSize = (
  bodyImageRatio: SoloBodyImageRatio | undefined | null
): SoloContentBlockMediaSize => {
  switch (bodyImageRatio) {
    case SoloBodyImageRatio.Third:
      return SoloContentBlockMediaSize.Big
    case SoloBodyImageRatio.Half:
      return SoloContentBlockMediaSize.Default
    default:
      return SoloContentBlockMediaSize.Big
  }
}
