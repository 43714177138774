import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { LightAuthLoginModalProps } from '../../../components/molecules/LightAuthModal/LightAuthLoginModal'
import { imageMocks } from '../../../components/atoms/Picture/mocks'
import { formikFormMocks } from '../../../components/form/FormikForm/mock'
import { formikFieldMocks } from '../../../components/form/FormikField/mocks'
import { ActionButtonVariant } from '../../../components/atoms/ActionButton/styled'
import { Icons } from '../../../components/atoms/Icon'
import { useServiceRequest } from '../../../hooks/useServiceRequest'
import { actions, selectors } from '../../../redux'
import Router, { routes } from '../../../routes/Router'

export const useCapriceValentineGameLogin = (
  isOpen: boolean,
  closeHandler: () => void
): LightAuthLoginModalProps => {
  const { t } = useTranslation()
  const [login, loginHandler] = useServiceRequest(
    selectors.auth.login,
    actions.auth.loginRequest,
    null
  )

  const [, socialLoginHandler] = useServiceRequest(
    selectors.auth.socialLogin,
    actions.auth.socialLoginRequest,
    actions.auth.socialLoginReset
  )

  return {
    isOpen,
    closeHandler,
    logoProps: {
      ...imageMocks,
      images: [
        {
          src: '/static/assets/images/caprice/caprice-logo.png',
          size: 400,
        },
      ],
    },
    title: 'Je me connecte pour jouer',
    login: {
      formikForm: {
        ...formikFormMocks.basic,
        initialValues: {
          email: '',
          password: '',
        },
        validationSchema: Yup.object().shape({
          email: Yup.string()
            .email('Entrez un email valide')
            .required('Ce champ est requis'),
          password: Yup.string().required('Ce champ est requis'),
        }),
        onSubmit: loginHandler,
        validateOnChange: true,
      },
      ...(login.errors && {
        errors: {
          messages: login.errors,
        },
      }),
      formikFieldEmailProps: {
        ...formikFieldMocks.basic,
        name: 'email',
        label: 'Email',
        placeholder: '',
      },
      formikFieldPasswordProps: {
        ...formikFieldMocks.basic,
        type: 'password',
        name: 'password',
        label: 'Password',
        placeholder: '',
      },
      linkResetPasswordProps: {
        label: t('header_auth_popin_login_forgot_password'),
        href: Router.getRouteUrl(routes.requestPassword.name),
      },
      submitButton: {
        isPending: login?.pending,
        text: login?.pending ? t('header_auth_popin_login_pending') : 'Valider',
        variant: ActionButtonVariant.secondary,
        iconPosition: 'right',
        iconProps: {
          icon: login?.pending ? Icons.refresh : Icons.arrowRight,
        },
      },
    },
    social: {
      actionFacebookButtonProps: {
        label: 'Continuer avec Facebook',
        iconPosition: 'left',
        iconProps: {
          icon: Icons.socialFacebook,
          width: 24,
          height: 24,
        },
        onClick: () =>
          socialLoginHandler({
            provider: 'facebook',
          }),
      },
      actionGoogleButtonProps: {
        label: 'Continuer avec Google',
        variant: ActionButtonVariant.secondary,
        iconPosition: 'left',
        iconProps: {
          icon: Icons.google,
          width: 24,
          height: 24,
        },
        onClick: () =>
          socialLoginHandler({
            provider: 'google',
          }),
      },
    },
  }
}
