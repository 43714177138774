import { decode } from 'html-entities'

// Prevent URL from being broken by removing the extra http:// (ex: http://https://www.google.com -> https://www.google.com)
export const fixURL = (description: string) => {
  const regex = /http:\/\/https:\/\//gi
  return description.replace(regex, 'https://')
}

export const fixOneTrustTag = (str: string) => {
  return str.replace(
    '<p>ot-sdk-cookie-policy</p>',
    '<div id="ot-sdk-cookie-policy"></div>'
  )
}

export const escapeHtmlForJsonLd = (str: string) => {
  return decode(str)
    .replace(/<\/?[^>]+>/gi, '')
    .replace(/\n/g, '')
}

export const checkCorrectUrl = (url: string) => {
  const regex = /^(http|https):\/\/[^ "]+$/
  return regex.test(url)
}
